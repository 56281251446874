import { analyticsService } from "@/common/services/analytics/analytics.service";
import MarkdownToJSX, {
  MarkdownToJSX as MarkdownNamespace,
} from "markdown-to-jsx";
import { useTranslation } from "next-i18next";
import { useShouldExcludeBanner } from "@/common/hooks/pages.hooks";

const OPTIONS: MarkdownNamespace.Options = {
  overrides: {
    a: {
      props: {
        onClick: () => {
          analyticsService.trackAnnouncementBar();
        },
      },
    },
  },
};

export const HeaderEventsBanner = () => {
  const { t } = useTranslation();

  const translation = t("header:header-banner-text-1");
  const shouldExclude = useShouldExcludeBanner();

  if (shouldExclude) {
    return null;
  }

  // If the key doesn't exist in lokalise translations, the translation function will return the key itself, thus the second check.
  // We can influence the rendering of the banner component through lokalise. The banner will render only if the key has the translation.
  if (!translation || translation === "header-banner-text-1") {
    return null;
  }

  return (
    <div className="flex items-center justify-center bg-brand-500 px-12 py-2.5 font-title text-sm text-white">
      <span className="text-center">
        <MarkdownToJSX options={OPTIONS}>
          {t("header:header-banner-text-1")}
        </MarkdownToJSX>
      </span>
    </div>
  );
};
