import React, { forwardRef, HTMLProps, useCallback, useRef } from "react";
import clsx from "clsx";

import InputCss from "./input.module.scss";

type InputSize = "small" | "normal";

const INPUT_CLASSES = {
  base: (
    size: InputSize,
    valid: boolean,
    disabled = false,
    readOnly = false
  ) => {
    return clsx(
      size === "normal" ? "px-[24px]" : "px-[8px]",
      size === "normal" ? "py-[12px]" : "py-[4px]",
      "text-[#282F36]",
      "font-body",
      "text-base",
      "border",
      valid ? "border-gray-400 bg-white" : "border-attention-500",
      disabled && "border-opacity-60 bg-gray-100 bg-opacity-60",
      readOnly && "border-opacity-60",
      !valid && [
        "bg-opacity-10",
        "hover:border-attention-600",
        "focus-within:border-attention-500",
        "focus-within:ring-attention-200",
      ],
      "rounded-[4px]",
      "ring-offset-0",
      "focus-within:outline-none",
      "focus-within:ring-2",
      "flex",
      "gap-[12px]"
    );
  },
  primary: clsx(
    "hover:border-primary-500",
    "focus-within:border-primary-500",
    "focus-within:ring-primary-200"
  ),
  secondary: clsx(
    "hover:border-secondary-500",
    "focus-within:border-secondary-500",
    "focus-within:ring-secondary-200"
  ),
  tertiary: clsx(
    "hover:border-curious-500",
    "focus-within:border-curious-500",
    "focus-within:ring-curious-200"
  ),
  brand: clsx(
    "hover:border-brand-500",
    "focus-within:border-brand-500",
    "focus-within:ring-brand-200"
  ),
};

type InputColor =
  | "primary"
  | "secondary"
  | "tertiary"
  | "brand"
  | (() => string);

export interface InputProps
  extends Omit<HTMLProps<HTMLInputElement>, "ref" | "color" | "size"> {
  leftElement?: React.ReactNode;
  rightElement?: React.ReactNode;
  color?: InputColor;
  size?: InputSize;
  valid?: boolean;
}

/**
 * Simple input.
 *
 * @deprecated Use `Input` component from `ui/cva/input/Input`.
 */
const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      leftElement,
      rightElement,
      type,
      color = "primary",
      size = "normal",
      valid = true,
      disabled,
      readOnly,
      className,
      ...props
    },
    ref
  ) => {
    const internalRef = useRef<HTMLInputElement | null>(null);

    const getColorClasses = (color: InputColor) => {
      return typeof color === "function" ? color() : INPUT_CLASSES[color];
    };

    const refCallback = useCallback(
      (element: HTMLInputElement) => {
        internalRef.current = element;

        if (ref) {
          if (typeof ref === "function") ref(element);
          else ref.current = element;
        }
      },
      [ref]
    );

    const handleWrapperClick = () => {
      if (internalRef.current) internalRef.current.focus();
    };

    return (
      <div
        className={clsx(
          INPUT_CLASSES.base(size, valid, disabled, readOnly),
          valid && getColorClasses(color)
        )}
        onClick={handleWrapperClick}
      >
        {leftElement && <span className="flex">{leftElement}</span>}
        <input
          ref={refCallback}
          className={clsx(
            "w-full bg-transparent text-[#282F36] focus:outline-none",
            "placeholder:text-gray-300 placeholder:text-opacity-80",
            "read-only:text-gray-400 read-only:placeholder-opacity-60",
            "disabled:cursor-not-allowed disabled:text-gray-600 disabled:placeholder-opacity-60",
            InputCss["autofill-fix"],
            className
          )}
          type={type}
          disabled={disabled}
          readOnly={readOnly}
          {...props}
        />
        {rightElement && <span className="flex">{rightElement}</span>}
      </div>
    );
  }
);

export default Input;
