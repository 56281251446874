import { analyticsService } from "@/common/services/analytics/analytics.service";
import { cn } from "@/common/utils/css.utils";
import { MegaMenuPanelDelegator } from "@/components/mega-menu/MegaMenuPanelDelegator";
import { Flavor } from "@/ui/typography/Flavor";
import {
  FocusOutsideEvent,
  PointerDownOutsideEvent,
} from "@radix-ui/react-dismissable-layer";
import {
  NavigationMenuItem,
  NavigationMenuTrigger,
  NavigationMenuLink,
  NavigationMenuContent,
} from "@radix-ui/react-navigation-menu";
import Link from "@whoppah/next/link";
import { MouseEvent, useRef, PointerEvent, useEffect, useState } from "react";
import { MegaMenuItemData } from "@/common/contracts/mega-menu.contracts";

const MegaMenuHeaderItemSubmenu = ({
  open,
  label,
  submenuSlug,
  ...props
}: Omit<MegaMenuItemData, "href"> & { open: boolean }) => {
  const handlePointerEvent = (evt: PointerEvent<HTMLDivElement>) => {
    /**
     * Prevent this event as we don't want to close the content once mouse pointer
     * leaves the bounds of content.
     */
    evt.preventDefault();
  };

  const handlePointerDownOutside = (
    evt: PointerDownOutsideEvent | FocusOutsideEvent
  ) => {
    const menuItems = Array.from(
      document.querySelectorAll("[data-mega-menu-items] li")
    );

    const target = evt.target as HTMLElement;

    const shouldPrevent = menuItems.some(
      element =>
        element.contains(target) &&
        target.dataset.megaMenuSubmenuSlug !== "href"
    );

    if (shouldPrevent) {
      evt.preventDefault();
    }
  };

  if (!submenuSlug) {
    return null;
  }

  return (
    <NavigationMenuContent
      forceMount
      onPointerEnter={handlePointerEvent}
      onPointerLeave={handlePointerEvent}
      onInteractOutside={handlePointerDownOutside}
      className={cn(
        "data-[motion=from-end]:animate-menu-show data-[motion=from-start]:animate-menu-show data-[motion=to-end]:animate-menu-hide data-[motion=to-start]:animate-menu-hide peer-data-[state=open]:bg-green-200 [&>div]:outline-none",
        !open && "hidden"
      )}
    >
      <MegaMenuPanelDelegator
        title={label}
        submenuSlug={submenuSlug}
        {...props}
      />
    </NavigationMenuContent>
  );
};

const MegaMenuHeaderItemLink = ({
  href,
  label,
  subMenuSlug,
  className,
  onClick,
}: {
  href: string;
  label: string;
  subMenuSlug: string;
  className?: string;
  onClick?: (evt: MouseEvent<HTMLAnchorElement>) => void;
}) => {
  const handleClick = (evt: MouseEvent<HTMLAnchorElement>) => {
    onClick?.(evt);

    /** Trigger `navigation_menu` event. */
    analyticsService.trackNavigationMenu(label);
  };

  return (
    <Link href={href} passHref={true} prefetch={false}>
      <NavigationMenuLink
        onClick={handleClick}
        className={cn(
          "grid before:invisible before:h-0 before:font-title before:text-sm before:font-bold before:content-[attr(title)] focus:rounded-[0.25rem] focus:outline-none focus-visible:ring-2 focus-visible:ring-curious-200 focus-visible:ring-offset-4",
          className
        )}
        title={label}
      >
        <Flavor data-mega-menu-submenu-slug={subMenuSlug}>{label}</Flavor>
      </NavigationMenuLink>
    </Link>
  );
};

/**
 * Mega menu header item.
 */
export const MegaMenuHeaderItem = ({
  label,
  href,
  ...props
}: MegaMenuItemData) => {
  const [triggerOpen, setTriggerOpen] = useState(false);
  const triggerRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const trigger = triggerRef.current;

    if (!trigger) {
      return;
    }

    const callback = (mutations: MutationRecord[]) => {
      for (const mutation of mutations) {
        if (mutation.attributeName === "data-state") {
          const value = trigger.getAttribute(mutation.attributeName);
          setTriggerOpen(value === "open");
        }
      }
    };

    const observer = new MutationObserver(callback);
    observer.observe(trigger, { attributes: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleClick = (evt: MouseEvent<HTMLAnchorElement>) => {
    if (props.submenuSlug) {
      /** Don't navigate, we want to open the sub-menu. */
      evt.preventDefault();
    }

    /** Activate header item, potentially opening sub-menu. */
    triggerRef.current?.click();

    /** Workaround: If the header item does not have a sub-menu, trigger it once more to close the backdrop. */
    if (!props.submenuSlug) {
      triggerRef.current?.click();
    }
  };

  if (!href) {
    return;
  }

  return (
    <NavigationMenuItem>
      <NavigationMenuTrigger ref={triggerRef} className="peer hidden" />
      <MegaMenuHeaderItemLink
        href={href}
        label={label}
        subMenuSlug={props.submenuSlug ?? "href"}
        className="whitespace-nowrap peer-data-[state=open]:font-semibold peer-data-[state=open]:text-brand-500"
        onClick={handleClick}
      />
      <MegaMenuHeaderItemSubmenu open={triggerOpen} label={label} {...props} />
    </NavigationMenuItem>
  );
};
