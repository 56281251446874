import { authStore } from "@/auth";
import { STATSIG_CLIENT_KEY } from "@/config";
import { PropsWithChildren, useState } from "react";
import { StatsigUser } from "statsig-react";
import { once } from "lodash-es";
import { StatsigProvider } from "@/experiments/StatsigProvider";
import { getStatsigOptions } from "@/experiments/utils/get-statsig-options";
import { createStatsigUser } from "@/experiments/utils/create-statsig-user";

const consoleWarOnce = once(console.warn);

type ExperimentsProviderProps = PropsWithChildren & {
  values: {
    user: StatsigUser;
    initializeValues: Record<string, unknown>;
  };
};

export const ExperimentsProvider = ({
  children,
  values,
}: ExperimentsProviderProps) => {
  const initializeValues = values?.initializeValues;

  const { deviceId } = authStore.getState();

  const [user, setUser] = useState<StatsigUser>(() => {
    if (values?.user) {
      return {
        ...values.user,
        userID: values.user.userID ?? undefined,
      };
    }
    const { userId } = authStore.getState();

    return createStatsigUser({ userId: userId ?? undefined, deviceId });
  });

  const options = getStatsigOptions({ deviceId });

  if (!STATSIG_CLIENT_KEY) {
    consoleWarOnce(
      "STATSIG_CLIENT_KEY is not defined. Experiments will not be enabled."
    );
    return children;
  }

  return (
    <StatsigProvider
      sdkKey={STATSIG_CLIENT_KEY}
      initializeValues={initializeValues}
      user={user}
      setUser={setUser}
      options={options}
    >
      {children}
    </StatsigProvider>
  );
};
