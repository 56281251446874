import { PendingAction } from "@/Apollo/schema";
import { Spark } from "@/assets/icons/Logo";
import { useRunOnce } from "@/common/hooks/general.hooks";
import { CommonModalActionAcceptTOS } from "@/components/common/modal/actions/CommonModalActionAcceptTOS";
import { CommonModalActionLogOut } from "@/components/common/modal/actions/CommonModalActionLogOut";
import { CommonModal } from "@/components/common/modal/CommonModal";
import { LokaliseMarkdownText } from "@/components/text/LokaliseMarkdownText";
import useMerchant from "@/hooks/useMerchant";
import { showModal } from "@/ui/Modal2";
import { Body } from "@/ui/typography/Body";
import Link from "@whoppah/next/link";
import { useTranslation } from "next-i18next";
import { LinkProps } from "next/link";
import { useRouter } from "next/router";
import { useEffect } from "react";

const DONT_SHOW_PATHS = ["/terms", "/privacy-policy"];

const LocalizedLink = (props: LinkProps) => {
  const { href, ...rest } = props;

  return (
    <Link href={href} passHref>
      <a {...rest} />
    </Link>
  );
};

export const useMerchantTOS = () => {
  const { t } = useTranslation();
  const { pendingActions } = useMerchant();
  const { when, reset } = useRunOnce();
  const { asPath } = useRouter();

  useEffect(() => {
    const show = !DONT_SHOW_PATHS.includes(asPath);
    const needsToAcceptTOS =
      pendingActions?.includes(PendingAction.AcceptTos) ?? false;

    const handleActionClick = () => {
      reset();
    };

    when(() => show && needsToAcceptTOS).runOnce(() => {
      showModal("common", {
        title: (
          <div className="flex flex-col items-center">
            <Spark className="h-6 w-6 text-brand-500" />
            <span>{t("login:login-tos-title")}</span>
          </div>
        ),
        content: (
          <Body className="text-center">{t("login:login-tos-content")}</Body>
        ),
        closeable: false,
        actions: (modal: CommonModal) => [
          <CommonModalActionLogOut
            key="log-out"
            modal={modal}
            onClick={handleActionClick}
          />,
          <CommonModalActionAcceptTOS
            key="accept"
            modal={modal}
            onClick={handleActionClick}
          />,
        ],
        footer: (
          <LokaliseMarkdownText
            lokaliseKey="login:login-tos-footer"
            className="text-center font-body text-sm"
            overrides={{
              a: {
                component: LocalizedLink,
                props: {
                  target: "_blank",
                  rel: "noopener noreferrer nofollow",
                  className: "text-brand-500 hover:underline",
                },
              },
            }}
          />
        ),
        className: "md:max-w-[35rem]",
      });
    });
  }, [asPath, pendingActions, reset, t, when]);
};
