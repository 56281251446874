import useCartCount from "@whoppah/apollo/hooks/useCartCount";
import { useCallback, useEffect } from "react";
import { useAuth } from "../../../auth";
import { makeVar } from "@apollo/client";
import { GUEST_CHECKOUT_KEY } from "common/static/checkout/checkout.static";

const hasItemsVar = makeVar(false);

const useCartHasItems = () => {
  const { isLoggedIn } = useAuth();

  const {
    data,
    loading,
    refetch: cartCountRefetch,
  } = useCartCount({
    skip: !isLoggedIn,
  });

  const getHasProducts = useCallback(() => {
    if (typeof window === "undefined") {
      return false;
    }

    const ids = localStorage.getItem(GUEST_CHECKOUT_KEY);
    return ids ? Boolean(JSON.parse(ids).length) : false;
  }, []);

  const updateHasItems = useCallback(
    (isLoggedIn: boolean) => {
      if (isLoggedIn && data?.cartCount) {
        hasItemsVar(Boolean(data?.cartCount));
        return;
      }
      hasItemsVar(getHasProducts());
    },
    [data?.cartCount, getHasProducts]
  );

  useEffect(() => {
    updateHasItems(isLoggedIn);
  }, [updateHasItems, isLoggedIn]);

  const refetch = useCallback(async () => {
    if (isLoggedIn) {
      await cartCountRefetch();
    }
    updateHasItems(isLoggedIn);
  }, [cartCountRefetch, isLoggedIn, updateHasItems]);

  return { hasItemsVar, loading, refetch };
};

export default useCartHasItems;
