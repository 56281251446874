const path = require("path");
const sprintf = require("i18next-sprintf-postprocessor");
const ns = require("./public/locales/namespaces");

/**
 * We cast these to `const`. This is similar to doing `as const` in ts.
 * {@link https://www.typescriptlang.org/docs/handbook/jsdoc-supported-types.html#casts}
 */
const locales = /** @type {const} */ (["en", "nl", "de", "fr"]);
const defaultLocale = /** @type {const} */ ("en");

/**
 * Global mapping for the price prefix. Beside prefix, we can also provide
 * a selector function used to determine whether to append the prefix or not.
 */
const DEFAULT_PRICE_PREFIX = {};

/**
 * Global mapping for the price suffix. Beside suffix, we can also provide
 * a selector function used to determine whether to append the suffix or not.
 */
const DEFAULT_PRICE_SUFFIX = {
  nl: {
    value: ",-",
    selector: amount => {
      return amount % 1 === 0;
    },
  },
};

const getPrefixValue = (amount, prefix, selector) => {
  if (!selector || selector(amount)) {
    return prefix ?? "";
  }

  return "";
};

const getSuffixValue = (amount, suffix, selector) => {
  if (!selector || selector(amount)) {
    return suffix ?? "";
  }

  return "";
};

module.exports = /** @type {const} */ ({
  i18n: {
    defaultLocale,
    locales,
  },
  postProcess: "sprintf",
  returnNull: false,
  fallbackLng: false,
  // debug: true,
  ns,
  localePath: path.resolve("./public/locales"),
  serializeConfig: false,
  use: [sprintf],
  interpolation: {
    format: (value, format, lng) => {
      if (
        format !== "currency" ||
        typeof value?.amount !== "number" ||
        !value?.currency
      ) {
        return value;
      }

      const { value: prefixValue, selector: prefixSelector } =
        value.prefix?.[lng] ?? DEFAULT_PRICE_PREFIX[lng] ?? {};

      const prefix = getPrefixValue(value.amount, prefixValue, prefixSelector);

      const { value: suffixValue, selector: suffixSelector } =
        value.suffix?.[lng] ?? DEFAULT_PRICE_SUFFIX[lng] ?? {};

      const suffix = getSuffixValue(value.amount, suffixValue, suffixSelector);

      const price = Intl.NumberFormat(lng, {
        style: "currency",
        minimumFractionDigits: Number.isInteger(value?.amount) ? 0 : 2,
        currency: value?.currency,
      }).format(value?.amount);

      return [prefix, price, suffix].join("");
    },
  },
});
