import { useAuth } from "@/auth";
import { MegaMenuMobileActionProps } from "@/components/mega-menu/mobile/MegaMenuMobileActionDelegator";
import { Flavor } from "@/ui/typography/Flavor";

export const MegaMenuMobileLogoutAction = ({
  onClick,
  children,
  ...props
}: MegaMenuMobileActionProps) => {
  const { logout } = useAuth();

  const handleClick = () => {
    logout();
    onClick?.();
  };

  return (
    <li>
      <button {...props} onClick={handleClick}>
        <Flavor>{children}</Flavor>
      </button>
    </li>
  );
};
