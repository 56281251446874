const ChevronDown = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M17.6877 9.31093C17.2731 8.89636 16.6034 8.89636 16.1889 9.31093L12.0643 13.4355L7.93981 9.31093C7.52523 8.89636 6.85553 8.89636 6.44095 9.31093C6.02637 9.72551 6.02637 10.3952 6.44095 10.8098L11.3202 15.6891C11.7348 16.1036 12.4045 16.1036 12.8191 15.6891L17.6984 10.8098C18.1023 10.4058 18.1023 9.72551 17.6877 9.31093Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ChevronDown;
