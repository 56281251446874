import { IconProps } from "@/common/contracts/icon.contracts";

export const Spark = (props: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.9963 14.8C12.2605 14.8004 12.5149 14.7183 12.708 14.5702C12.9011 14.4222 13.0182 14.2194 13.0355 14.003L13.5582 7.48678C13.571 7.4215 13.578 7.3555 13.5789 7.28936C13.5789 7.27839 13.5789 7.26864 13.5789 7.25767V7.2333C13.5613 6.90136 13.3883 6.58781 13.0959 6.35804C12.8035 6.12826 12.4144 6 12.0096 6C11.6048 6 11.2157 6.12826 10.9233 6.35804C10.6309 6.58781 10.4579 6.90136 10.4404 7.2333H10.421L10.957 14.003C10.9744 14.2194 11.0914 14.4222 11.2845 14.5702C11.4776 14.7183 11.732 14.8004 11.9963 14.8Z"
        fill="currentColor"
      />
      <path
        d="M16.1479 16.7652L21.4214 11.9211C21.4795 11.8803 21.5342 11.8344 21.5849 11.784L21.6072 11.7579L21.6268 11.7401C21.872 11.4654 22.0057 11.1017 21.9998 10.7256C21.9938 10.3495 21.8487 9.99055 21.5949 9.72458C21.3411 9.45861 20.9986 9.30643 20.6398 9.30019C20.281 9.29395 19.9338 9.43413 19.6718 9.69112L19.66 9.67878L14.8563 15.4102C14.7043 15.5935 14.6241 15.8305 14.6321 16.0732C14.6402 16.316 14.7357 16.5466 14.8996 16.7185C15.0634 16.8903 15.2833 16.9907 15.5149 16.9994C15.7466 17.008 15.9727 16.9244 16.1479 16.7652Z"
        fill="currentColor"
      />
      <path
        d="M9.10068 16.7252C9.26487 16.5529 9.36042 16.3218 9.36795 16.0785C9.37548 15.8353 9.29444 15.5981 9.14124 15.4151L4.34132 9.67849L4.32823 9.69082C4.06618 9.43402 3.71907 9.29395 3.36022 9.30019C3.00138 9.30642 2.6589 9.45849 2.40513 9.72426C2.15135 9.99002 2.00615 10.3487 2.00019 10.7245C1.99423 11.1003 2.12799 11.4638 2.3732 11.7382L2.39283 11.7561C2.40068 11.7643 2.40722 11.7739 2.41638 11.7821C2.46696 11.8322 2.52121 11.878 2.57865 11.9191L7.85227 16.7595C8.0259 16.9202 8.25125 17.0061 8.48291 16.9997C8.71457 16.9933 8.93532 16.8953 9.10068 16.7252Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Spark;
