import { GRAPHQL_ENDPOINT } from "@/config";

export const getSearchMeta = () => {
  if (typeof window === "undefined") {
    return;
  }

  const search_meta_string = window.sessionStorage.getItem("search_meta");

  if (search_meta_string) {
    return JSON.parse(search_meta_string);
  }

  const prefix = "https://gateway.";
  const suffix = ".whoppah.com";
  const env = GRAPHQL_ENDPOINT.replace(prefix, "").replace(suffix, "");

  return { index: `whoppah-${env}-nl` };
};

export const setSearchMeta = (meta: { queryID: string; index: number }) => {
  if (typeof window === "undefined") return;
  const search_meta_string = JSON.stringify({
    queryID: meta.queryID,
    index: meta.index,
  });
  window.sessionStorage.setItem("search_meta", search_meta_string);
};
