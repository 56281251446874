import { useAcceptTOS } from "@/Apollo/hooks/useAcceptTOS";
import { useExecAsync } from "@/common/hooks/general.hooks";
import { CommonModalActionProps } from "@/components/common/modal/contracts/modal.contracts";
import { Button } from "@/ui/cva/Button";
import { closeModal } from "@/ui/Modal2";
import { useTranslation } from "next-i18next";

type CommonModalActionAcceptTOSProps = {
  onClick?: () => void;
} & CommonModalActionProps;

export const CommonModalActionAcceptTOS = ({
  modal,
  onClick,
}: CommonModalActionAcceptTOSProps) => {
  const { t } = useTranslation();
  const [acceptTOS] = useAcceptTOS();

  const { exec, isExecuting } = useExecAsync({
    onError: error => {
      console.error("Accept TOS", error);
    },
  });

  const handleClick = async () => {
    await exec(() => acceptTOS());
    closeModal(modal);

    onClick?.();
  };

  return (
    <Button loading={isExecuting} onClick={handleClick}>
      {t("common:common-accept")}
    </Button>
  );
};
