import { Transition } from "@headlessui/react";
import { ReactNode, useEffect, useRef, useState } from "react";

interface AnimatedBannerProps {
  enter?: string;
  enterFrom?: string;
  enterTo?: string;
  entered?: string;
  leave?: string;
  leaveFrom?: string;
  leaveTo?: string;
  panels: ReactNode[];
  timeout?: number;
}

const AnimatedBanner = ({
  enter,
  enterFrom,
  enterTo,
  entered,
  leave,
  leaveFrom,
  leaveTo,
  panels,
  timeout,
}: AnimatedBannerProps) => {
  const showIndexRef = useRef(0);
  const [showNext, setShowNext] = useState(false);

  const timerRef = useRef<number | null>(null);

  useEffect(() => {
    // Show first message on mount (start show animation).
    setShowNext(true);

    // Unmounted, cleanup timer if present
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  const handleAfterEnterTimeout = () => {
    // Hide message on timeout (start hide animation).
    setShowNext(false);
  };

  // Once message is shown (show animation completes), start timer to hide it.
  const handleAfterEnter = () => {
    timerRef.current = window.setTimeout(handleAfterEnterTimeout, timeout);
  };

  const handleAfterLeaveTimeout = () => {
    const getNextIndex = (prev: number) => {
      if (prev < panels.length - 1) {
        return prev + 1;
      }

      return 0;
    };

    showIndexRef.current = getNextIndex(showIndexRef.current);
    setShowNext(true);
  };

  const handleAfterLeave = () => {
    // Queues the state change to next index, since truing to do it immediately results
    // in animation being stopped (randomly) on Mobile. This is a <Transition> quirk.
    timerRef.current = window.setTimeout(handleAfterLeaveTimeout);
  };

  return (
    <Transition
      show={showNext}
      enter={enter}
      enterFrom={enterFrom}
      enterTo={enterTo}
      entered={entered}
      leave={leave}
      leaveFrom={leaveFrom}
      leaveTo={leaveTo}
      afterEnter={handleAfterEnter}
      afterLeave={handleAfterLeave}
      appear={true}
    >
      {panels[showIndexRef.current]}
    </Transition>
  );
};

export default AnimatedBanner;
