import { IconProps } from "@/common/contracts/icon.contracts";

export const Bookmark = (props: IconProps) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_6304_13882)">
        <path
          d="M17 18.4026L12 16.2226L7 18.4026V5.40259H17M17 3.40259H7C6.46957 3.40259 5.96086 3.6133 5.58579 3.98837C5.21071 4.36345 5 4.87215 5 5.40259V21.4026L12 18.4026L19 21.4026V5.40259C19 4.29259 18.1 3.40259 17 3.40259Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_6304_13882">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.402588)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
