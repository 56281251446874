import { MegaMenuItemData } from "@/common/contracts/mega-menu.contracts";
import { MegaMenuPanel } from "@/components/mega-menu/MegaMenuPanel";

type MegaMenuDelegatorProps = { title: string } & Omit<
  MegaMenuItemData,
  "label"
>;

export const MegaMenuPanelDelegator = ({
  title,
  submenuSlug,
  submenuType,
  submenu,
}: MegaMenuDelegatorProps) => {
  if (!submenuSlug || !submenuType || !submenu) {
    return null;
  }

  return <MegaMenuPanel title={title} type={submenuType} data={submenu} />;
};
