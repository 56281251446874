import NoSSR from "@mpth/react-no-ssr";
import AccountUtils from "@whoppah/components/Account/AccountUtils";
import { ProfileOutlined as ProfileOutlinedIcon } from "@whoppah/icons/24/ProfileOutlined";
import React, { useMemo } from "react";
import { useAuth } from "@/auth";
import useMe from "../../scenes/auth/hooks/useMe";
import { useTranslation } from "next-i18next";

/**
 * Header account avatar icon and label.
 */
const HeaderAccountAvatar = () => {
  const { t } = useTranslation();
  const { isLoggedIn } = useAuth();
  const { data } = useMe();

  const me = data?.me;

  const missingInfo = useMemo(() => {
    return AccountUtils.isInfoMissing(me);
  }, [me]);

  return (
    <div className="flex cursor-pointer flex-col items-center md:space-y-1">
      <div className="relative">
        <ProfileOutlinedIcon />
        {missingInfo && (
          <div className="absolute right-0 top-0 flex h-2 w-2 items-center justify-center rounded-full bg-attention-500" />
        )}
      </div>
      <NoSSR>
        <span className="hidden font-body text-sm group-hover:text-attention-500 lg:inline">
          {isLoggedIn ? t("header:nav-account") : t("header:nav-account-login")}
        </span>
      </NoSSR>
    </div>
  );
};

export default HeaderAccountAvatar;
