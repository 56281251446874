import { IconProps } from "@/common/contracts/icon.contracts";

const Maintenance = ({ className }: IconProps) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_3878_10569)">
        <path
          d="M16.72 10.4549C14.68 8.4149 14.5 4.6849 14.5 4.0249H13V6.0249H9V4.0249H7C7 2.9249 7.9 2.0249 9 2.0249H16V3.0249C16 3.1049 16.04 7.6549 17.78 9.3949L16.72 10.4549ZM17 2.0249V4.0249H18V2.0249H17ZM15 12.0249C13 10.0249 13 7.0249 13 7.0249H9V9.0249C9 10.0249 9 10.0249 8 11.0249C7 12.0249 7 13.0249 7 13.0249V20.0249C7 21.1249 7.9 22.0249 9 22.0249H13C14.1 22.0249 15 21.1249 15 20.0249V12.0249Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3878_10569">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.0249023)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Maintenance;
