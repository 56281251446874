import { i18n } from "../../next-i18next.config";

const removeLocaleFromPath = (path: string): string => {
  for (const locale of i18n.locales) {
    if (path.startsWith(locale)) {
      return path.slice(locale.length);
    } else if (path.startsWith(`/${locale}/`)) {
      return path.slice(locale.length + 1);
    }
  }
  return path;
};

export default removeLocaleFromPath;
