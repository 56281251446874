import { IconProps } from "@/common/contracts/icon.contracts";

const Books = ({ className }: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M17.3288 3.01096C16.8854 2.68715 16.5036 2.4009 16.113 2.12718C15.6326 1.79441 15.154 1.23265 14.6561 1.21476C14.1581 1.19687 13.6778 1.73001 13.1921 2.02878L6.00795 6.45845C5.55751 6.73576 5.54695 6.79837 5.8971 7.2188C6.49358 7.93442 7.30473 8.19562 8.17394 8.32085C8.45898 8.362 8.57335 8.43714 8.57511 8.75022C8.58391 9.9131 8.56455 9.93278 7.45253 9.61612C6.52173 9.35134 5.70355 8.90408 5.46777 7.88254C5.33405 7.3172 5.39563 6.70355 5.39387 6.11138C5.39387 6.02551 5.51176 5.9128 5.6015 5.85734C8.71939 3.92994 11.8379 2.00671 14.9569 0.0876632C15.0311 0.052999 15.1075 0.023705 15.1857 0C15.2118 0.0723552 15.2266 0.14841 15.2297 0.22542C15.0977 0.926726 15.4056 1.35967 15.9951 1.68528C16.4265 1.93633 16.8347 2.22675 17.2144 2.55297C17.51 2.79628 17.7652 2.83027 18.0678 2.58696C18.2209 2.4836 18.3815 2.3921 18.5482 2.31324V2.86247C18.5482 7.73762 18.5482 12.6128 18.5482 17.4861C18.5594 17.6371 18.5254 17.788 18.4506 17.9189C18.3759 18.0498 18.2639 18.1547 18.1294 18.2196C15.1687 20.0278 12.2115 21.849 9.25781 23.6834C9.1364 23.7585 9.00972 23.8211 8.80209 23.9356V23.3989C8.80209 18.5238 8.80209 13.6486 8.79329 8.77348C8.78121 8.61596 8.81689 8.45845 8.89554 8.32219C8.97419 8.18592 9.09203 8.07743 9.23318 8.01135C11.7845 6.45726 14.3305 4.89185 16.8713 3.3151C17.0033 3.23281 17.1405 3.14335 17.3288 3.01096ZM17.093 5.54604C16.8907 5.66054 16.764 5.72495 16.6443 5.7983C14.6244 7.05063 12.608 8.30296 10.5792 9.52488C10.4305 9.59408 10.3064 9.7081 10.2236 9.85148C10.1408 9.99485 10.1034 10.1606 10.1165 10.3264C10.1341 13.6236 10.127 16.9208 10.127 20.218C10.127 20.3737 10.1411 20.5293 10.1499 20.744C10.3258 20.6563 10.4385 20.6062 10.5458 20.54C12.6168 19.2716 14.6772 17.9889 16.7605 16.724C16.8745 16.6629 16.9678 16.5682 17.028 16.4524C17.0883 16.3365 17.1128 16.2048 17.0983 16.0746C17.0913 12.7493 17.0913 9.42231 17.0983 6.09349L17.093 5.54604Z"
        fill="currentColor"
      />
      <path
        d="M5.3771 8.57294C6.13546 9.48177 7.09792 9.85032 8.17652 9.97734C8.42637 10.006 8.57241 10.0704 8.57065 10.3745C8.56361 14.3414 8.56361 18.3077 8.57065 22.2734C8.56523 22.3436 8.55345 22.4131 8.53546 22.481C6.9642 22.5615 5.31023 21.5417 5.3507 19.835C5.43692 16.2569 5.3771 12.6788 5.3771 9.10071V8.57294ZM7.50965 20.9174C7.53956 20.8494 7.55188 20.8368 7.55188 20.8225C7.55892 17.7931 7.56302 14.7642 7.5642 11.736C7.56253 11.6802 7.54854 11.6255 7.52326 11.5759C7.49798 11.5264 7.46206 11.4832 7.41816 11.4497C6.93428 11.1796 6.45217 10.9363 5.9507 10.6751C5.93769 10.7194 5.92943 10.7649 5.92607 10.811C5.92607 13.8095 5.92607 16.8091 5.92607 19.8099C5.92544 19.9539 5.9775 20.0929 6.07211 20.1999C6.46847 20.5805 6.97034 20.8278 7.50965 20.9084V20.9174Z"
        fill="currentColor"
      />
      <path
        d="M8.51787 23.9952C6.75834 24.0883 5.20995 22.7429 5.44045 21.2526C5.97887 22.0219 6.70555 22.4119 7.54133 22.6159C7.5994 22.6302 7.65394 22.6534 7.71729 22.6642C8.65864 22.8646 8.69383 22.929 8.51787 23.9952Z"
        fill="currentColor"
      />
      <path
        d="M16.8763 5.94794V6.53653C16.8763 9.65663 16.8763 12.7785 16.8763 15.8986C16.8893 16.0488 16.8577 16.1995 16.7855 16.3313C16.7134 16.463 16.604 16.5697 16.4716 16.6375C14.566 17.7932 12.6745 18.9632 10.7777 20.1369C10.6704 20.2031 10.5543 20.2549 10.3889 20.3408C10.3783 20.1351 10.366 19.983 10.366 19.8488C10.366 16.6983 10.366 13.5478 10.366 10.3991C10.3503 10.2549 10.378 10.1092 10.4456 9.9814C10.5131 9.85359 10.6173 9.74969 10.7443 9.68346C12.6287 8.54384 14.5009 7.3756 16.3924 6.21629C16.5138 6.14115 16.6422 6.08212 16.8763 5.94794Z"
        fill="currentColor"
      />
      <path
        d="M6.16248 11.0729C6.27724 11.1505 6.39923 11.2164 6.5267 11.2697C7.28506 11.4486 7.37831 11.9692 7.36424 12.6867C7.31497 15.1376 7.34488 17.5904 7.34488 20.0432V20.5799C6.49151 20.4851 6.16248 20.1309 6.16248 19.3473V11.0729Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Books;
