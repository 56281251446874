import { HEADER_PINNED, HEADER_UNPINNED } from "@/common/static/header.static";
import HeaderBanner from "@/components/MainHeader/HeaderBanner";
import Headroom from "react-headroom";
import React, { useEffect, useRef, useState } from "react";
import { HeaderEventsBanner } from "@/components/Header/HeaderEventsBanner";
import { HeaderDesktop } from "@/components/Header/HeaderDesktop";
import { HeaderMobile } from "@/components/Header/HeaderMobile";

const HEADER_STYLE: React.CSSProperties = {
  zIndex: 100,
};

// Merge interface declaration with additional `id` prop.
declare module "react-headroom" {
  interface ReactHeadroomProps {
    id?: string;
  }
}

type HeaderProps = {
  focused?: boolean;
  hideBanner?: boolean;
};

const HEADER_PIN_EVENT = new Event(HEADER_PINNED);
const HEADER_UNPIN_EVENT = new Event(HEADER_UNPINNED);

type HeaderBannerWrapperProps = {
  hideBanner?: boolean;
  onMounted?: (height: number) => void;
};

const HeaderBannerWrapper = ({
  hideBanner = false,
  onMounted,
}: HeaderBannerWrapperProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      onMounted?.(ref.current.getBoundingClientRect().height);
    }
  }, [onMounted]);

  if (hideBanner) {
    return null;
  }

  return (
    <div ref={ref} className="flex flex-col">
      <HeaderEventsBanner />
      <HeaderBanner.Desktop />
      <HeaderBanner.Mobile />
    </div>
  );
};

const Header = ({
  focused = false,
  hideBanner = false,
  className,
}: HeaderProps & { className?: string }) => {
  const [pinStart, setPinStart] = useState<number | undefined>(undefined);

  const handleBannerMounted = (height: number) => {
    setPinStart(height);
  };

  const handlePin = () => {
    document.dispatchEvent(HEADER_PIN_EVENT);
  };

  const handleUnpin = () => {
    document.dispatchEvent(HEADER_UNPIN_EVENT);
  };

  return (
    <>
      <HeaderBannerWrapper
        hideBanner={hideBanner}
        onMounted={handleBannerMounted}
      />
      <Headroom
        onPin={handlePin}
        onUnpin={handleUnpin}
        id="main-header"
        style={HEADER_STYLE}
        className={className}
        pinStart={pinStart}
      >
        <HeaderDesktop focused={focused} />
        <HeaderMobile focused={focused} />
      </Headroom>
    </>
  );
};

export default Header;
