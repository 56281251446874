import { GRAPHQL_ENDPOINT } from "@/config";
import { HttpLink } from "@apollo/client";

/**
 * Creates HTTP link for Apollo Client. This should be the last link in the
 * Apollo Link chain.
 */
export const createHttpLink = () => {
  return new HttpLink({ uri: GRAPHQL_ENDPOINT });
};
