import { useMegaMenuData } from "@/components/mega-menu/hooks/useMegaMenuData";
import { useMemo } from "react";

const EMPTY_MENU = { items: [], label: "" };

export const useHeaderAccountMobileData = () => {
  const { data: megaMenuData } = useMegaMenuData({ mobile: true });

  const data = useMemo(() => {
    const mobilePanel = megaMenuData?.items.find(
      item => item.href === "/account"
    );

    if (!mobilePanel) {
      return EMPTY_MENU;
    }

    return mobilePanel;
  }, [megaMenuData?.items]);

  return { data };
};
