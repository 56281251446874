import {
  CART_SIGNUP_URL,
  CART_OVERVIEW_URL,
} from "@/common/static/checkout/checkout.static";

type PathNameToPageTypeMapEntry = {
  pathName: string | string[];
  pageType: string;
  exact?: boolean;
};

/**
 * Array containing maps between first component of `window.location.pathname`
 * and `page_type` Google Tag Manager parameter.
 *
 * NOTE: Last entry marked with `*` is a "catch-all" because of the way we "capture" urls that
 * should result in search page being shown.
 */
export const PATHNAME_PAGETYPE_MAP_ARRAY: PathNameToPageTypeMapEntry[] = [
  {
    pathName: "/",
    pageType: "home",
    exact: true,
  },
  {
    pathName: "/create-ad",
    pageType: "create_ad_flow",
  },
  {
    pathName: ["/products", "/producten", "/produkte", "/produits"],
    pageType: "pdp",
  },
  {
    pathName: "/account/login",
    pageType: "login",
  },
  {
    pathName: "/account/signup",
    pageType: "sign_up",
  },
  {
    pathName: "/account",
    pageType: "my_account",
  },
  {
    pathName: ["/collection", "/collections"],
    pageType: "collection",
  },
  {
    pathName: ["/brand", "/brands"],
    pageType: "brand",
  },
  {
    pathName: ["/icon", "/icons"],
    pageType: "icon",
  },
  {
    pathName: "/showroom",
    pageType: "showroom",
  },
  {
    pathName: "/similar",
    pageType: "similar",
  },
  {
    pathName: CART_SIGNUP_URL,
    pageType: "cart_signup",
  },
  {
    pathName: CART_OVERVIEW_URL,
    pageType: "cart_overview",
  },
  {
    pathName: ["/cart", "/cart2"],
    pageType: "cart",
  },
  {
    pathName: ["/checkout", "/checkout2"],
    pageType: "checkout",
  },
  {
    pathName: "/blog",
    pageType: "blog",
  },
  {
    pathName: [
      "/new",
      "/nieuw",
      "/neu",
      "/sale",
      "/most-popular",
      "/meest-populair",
      "/am-beliebtesten",
      "/furniture",
      "/meubels",
      "/mobel",
      "/lighting",
      "/verlichting",
      "/beleuchtung",
      "/art",
      "/kunst",
      "/decoration",
      "/decoratie",
      "/dekoration",
      "/tableware",
      "/tafelwaar",
      "/tafelgeschirr",
      "/coffee-table-books",
      "/koffietafelboeken",
      "/kaffeetisch-bucher",
    ],
    pageType: "plp",
  },
  {
    pathName: "/chat",
    pageType: "chat",
  },
  {
    pathName: "*",
    pageType: "search_results",
  },
];
