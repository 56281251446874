import { Currency, Price } from "@/Apollo/schema";
import type { Locale } from "next/router";
import localizedFormat from "dayjs/plugin/localizedFormat";
import dayjs from "dayjs";
import "dayjs/locale/de";
import "dayjs/locale/nl";
import "dayjs/locale/fr";

dayjs.extend(localizedFormat);

const DEFAULT_CURRENCY = Currency.Eur;
const DEFAULT_LOCALE: Locale = "en";
const DEFAULT_FRACTION_DIGITS = 2;

export type FormatPricePrice = Price | number | null;

export type FormatPriceOptions = {
  currency?: Currency;
  locale: Locale;
  fractionDigits?: number;
};

/**
 * Function that formats currency.
 *
 * @param price Either Price object (with amount and/or currency) or amount to format.
 * @param options Set of options that change the way currency will be formatted, such as currency, locale or number of fraction digits.
 */
export const formatPrice = (
  price?: FormatPricePrice,
  options: FormatPriceOptions = {
    currency: DEFAULT_CURRENCY,
    locale: DEFAULT_LOCALE,
    fractionDigits: DEFAULT_FRACTION_DIGITS,
  }
) => {
  if (!price) {
    return "";
  }

  const amount = typeof price === "number" ? price : price.amount;

  const currency =
    typeof price === "number"
      ? options.currency ?? DEFAULT_CURRENCY
      : price.currency ?? options.currency ?? DEFAULT_CURRENCY;

  return new Intl.NumberFormat(options.locale, {
    style: "currency",
    minimumFractionDigits: Number.isInteger(amount)
      ? 0
      : options.fractionDigits ?? DEFAULT_FRACTION_DIGITS,
    currency: currency,
  }).format(amount);
};

export const formatDateWithLocale = (
  value: string,
  format: string,
  locale: Locale
) => {
  return dayjs(value).locale(locale).format(format);
};
