import { cva } from "class-variance-authority";
import { ComponentProps, ForwardedRef, forwardRef, Ref } from "react";
import { cn } from "@/common/utils/css.utils";

type BodyTextColor = "default" | "muted";

const BodyStyle = cva("font-body text-base", {
  variants: {
    color: {
      default: "text-black",
      muted: "text-grey-700",
    },
  },
  defaultVariants: {
    color: "default",
  },
});

type BodyBaseParagraphProps = {
  as: "p";
  color?: BodyTextColor;
  paragraphRef?: Ref<HTMLParagraphElement>;
} & Omit<ComponentProps<"p">, "ref">;

type BodyBaseSpanProps = {
  as?: "span";
  color?: BodyTextColor;
  spanRef?: Ref<HTMLSpanElement>;
} & Omit<ComponentProps<"span">, "ref">;

type BodyBaseProps = BodyBaseParagraphProps | BodyBaseSpanProps;

export const BodyBase = (props: BodyBaseProps) => {
  const { color, className, ...rest } = props;

  if (rest.as === "p") {
    const { paragraphRef, ...restProps } = rest;

    return (
      <p
        ref={paragraphRef}
        className={cn(BodyStyle({ color }), className)}
        {...restProps}
      />
    );
  }

  const { spanRef, ...restProps } = rest;

  return (
    <span
      ref={spanRef}
      className={cn(BodyStyle({ color }), className)}
      {...restProps}
    />
  );
};

BodyBase.displayName = "Body";

export type BodyProps =
  | Omit<BodyBaseParagraphProps, "paragraphRef">
  | Omit<BodyBaseSpanProps, "spanRef">;

/**
 * Body text component
 */
export const Body = forwardRef<
  HTMLParagraphElement | HTMLSpanElement,
  BodyProps
>((props, ref) => {
  if (props.as === "p") {
    return (
      <BodyBase
        {...props}
        paragraphRef={ref as ForwardedRef<HTMLParagraphElement>}
      />
    );
  }

  return <BodyBase {...props} spanRef={ref} />;
});
