import { MegaMenuPanelData } from "@/common/contracts/mega-menu.contracts";
import { MegaMenuHeroImage } from "@/components/mega-menu/MegaMenuHeroImage";
import { MegaMenuLinkItemList } from "@/components/mega-menu/MegaMenuLinkItemList";
import { MegaMenuSeeAllItem } from "@/components/mega-menu/MegaMenuSeeAllItem";
import { Heading } from "@/ui/cva/Heading";
import { useMemo, useState } from "react";

type MegaMenuPanelLinksProps = {
  title: string;
  data: MegaMenuPanelData;
};

export const MegaMenuPanelLinks = ({
  title,
  data,
}: MegaMenuPanelLinksProps) => {
  const [imageIndex, setImageIndex] = useState(0);

  const all = useMemo(() => {
    return data.items?.find(item => item.type === "all");
  }, [data.items]);

  const links = useMemo(() => {
    return data.items?.filter(item => item.type === "link" || !item.type);
  }, [data.items]);

  const handleItemSelect = (index: number) => {
    setImageIndex(index);
  };

  return (
    <div className="flex flex-1 flex-col items-start space-y-10">
      <div className="flex items-baseline space-x-2">
        <Heading as="span" size="h3">
          {title}
        </Heading>
        <MegaMenuSeeAllItem item={all} />
      </div>
      <div className="flex justify-between space-x-12 self-stretch">
        <div className="flex justify-between space-x-12">
          {links && (
            <MegaMenuLinkItemList
              depth={1}
              items={links}
              visible={true}
              onItemSelect={handleItemSelect}
            />
          )}
        </div>
        <MegaMenuHeroImage
          singleImageItem={all}
          multipleImageItems={{
            items: links ?? [],
            itemIndex: imageIndex,
          }}
        />
      </div>
    </div>
  );
};
