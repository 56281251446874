import {
  QuerySuggestionsQuery,
  QuerySuggestionsQueryVariables,
} from "@/Apollo/schema";
import { QueryHookOptions, useQuery } from "@apollo/client";
import queryGQL from "@/Apollo/query/querySuggestions.graphql";

export const useQuerySuggestions = (
  options?: QueryHookOptions<
    QuerySuggestionsQuery,
    QuerySuggestionsQueryVariables
  >
) => {
  return useQuery(queryGQL, options);
};
