import { cn } from "@/common/utils/css.utils";
import { ComponentProps } from "react";

type HelperProps = ComponentProps<"span">;

/**
 * Helper text component.
 *
 * @param className List of CSS classes to pass to the body component.
 * @param props Rest of the body props.
 */
export const Helper = ({ className, ...props }: HelperProps) => {
  return (
    <span
      className={cn("font-body text-sm text-black", className)}
      {...props}
    />
  );
};
