export const CountryUtils = {
  getCountryName: (countryCode: string, language: string) => {
    try {
      const regionNames = new Intl.DisplayNames([language], {
        type: "region",
      });

      return regionNames.of(countryCode);
    } catch (e) {
      return countryCode;
    }
  },
};
