import { IconProps } from "@/common/contracts/icon.contracts";

export const Spark = (props: IconProps) => {
  return (
    <svg
      width="44"
      height="22"
      viewBox="0 0 44 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.0629 18.7018C22.541 18.7028 23.0015 18.5283 23.3508 18.2136C23.7002 17.899 23.9121 17.4679 23.9435 17.008L24.8893 3.15973C24.9127 3.02098 24.9252 2.88073 24.9269 2.74016C24.9269 2.71685 24.9269 2.69614 24.9269 2.67283V2.62103C24.895 1.91559 24.5819 1.24923 24.0528 0.760904C23.5238 0.272582 22.8195 0 22.087 0C21.3546 0 20.6503 0.272582 20.1212 0.760904C19.5921 1.24923 19.279 1.91559 19.2472 2.62103H19.2123L20.1822 17.008C20.2136 17.4679 20.4255 17.899 20.7749 18.2136C21.1242 18.5283 21.5848 18.7028 22.0629 18.7018Z"
        fill="currentColor"
      />
      <path
        d="M31.9889 20.8501L42.8163 11.7025C42.9356 11.6254 43.0479 11.5388 43.1521 11.4435L43.1978 11.3943L43.2381 11.3606C43.7415 10.842 44.0162 10.155 44.0039 9.44479C43.9917 8.73458 43.6936 8.05676 43.1725 7.55449C42.6515 7.05223 41.9484 6.76485 41.2116 6.75306C40.4749 6.74127 39.7622 7.00599 39.2242 7.49131L39.2 7.468L29.3371 18.2913C29.0249 18.6375 28.8605 19.085 28.877 19.5435C28.8935 20.0019 29.0897 20.4374 29.426 20.7619C29.7624 21.0865 30.2138 21.276 30.6894 21.2924C31.165 21.3088 31.6294 21.1507 31.9889 20.8501Z"
        fill="currentColor"
      />
      <path
        d="M14.5785 20.766C14.9156 20.4404 15.1118 20.0036 15.1273 19.5438C15.1427 19.0841 14.9763 18.6359 14.6618 18.29L4.80701 7.44859L4.78015 7.4719C4.24211 6.98658 3.52945 6.72186 2.7927 6.73365C2.05595 6.74544 1.35281 7.03282 0.831776 7.53509C0.310745 8.03735 0.0126227 8.71517 0.00039171 9.42538C-0.0118393 10.1356 0.262775 10.8226 0.766224 11.3412L0.806523 11.3749C0.822643 11.3904 0.836075 11.4086 0.854882 11.4241C0.958714 11.5187 1.07011 11.6053 1.18804 11.6831L12.0154 20.8307C12.3719 21.1344 12.8346 21.2967 13.3102 21.2846C13.7858 21.2726 14.239 21.0873 14.5785 20.766Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const WhoppahSpark = (props: IconProps) => {
  return (
    <svg
      width="31"
      height="39"
      viewBox="0 0 31 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.4545 29.9404L17.7199 19.8782H13.2802L9.54562 29.9038L6.32269 19.8782H0.152832L6.48089 38.5556H11.9439L15.5202 28.4552L19.0562 38.5556H24.5192L30.8473 19.8782H24.6774L21.4545 29.9404Z"
        fill="currentColor"
      />
      <path
        d="M15.5969 12.0729C15.8964 12.0735 16.1849 11.9668 16.4037 11.7744C16.6226 11.5821 16.7553 11.3185 16.775 11.0373L17.3674 2.57072C17.3821 2.48589 17.39 2.40015 17.391 2.31421C17.391 2.29996 17.391 2.28729 17.391 2.27304V2.24137C17.3711 1.81007 17.1749 1.40267 16.8435 1.10412C16.5121 0.805568 16.0709 0.638916 15.6121 0.638916C15.1532 0.638916 14.7121 0.805568 14.3807 1.10412C14.0492 1.40267 13.8531 1.81007 13.8332 2.24137H13.8113L14.4188 11.0373C14.4385 11.3185 14.5713 11.5821 14.7901 11.7744C15.009 11.9668 15.2974 12.0735 15.5969 12.0729Z"
        fill="currentColor"
      />
      <path
        d="M21.8148 13.3864L28.5973 7.7937C28.672 7.74656 28.7424 7.6936 28.8076 7.63536L28.8363 7.60527L28.8615 7.58469C29.1769 7.26759 29.3489 6.84758 29.3412 6.41336C29.3336 5.97915 29.1468 5.56474 28.8204 5.25767C28.4941 4.95059 28.0536 4.7749 27.5921 4.76769C27.1306 4.76048 26.6841 4.92232 26.3471 5.21904L26.332 5.20479L20.1537 11.822C19.9581 12.0336 19.8551 12.3072 19.8655 12.5875C19.8758 12.8678 19.9987 13.134 20.2094 13.3325C20.4201 13.5309 20.7029 13.6468 21.0008 13.6568C21.2987 13.6668 21.5896 13.5702 21.8148 13.3864Z"
        fill="currentColor"
      />
      <path
        d="M10.9086 13.3348C11.1198 13.1358 11.2427 12.8687 11.2524 12.5877C11.262 12.3066 11.1578 12.0326 10.9608 11.8211L4.78756 5.19282L4.77073 5.20707C4.4337 4.91036 3.98727 4.74852 3.52576 4.75572C3.06425 4.76293 2.62379 4.93863 2.29741 5.24571C1.97102 5.55278 1.78427 5.96719 1.77661 6.4014C1.76895 6.83561 1.94097 7.25563 2.25634 7.57273L2.28159 7.59331C2.29168 7.60281 2.3001 7.61389 2.31188 7.62339C2.37692 7.68124 2.4467 7.73419 2.52057 7.78174L9.30303 13.3744C9.52634 13.5601 9.81616 13.6593 10.1141 13.652C10.412 13.6446 10.6959 13.5313 10.9086 13.3348Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const WhoppahFullSize = (props: IconProps) => {
  return (
    <svg
      width="152"
      height="40"
      viewBox="0 0 152 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M140.827 17.2498C138.619 17.2498 136.838 18.0358 135.497 19.4029V11.177H129.993V34.9195H135.494V26.957C135.494 23.6403 136.903 22.1708 139.028 22.1708C140.817 22.1708 142.02 23.5378 142.02 25.5548V34.9224H147.487V24.3926C147.498 20.0865 144.815 17.2498 140.827 17.2498Z"
        fill="currentColor"
      />
      <path
        d="M149.72 11.4287C148.466 11.4287 147.44 12.3863 147.44 13.7201C147.44 15.0426 148.466 16.0002 149.72 16.0002C150.974 16.0002 152 15.0426 152 13.7201C152 12.3863 150.974 11.4287 149.72 11.4287ZM149.72 15.5556C148.728 15.5556 147.907 14.769 147.907 13.7201C147.907 12.6485 148.728 11.8619 149.72 11.8619C150.723 11.8619 151.544 12.6485 151.544 13.7201C151.544 14.769 150.723 15.5556 149.72 15.5556ZM150.712 13.2983C150.712 12.8765 150.37 12.6029 149.857 12.6029H148.888V14.7006H149.344V14.028H149.686L150.278 14.7006H150.826L150.176 13.9824C150.506 13.8798 150.712 13.6289 150.712 13.2983ZM149.344 13.6175V13.0133H149.857C150.096 13.0133 150.256 13.1273 150.256 13.3097C150.256 13.4921 150.096 13.6175 149.857 13.6175H149.344Z"
        fill="currentColor"
      />
      <path
        d="M99.252 17.3865C97.0168 17.3865 95.2292 18.07 93.8878 19.2658V17.8988H88.3867V40.0004H93.8878V33.689C95.2292 34.8863 97.0168 35.5699 99.252 35.5699C103.825 35.5699 107.676 31.9106 107.676 26.4774C107.676 21.0779 103.825 17.3865 99.252 17.3865ZM98.1152 30.9212C95.88 30.9212 93.8848 29.1443 93.8848 26.4774C93.8848 23.8428 95.88 22.0351 98.1152 22.0351C100.521 22.0351 102.275 23.8457 102.275 26.4774C102.278 29.1443 100.524 30.9212 98.1152 30.9212Z"
        fill="currentColor"
      />
      <path
        d="M18.637 27.0626L15.3696 17.8004H11.4852L8.21783 27.029L5.39806 17.8004H0L5.53647 34.9929H10.3161L13.4451 25.6955L16.5387 34.9929H21.3184L26.8548 17.8004H21.4568L18.637 27.0626Z"
        fill="currentColor"
      />
      <path
        d="M39.1899 17.2498C36.9901 17.2498 35.201 18.0358 33.8611 19.4029V11.177H28.3585V34.9195H33.8611V26.957C33.8611 23.6403 35.2702 22.1708 37.395 22.1708C39.1826 22.1708 40.387 23.5378 40.387 25.5548V34.9224H45.8543V24.3926C45.8617 20.0865 43.1788 17.2498 39.1899 17.2498Z"
        fill="currentColor"
      />
      <path
        d="M56.7539 17.3865C51.5266 17.3865 47.1946 21.0106 47.1946 26.4101C47.1946 31.8448 51.5266 35.4674 56.7539 35.4674C61.8766 35.4674 66.2101 31.8448 66.2101 26.4101C66.2101 21.0106 61.8766 17.3865 56.7539 17.3865ZM56.7539 30.8524C54.5187 30.8524 52.4837 29.1091 52.4837 26.4086C52.4837 23.7433 54.5128 21.9664 56.7539 21.9664C58.886 21.9664 60.9151 23.7433 60.9151 26.4086C60.9151 29.1121 58.886 30.8539 56.7539 30.8539V30.8524Z"
        fill="currentColor"
      />
      <path
        d="M78.5525 17.3865C76.3173 17.3865 74.5297 18.07 73.1883 19.2658V17.8988H67.6872V40.0004H73.1883V33.689C74.5297 34.8863 76.3173 35.5699 78.5525 35.5699C83.126 35.5699 86.9765 31.9106 86.9765 26.4774C86.9765 21.0779 83.126 17.3865 78.5525 17.3865ZM77.4217 30.9212C75.1865 30.9212 73.1927 29.1443 73.1927 26.4774C73.1927 23.8428 75.1865 22.0351 77.4217 22.0351C79.8291 22.0351 81.5829 23.8457 81.5829 26.4774C81.5784 29.1443 79.8247 30.9212 77.4217 30.9212Z"
        fill="currentColor"
      />
      <path
        d="M122.874 19.2658C121.533 18.07 119.745 17.3865 117.51 17.3865C112.937 17.3865 109.086 21.0779 109.086 26.4774C109.086 31.9121 112.937 35.5699 117.51 35.5699C119.745 35.5699 121.533 34.8863 122.874 33.689V35.0561H128.376V17.8988H122.874V19.2658ZM118.646 30.9212C116.238 30.9212 114.484 29.1443 114.484 26.4774C114.484 23.8428 116.238 22.0351 118.646 22.0351C120.879 22.0351 122.874 23.8457 122.874 26.4774C122.874 29.1443 120.879 30.9212 118.646 30.9212Z"
        fill="currentColor"
      />
      <path
        d="M77.1567 10.5693C77.4187 10.5699 77.6711 10.4712 77.8625 10.2934C78.054 10.1156 78.1701 9.87195 78.1873 9.61205L78.7056 1.78571C78.7184 1.7073 78.7253 1.62804 78.7262 1.5486C78.7262 1.53542 78.7262 1.52371 78.7262 1.51054V1.48127C78.7088 1.08259 78.5372 0.705996 78.2472 0.430023C77.9573 0.154049 77.5713 0 77.1699 0C76.7685 0 76.3826 0.154049 76.0926 0.430023C75.8027 0.705996 75.6311 1.08259 75.6136 1.48127H75.5945L76.126 9.61205C76.1432 9.87195 76.2594 10.1156 76.4508 10.2934C76.6423 10.4712 76.8947 10.5699 77.1567 10.5693Z"
        fill="currentColor"
      />
      <path
        d="M82.5936 11.7828L88.5273 6.61306C88.5927 6.56949 88.6542 6.52053 88.7113 6.46669L88.7364 6.43888L88.7584 6.41985C89.0343 6.12673 89.1848 5.73849 89.1781 5.33711C89.1714 4.93574 89.0081 4.55267 88.7225 4.26881C88.437 3.98496 88.0517 3.82255 87.6479 3.81589C87.2441 3.80922 86.8536 3.95883 86.5587 4.2331L86.5455 4.21993L81.1404 10.3367C80.9693 10.5324 80.8792 10.7852 80.8883 11.0444C80.8973 11.3035 81.0048 11.5495 81.1891 11.733C81.3735 11.9164 81.6209 12.0235 81.8815 12.0328C82.1422 12.042 82.3966 11.9527 82.5936 11.7828Z"
        fill="currentColor"
      />
      <path
        d="M73.0529 11.7358C73.2376 11.5519 73.3451 11.305 73.3536 11.0451C73.3621 10.7853 73.2709 10.532 73.0985 10.3365L67.6979 4.20955L67.6832 4.22273C67.3883 3.94845 66.9978 3.79885 66.594 3.80551C66.1902 3.81217 65.8049 3.97459 65.5194 4.25844C65.2338 4.54229 65.0705 4.92536 65.0638 5.32673C65.057 5.72811 65.2075 6.11636 65.4834 6.40947L65.5055 6.4285C65.5144 6.43728 65.5217 6.44753 65.532 6.45631C65.5889 6.50979 65.65 6.55873 65.7146 6.60268L71.6483 11.7724C71.8436 11.9441 72.0972 12.0357 72.3578 12.029C72.6185 12.0222 72.8668 11.9174 73.0529 11.7358Z"
        fill="currentColor"
      />
    </svg>
  );
};
