import { cva } from "class-variance-authority";
import React, { ComponentProps } from "react";
import { cn } from "@/common/utils/css.utils";

type HeadingTags = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
type HeadingBodyTags = "p" | "span";

type HeadingProps = ComponentProps<HeadingTags> & {
  as: HeadingTags | HeadingBodyTags;
  size?: HeadingTags;
};

const HeadingStyles = cva("font-title font-bold text-black", {
  variants: {
    size: {
      h1: "text-3xl md:text-5xl",
      h2: "text-2xl md:text-3xl",
      h3: "text-lg md:text-2xl",
      h4: "text-base md:text-lg",
      h5: "text-sm md:text-base",
      h6: "text-sm md:text-sm",
    },
  },
});

/**
 * Simple heading component.
 *
 * @param as Render heading as `<h1>`, `<h2>`, `<h3>`, `<h4>`, `<h5>`, `<h6>`, `<p>` or `<span>`.
 * @param size Heading style to apply.
 * @param className List of CSS classes to pass to the heading, p or span tag.
 * @param props Rest of the heading props.
 */
export const Heading = ({
  as,
  size = as === "p" || as === "span" ? "h6" : as,
  className,
  ...props
}: HeadingProps) => {
  const Component = as;

  return (
    <Component className={cn(HeadingStyles({ size }), className)} {...props} />
  );
};
