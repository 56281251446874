import { IconProps } from "@/common/contracts/icon.contracts";

export const ProfileOutlined = ({ className }: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7C17 8.32608 16.4732 9.59785 15.5355 10.5355C14.5979 11.4732 13.3261 12 12 12C10.6739 12 9.40215 11.4732 8.46447 10.5355C7.52678 9.59785 7 8.32608 7 7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2ZM12 4.5C11.337 4.5 10.7011 4.76339 10.2322 5.23223C9.76339 5.70107 9.5 6.33696 9.5 7C9.5 7.66304 9.76339 8.29893 10.2322 8.76777C10.7011 9.23661 11.337 9.5 12 9.5C12.663 9.5 13.2989 9.23661 13.7678 8.76777C14.2366 8.29893 14.5 7.66304 14.5 7C14.5 6.33696 14.2366 5.70107 13.7678 5.23223C13.2989 4.76339 12.663 4.5 12 4.5ZM12 13.25C15.3375 13.25 22 14.9125 22 18.25V22H2V18.25C2 14.9125 8.6625 13.25 12 13.25ZM12 15.625C8.2875 15.625 4.375 17.45 4.375 18.25V19.625H19.625V18.25C19.625 17.45 15.7125 15.625 12 15.625Z"
        fill="currentColor"
      />
    </svg>
  );
};
