import { MegaMenuItemData } from "@/common/contracts/mega-menu.contracts";
import { MegaMenuLinkItem } from "@/components/mega-menu/MegaMenuLinkItem";
import { Heading } from "@/ui/cva/Heading";
import Link from "@whoppah/next/link";
import {
  Fragment,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  NavigationMenuItem,
  NavigationMenuLink,
} from "@radix-ui/react-navigation-menu";
import { cn } from "@/common/utils/css.utils";

type MegaMenuLinkItemListProps = {
  depth?: number;
  items: MegaMenuItemData[];
  onItemSelect?: (index: number) => void;
};

export const MegaMenuLinkItemList = ({
  depth,
  items,
  visible,
  onItemSelect,
}: MegaMenuLinkItemListProps & { visible?: boolean }) => {
  const [subIndex, setSubIndex] = useState(0);

  const title = useMemo(() => {
    return items?.find(item => item.type === "links_title");
  }, [items]);

  const links = useMemo(() => {
    return items.filter(item => item.type !== "links_title");
  }, [items]);

  const getSubItems = useCallback(
    (index: number) => {
      const subItemsParent = items[index];
      return {
        id: subItemsParent?.id,
        title: subItemsParent?.label,
        items: subItemsParent?.items ?? [],
      };
    },
    [items]
  );

  const activeSubItems = useMemo(() => {
    return getSubItems(subIndex);
  }, [subIndex, getSubItems]);

  /** Reset selected sub-item to first, each time items has changed. */
  useEffect(() => {
    setSubIndex(0);
  }, [items]);

  const handleSelect = (index: number) => (evt: Event) => {
    if (getSubItems(index).items.length) {
      /** If there are sub-items, don't close the panel. */
      evt.preventDefault();
    }

    setSubIndex(index);
    onItemSelect?.(index);
  };

  const handleClick =
    (index: number) => (evt: MouseEvent<HTMLAnchorElement>) => {
      if (getSubItems(index).items.length) {
        /** If there are sub-items, don't navigate. */
        evt.preventDefault();
      }
    };

  return (
    <>
      <div
        className={cn(
          "flex flex-col items-start space-y-4",
          !visible && "hidden"
        )}
      >
        {title?.href && (
          <ul>
            <NavigationMenuItem>
              <Link href={title.href} passHref={true} prefetch={false}>
                <NavigationMenuLink>
                  <Heading
                    as="span"
                    size="h5"
                    className="hover:text-brand-500 hover:underline"
                  >
                    {title.label}
                  </Heading>
                </NavigationMenuLink>
              </Link>
            </NavigationMenuItem>
          </ul>
        )}
        <ul className="flex flex-col items-start space-y-4">
          {links.map((item, index) => {
            const selected =
              index === subIndex && depth === 1 && !!item.items?.length;

            if (!item.href) {
              return null;
            }

            const href = item.href.startsWith("/http")
              ? item.href.substring(1)
              : item.href;

            return (
              <MegaMenuLinkItem
                key={item.id}
                href={href}
                label={item.label}
                selected={selected}
                external={href.startsWith("http")}
                onSelect={handleSelect(index)}
                onClick={handleClick(index)}
              />
            );
          })}
        </ul>
      </div>
      {links.map(item => {
        if (!item.items?.length) {
          return null;
        }

        return (
          <Fragment key={item.id}>
            <div
              className={cn(
                "border-r border-grey-300",
                item.id !== activeSubItems.id && "hidden"
              )}
            />
            <MegaMenuLinkItemList
              depth={(depth ?? 0) + 1}
              items={item.items}
              visible={item.id === activeSubItems.id}
              onItemSelect={onItemSelect}
            />
          </Fragment>
        );
      })}
    </>
  );
};
