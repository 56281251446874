import {
  formatPrice,
  FormatPriceOptions,
  FormatPricePrice,
} from "@/common/utils/intl.utils";
import { useTranslation } from "next-i18next";
import { useCallback } from "react";

/**
 * Hook that returns currency format function.
 *
 * @param options Set of options that change the way currency will be formatted, such as currency, locale or number of fraction digits.
 */
export const useFormatPrice = (options?: FormatPriceOptions) => {
  const format = useCallback(
    (price?: FormatPricePrice) => {
      return formatPrice(price, options);
    },
    [options]
  );

  return { formatPrice: format };
};

type FormatMinMaxOptions = {
  signGenerator?: (value: number) => string;
};

/**
 * Hook that returns min/max formatted string (price, dimensions, ...)
 * @param options
 */
export const useFormatMinMax = (options?: FormatMinMaxOptions) => {
  const { t } = useTranslation();

  const generateSign = useCallback(
    (value: number, signGenerator?: (value: number) => string) => {
      if (Number.isNaN(value)) {
        return "";
      }

      if (signGenerator) {
        return signGenerator(value);
      }

      return value.toString();
    },
    []
  );

  const format = useCallback(
    (min?: string | number, max?: string | number) => {
      const minAsNumber = Number(min);
      const minFormatted = generateSign(minAsNumber, options?.signGenerator);

      const maxAsNumber = Number(max);
      const maxFormatted = generateSign(maxAsNumber, options?.signGenerator);

      if (min && !max) {
        return `${t("common:common-from")} ${minFormatted}`;
      }

      if (!min && max) {
        return `${t("common:common-to")} ${maxFormatted}`;
      }

      return `${minFormatted} - ${maxFormatted}`;
    },
    [generateSign, options?.signGenerator, t]
  );

  return { format };
};
