import { useAuth } from "@/auth";
import { CommonModalActionProps } from "@/components/common/modal/contracts/modal.contracts";
import { Button } from "@/ui/cva/Button";
import { closeModal } from "@/ui/Modal2";
import { useTranslation } from "next-i18next";

type CommonModalActionLogOutProps = {
  onClick?: () => void;
} & CommonModalActionProps;

export const CommonModalActionLogOut = ({
  modal,
  onClick,
}: CommonModalActionLogOutProps) => {
  const { t } = useTranslation();
  const { logout } = useAuth();

  const handleClick = () => {
    logout();
    closeModal(modal);

    onClick?.();
  };

  return (
    <Button appearance="outlined" onClick={handleClick}>
      {t("common:common-log-out")}
    </Button>
  );
};
