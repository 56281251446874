import { Currency, Price, Product, ProductCondition } from "@/Apollo/schema";
import { PartialDeep } from "type-fest";

type ProductPriceInfo = {
  price: Price;
  soldAtPrice?: Price | null;
  originalPrice: Price;
  bidPrice?: Price | null;
  discounted: boolean;
  discountAmount: number;
  discountPercentage: number;
};

/**
 * Gets product price info, such as current price, sold at price, original price, bid price, whether the product is discounted, discount amount and percentage.
 *
 * @param product Product to get the price info from.
 */
export const getPriceInfo = (
  product: PartialDeep<Product, { recurseIntoArrays: true }>
): ProductPriceInfo => {
  const buyNowPrice = product.auction?.buy_now_price;
  const buyNowPriceAmount = buyNowPrice?.amount ?? 0;

  const soldAt = product.auction?.sold_at;

  const soldAtPrice: Price | undefined = soldAt
    ? {
        __typename: "Price",
        amount: soldAt?.amount ?? 0,
        currency: soldAt?.currency ?? Currency.Eur,
      }
    : undefined;

  const productBidPrice = product.auction?.minimum_bid;

  const bidPrice: Price | undefined = productBidPrice
    ? {
        __typename: "Price",
        amount: productBidPrice?.amount ?? 0,
        currency: productBidPrice?.currency ?? Currency.Eur,
      }
    : undefined;

  const originalPrice = product.original_price;
  const originalPriceAmount = originalPrice?.amount ?? buyNowPriceAmount;

  const prevPrice =
    buyNowPriceAmount < originalPriceAmount ? originalPrice : undefined;

  const prevPriceAmount = prevPrice?.amount ?? 0;

  const discounted = buyNowPriceAmount < prevPriceAmount;
  const discountAmount = discounted ? prevPriceAmount - buyNowPriceAmount : 0;
  const discountPercentage = discounted
    ? Math.round((discountAmount / prevPriceAmount) * 100)
    : 0;

  return {
    price: {
      __typename: "Price",
      amount: buyNowPriceAmount,
      currency: buyNowPrice?.currency ?? Currency.Eur,
    },
    soldAtPrice,
    originalPrice: {
      __typename: "Price",
      amount: originalPriceAmount,
      currency: originalPrice?.currency ?? Currency.Eur,
    },
    bidPrice,
    discounted,
    discountAmount,
    discountPercentage,
  };
};

export const getIsOfCondition = (
  product: PartialDeep<Product, { recurseIntoArrays: true }>,
  condition: ProductCondition
) => {
  return product.condition === condition;
};
