import { useHeaderLanguageSwitcherContext } from "@/components/Header/HeaderLanguageSwitcherContext";
import { MegaMenuMobileActionProps } from "@/components/mega-menu/mobile/MegaMenuMobileActionDelegator";
import { MegaMenuMobileBack } from "@/components/mega-menu/mobile/MegaMenuMobileBack";
import { MegaMenuMobileClose } from "@/components/mega-menu/mobile/MegaMenuMobileClose";
import { Flavor } from "@/ui/typography/Flavor";
import {
  Dialog,
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogTrigger,
} from "@radix-ui/react-dialog";
import Link from "@whoppah/next/link";
import { useRouter } from "next/router";
import { KeyboardEvent } from "react";
import { ChevronRight as ChevronRightIcon } from "@/assets/icons/24/ChevronRight";
import { cn } from "@/common/utils/css.utils";

export const MegaMenuMobileLanguageAction = ({
  onClick,
  children,
  className,
  ...props
}: MegaMenuMobileActionProps) => {
  const router = useRouter();
  const { urls } = useHeaderLanguageSwitcherContext();

  const handleKeyDown = (evt: KeyboardEvent<HTMLAnchorElement>) => {
    if (evt.key !== "Enter") {
      return;
    }

    onClick?.();
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <button
          {...props}
          className={cn("flex w-full items-center text-left", className)}
        >
          <Flavor className="flex-1">{children}</Flavor>
          <ChevronRightIcon />
        </button>
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay />
        <DialogContent className="fixed inset-0 z-[1000] flex flex-col space-y-6 bg-white p-4">
          <div className="flex items-center space-x-2 border-b border-b-grey-300 pb-4">
            <MegaMenuMobileBack />
            <span className="flex-1">{children}</span>
            <MegaMenuMobileClose onClick={onClick} />
          </div>
          <div className="flex flex-col space-y-6 overflow-y-auto overflow-x-hidden">
            <ul className="flex flex-col space-y-4">
              {router.locales?.map(locale => (
                <li
                  key={locale}
                  className="font-title text-sm text-grey-700 hover:text-attention-500"
                >
                  <Link
                    href={urls?.[locale] ?? router.asPath}
                    locale={locale}
                    prefetch={false}
                    passHref
                  >
                    <a
                      lang={locale}
                      hrefLang={locale}
                      role="link"
                      tabIndex={0}
                      onClick={onClick}
                      onKeyDown={handleKeyDown}
                    >
                      <Flavor className="uppercase">{locale}</Flavor>
                    </a>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};
