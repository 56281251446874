import { Operation } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";

/**
 * Checks if the operation is a subscription. This is useful for Apollo Link
 * split function.
 *
 * @param {Operation} operation  - The graphql operation to check.
 * @returns {boolean} True if the operation is a subscription, false otherwise.
 */
export const isSubscriptionOperation = (operation: Operation) => {
  const definition = getMainDefinition(operation.query);
  return (
    definition.kind === "OperationDefinition" &&
    definition.operation === "subscription"
  );
};
