import { SubscriptionHookOptions, useSubscription } from "@apollo/client";

// Apollo
import messageCreatedGql from "../../../subscriptions/chat/messageCreated.graphql";
import {
  MessageCreatedSubscription,
  MessageCreatedSubscriptionVariables,
} from "@whoppah/apollo/schema";

/**
 * Hook for subscribing to the `message created` event.
 * @param options - Hook typing options.
 * @returns Subscription results.
 */
export const useMessageCreatedSubscription = (
  options?: SubscriptionHookOptions<
    MessageCreatedSubscription,
    MessageCreatedSubscriptionVariables
  >
) => {
  return useSubscription(messageCreatedGql, {
    ...options,
    skip: typeof window === "undefined",
  });
};
