import { useEffect, useMemo, useRef, useState } from "react";
import { Statsig, StatsigOptions } from "statsig-react";
import StatsigJS, { StatsigUser } from "statsig-js";
import StatsigContext, {
  UpdateUserFunc,
} from "statsig-react/dist/StatsigContext";
import { noop } from "lodash-es";

type Props = {
  children: React.ReactNode | React.ReactNode[];
  sdkKey: string;
  user: StatsigUser;
  setUser?: UpdateUserFunc;
  initializeValues: Record<string, unknown>;
  options?: StatsigOptions;
};

/**
 * The StatsigProvider is the top level component that should be used to
 * wrap your application. It is responsible for initializing the SDK and
 * providing the context to the rest of the application.
 *
 * The StatsigProvider and StatsigSynchronousProvider exported from
 * statsig-react can be used only in client side (StatsigProvider) or
 * server side (StatsigSynchronousProvider) respectively.
 *
 * This custom StatsigProvider is a combination of both and can be used in
 * both client and server side. It behaves like StatsigProvider when
 * initializeValues is not provided and behaves like StatsigSynchronousProvider
 * when initializeValues is provided.
 *
 * NOTE - Most of the code is copied from the original StatsigProvider and
 * StatsigSynchronousProvider. The only difference is the way it behaves based
 * on the initializeValues prop which determines shouldBehaveLikeSynchronousProvider
 * variable. See the code there for more details.
 *
 * {@link https://github.com/statsig-io/react-sdk/blob/main/src/StatsigProvider.tsx}
 * {@link https://github.com/statsig-io/react-sdk/blob/main/src/StatsigSynchronousProvider.tsx}
 */
export const StatsigProvider = ({
  children,
  sdkKey,
  user,
  options,
  initializeValues,
  setUser,
}: Props) => {
  const [shouldBehaveLikeSynchronousProvider] = useState(() => {
    return Boolean(initializeValues);
  });

  const [userVersion, setUserVersion] = useState(0);
  const [initialized, setInitialized] = useState(true);
  const [hasNetworkValues, setHasNetworkValues] = useState(false);
  const resolver = useRef<(() => void) | null>(null);
  const firstUpdate = useRef(true);

  const statsigPromise = useRef<Promise<void>>(
    new Promise(resolve => {
      resolver.current = resolve;
    })
  );

  const userMemo = useMemo(() => {
    return user;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(user)]);

  useMemo(() => {
    Statsig.bootstrap(sdkKey, initializeValues, userMemo, options);
    return initializeValues;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(initializeValues)]);

  useEffect(() => {
    if (!shouldBehaveLikeSynchronousProvider) {
      return;
    }

    if (firstUpdate.current) {
      // this is the first time the effect ran
      // we dont want to modify state and trigger a rerender
      // and the SDK is already initialized/usable
      firstUpdate.current = false;

      if (typeof window !== "undefined") {
        window.__STATSIG_SDK__ = Statsig;
        window.__STATSIG_JS_SDK__ = StatsigJS;
        window.__STATSIG_RERENDER_OVERRIDE__ = () => {
          setUserVersion(userVersion + 1);
        };
      }
      return;
    }
    // subsequent runs should update the user
    setInitialized(false);
    Statsig.updateUser(user).then(() => {
      setUserVersion(userVersion + 1);
      setInitialized(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userMemo]);

  useEffect(() => {
    if (shouldBehaveLikeSynchronousProvider) {
      return;
    }

    if (Statsig.initializeCalled()) {
      statsigPromise.current = new Promise(resolve => {
        resolver.current = resolve;
      });

      Statsig.updateUser(user).then(() => {
        resolver.current && resolver.current();
        setUserVersion(version => version + 1);
      });

      return;
    }

    Statsig.setSDKPackageInfo({
      sdkType: "react-client",
      sdkVersion: "1.30.0", // TODO: get this from package.json
    });

    Statsig.initialize(sdkKey, userMemo, options).then(() => {
      setHasNetworkValues(true);
      resolver.current && resolver.current();
    });

    if (typeof window !== "undefined") {
      window.__STATSIG_SDK__ = Statsig;
      window.__STATSIG_JS_SDK__ = StatsigJS;
      window.__STATSIG_RERENDER_OVERRIDE__ = () => {
        setUserVersion(userVersion + 1);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userMemo]);

  useEffect(() => {
    Statsig.setReactContextUpdater(() =>
      setUserVersion(version => version + 1)
    );
    return () => {
      Statsig.setReactContextUpdater(null);
    };
  }, []);

  const contextValue = useMemo(() => {
    if (shouldBehaveLikeSynchronousProvider) {
      return {
        initialized: initialized,
        statsigPromise: null,
        userVersion,
        initStarted: Statsig.initializeCalled(),
        updateUser: setUser ?? noop,
      };
    }
    return {
      userVersion,
      initStarted: Statsig.initializeCalled(),
      updateUser: setUser ?? noop,
      initialized: hasNetworkValues,
      statsigPromise: statsigPromise,
    };
  }, [
    setUser,
    initialized,
    userVersion,
    hasNetworkValues,
    shouldBehaveLikeSynchronousProvider,
  ]);

  return (
    <StatsigContext.Provider value={contextValue}>
      {children}
    </StatsigContext.Provider>
  );
};
