import { ApolloLink, from, HttpLink, split } from "@apollo/client";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { sha256 } from "crypto-hash";

import { createErrorLink } from "./links/create-error-link";
import { createSubscriptionsLink } from "./links/create-subscriptions-link";
import { createHttpLink } from "./links/create-http-link";
import { AuthLinkOptions, createAuthLink } from "./links/create-auth-link";
import { createRetryLink } from "./links/create-retry-link";
import { isSubscriptionOperation } from "./utils";
import { createTimeoutLink } from "./links/create-timeout-link";

/**
 * Prepares the ApolloLink for the Queries and Mutations execution context.
 */
export const createApolloClientLink = (options?: AuthLinkOptions) => {
  const isServer = typeof window === "undefined";

  // Create the HTTP link. This should be the last link in the Apollo Link chain.
  const httpLink = createHttpLink();

  // Create the Apollo Link chain.
  const links: [...ApolloLink[], HttpLink] = [httpLink];

  // Create the persisted queries link. If the environment variable is set to
  // true, then the link will be added to the Apollo Link chain.
  if (process.env.NEXT_PUBLIC_APOLLO_PERSISTED_QUERIES === "true") {
    links.unshift(createPersistedQueryLink({ sha256 }));
  }

  // Create the auth link. This takes care of adding the Authorization header to
  // and X-User-Id header to the HTTP GraphQL request.
  links.unshift(createAuthLink(options));

  // Create the error link. If it's not a server, then the link will be added to
  // the Apollo Link chain.
  if (!isServer) {
    links.unshift(createErrorLink());
  }

  // Create the timeout link to handle timeouts.
  links.unshift(createTimeoutLink());

  // Create retry link to retry timeout errors.
  links.unshift(createRetryLink());

  // Create the Apollo Link chain from the links.
  const queriesAndMutationsLink = from(links);

  // If it's a server, then return the Apollo Link chain.
  if (isServer) {
    return queriesAndMutationsLink;
  }

  // If it's not a server, then create the subscriptions link.
  const subscriptionsLink = createSubscriptionsLink();

  // Create the split link. This is useful for separating the queries and
  // mutations from the subscriptions. If the operation is a subscription, then
  // the subscriptions link will be used, otherwise the queries and mutations link
  // will be used.
  return split(
    isSubscriptionOperation,
    subscriptionsLink,
    queriesAndMutationsLink
  );
};
