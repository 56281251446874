import { MegaMenuCardList } from "@/components/mega-menu/MegaMenuCardList";
import { MegaMenuPanelData } from "@/common/contracts/mega-menu.contracts";
import { MegaMenuHeroImage } from "@/components/mega-menu/MegaMenuHeroImage";
import { MegaMenuSeeAllItem } from "@/components/mega-menu/MegaMenuSeeAllItem";
import { Heading } from "@/ui/cva/Heading";
import { useMemo } from "react";

type MegaMenuSubmenuCardsProps = {
  title: string;
  data: MegaMenuPanelData;
};

export const MegaMenuPanelCards = ({
  title,
  data,
}: MegaMenuSubmenuCardsProps) => {
  const all = useMemo(() => {
    return data.items?.find(item => item.type === "all");
  }, [data.items]);

  const cards = useMemo(() => {
    return data.items?.filter(
      item => item.type?.startsWith("card") ?? !item.type
    );
  }, [data.items]);

  return (
    <div className="flex flex-1 flex-col items-start space-y-10">
      <div className="flex items-baseline space-x-2">
        <Heading as="span" size="h3">
          {title}
        </Heading>
        <MegaMenuSeeAllItem item={all} />
      </div>
      <div className="flex justify-between space-x-12 self-stretch">
        <MegaMenuCardList
          items={cards}
          className="max-w-[80rem] auto-rows-fr grid-cols-[repeat(2,minmax(12rem,1fr))] md:grid-cols-[repeat(3,minmax(12rem,1fr))] lg:grid-cols-[repeat(4,minmax(12rem,1fr))]"
        />
        <MegaMenuHeroImage singleImageItem={all} />
      </div>
    </div>
  );
};
