import { IconProps } from "@/common/contracts/icon.contracts";

const Tableware = ({ className }: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_3670_9918)">
        <path
          d="M7.5 7L5.5 5H18.5L16.5 7M11 13V19H6V21H18V19H13V13L21 5V3H3V5L11 13Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3670_9918">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Tableware;
