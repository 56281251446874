import EN_NL from "./locales/nl.json";
import EN_DE from "./locales/de.json";
import EN_FR from "./locales/fr.json";

const flipObject = obj => {
  return Object.fromEntries(Object.entries(obj).map(([k, v]) => [v, k]));
};

const mergeObject = (obj1, obj2) => {
  return Object.fromEntries(Object.entries(obj1).map(([k, v]) => [k, obj2[v]]));
};

// These mappings are temporary as ultimately we will parse it on backend.
const NL_EN = flipObject(EN_NL);
const DE_EN = flipObject(EN_DE);
const FR_EN = flipObject(EN_FR);

const NL_DE = mergeObject(NL_EN, EN_DE);
const NL_FR = mergeObject(NL_EN, EN_FR);
const FR_DE = mergeObject(FR_EN, EN_DE);

const DE_NL = flipObject(NL_DE);
const FR_NL = flipObject(NL_FR);
const DE_FR = flipObject(FR_DE);

const LANG_MAP = {
  en: { nl: EN_NL, de: EN_DE, fr: EN_FR },
  nl: { en: NL_EN, de: NL_DE, fr: NL_FR },
  de: { en: DE_EN, nl: DE_NL, fr: DE_FR },
  fr: { en: FR_EN, nl: FR_NL, de: FR_DE },
};

/**
 *  This function takes url (or href) and source and destination locale.
 *
 * @param {string} href - The url that needs to be localized
 * @param {import('next/router').Locale} sourceLocale - The current locale of the url
 * @param {import('next/router').Locale} destinationLocale - The locale in which the url should be returned
 * @returns {string} - The localized url
 */
const localizeHref = (href, sourceLocale, destinationLocale) => {
  if (href.startsWith("http")) return href;
  if (!destinationLocale) return href;
  if (sourceLocale === destinationLocale) return href;
  try {
    const MAP = LANG_MAP[sourceLocale][destinationLocale];
    const url = new URL(href, "https://www.whoppah.com");
    const newPathname = url.pathname
      .split("/")
      .map(path => MAP[path] ?? path)
      .join("/");
    return `${newPathname}${url.search}`;
  } catch (e) {
    return href;
  }
};

export default localizeHref;
