import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import {
  QueryHookOptions,
  useQuery,
  useLazyQuery,
  useReactiveVar,
} from "@apollo/client";

// Apollo
import queryGQL from "../../../query/chat/getUnreadMessagesCount.graphql";
import {
  GetUnreadMessageCountQuery,
  GetCollectionQueryVariables,
} from "@whoppah/apollo/schema";
import { unreadMessagesCountVar } from "@/variables/unreadMessagesCountVar";
import { lastThreadReadTimestampVar } from "@/variables/lastThreadReadTimestamp";

// Context
import { useAuth } from "auth";

// Hooks
import useMe from "scenes/auth/hooks/useMe";
import { useMessageCreatedSubscription } from "@/Apollo/hooks/chat/subscriptions/useMessageCreated";
import { useMessageUpdatedSubscription } from "@/Apollo/hooks/chat/subscriptions/useMessageUpdated";

// Static
import { CHAT_ROUTE } from "@/common/static/routes.static";

/**
 * Hook for getting unread messages count for the currently authenticated user.
 * @param options - Hook typing options.
 * @returns Query results.
 */
export const useUnreadMessagesCountQuery = (
  options?: QueryHookOptions<
    GetUnreadMessageCountQuery,
    GetCollectionQueryVariables
  >
) => {
  return useQuery(queryGQL, options);
};

/**
 * Hook for getting unread messages count for the currently authenticated user.
 * @param options - Hook typing options.
 * @returns Lazy query results.
 */
export const useUnreadMessagesCountLazyQuery = (
  options?: QueryHookOptions<
    GetUnreadMessageCountQuery,
    GetCollectionQueryVariables
  >
) => {
  return useLazyQuery(queryGQL, options);
};

/**
 * Hook for keeping track of the unread messages count for the currently authenticated user.
 * Listens for updates of messageCreated and messageUpdated hooks to keep the count updated.
 */
export const useUnreadMessagesCount = () => {
  const router = useRouter();
  const unreadMessagesCount = useReactiveVar(unreadMessagesCountVar);
  const lastThreadReadTimestamp = useReactiveVar(lastThreadReadTimestampVar);

  const { isLoggedIn } = useAuth();
  const { data: getMeQueryData } = useMe();

  // State for handling the last handled timestamp of the thread read action.
  const [lastHandledThreadReadTimestamp, setLastHandledThreadReadTimestamp] =
    useState<number | null>(null);

  // State for tracking the last handled messages that are coming over subscriptions.
  const [lastHandledCreatedMessageId, setLastHandledCreatedMessageId] =
    useState<string | null>(null);
  const [lastHandledUpdatedMessageId, setLastHandledUpdatedMessageId] =
    useState<string | null>(null);

  const [fetchUnreadMessagesCount] = useUnreadMessagesCountLazyQuery({
    fetchPolicy: "network-only",
  });

  const { data: messageCreatedSubscriptionData } =
    useMessageCreatedSubscription();
  const { data: messageUpdatedSubscriptionData } =
    useMessageUpdatedSubscription();

  const loadUnreadMessagesCount = useCallback(async () => {
    const count = await fetchUnreadMessagesCount();
    unreadMessagesCountVar(count.data?.getUnreadMessageCount || 0);
  }, [fetchUnreadMessagesCount]);

  /**
   * Hook used for reacting to new unread messages count data incoming from the server.
   */
  useEffect(() => {
    if (isLoggedIn) {
      loadUnreadMessagesCount();
    }
  }, [isLoggedIn, loadUnreadMessagesCount]);

  /**
   * Function for determining if the message should be included in the unread messages count.
   * If the authenticated user is the sender, and the message has been created it should be excluded.
   * If the authenticated user is the sender, and the message has been updated it should be included.
   * If the messages's thread screen is open, we can assume that the message is read and that it should be excluded.
   * @param data - Sender id and thread id of the message.
   * @return Boolean indicating if the message should be included or not.
   */
  const shouldIncludeMessage = useCallback(
    (data: {
      senderId: string;
      threadId: string;
      event: "create" | "update";
    }) => {
      const authUser = getMeQueryData?.me;
      if (!authUser) {
        return false;
      }

      // Authenticated user has sent the message.
      // Sender should still be notified if his message gets updated.
      if (data.senderId === authUser.id && data.event === "create") {
        return false;
      }

      // Based on the route, we can detect if the messaging screen for the given threadId is open or not.
      const route = router.asPath;
      if (route.includes(`${CHAT_ROUTE}/${data.threadId}`)) {
        return false;
      }

      return true;
    },
    [getMeQueryData, router]
  );

  /**
   * Hook for reacting to new messages coming over the messageCreated subscription.
   * Responsible for incrementing the overall unread messages count.
   * If the currently authenticated user is the sender, or if the messages's thread screen is open, the increment will be skipped.
   */
  useEffect(() => {
    const createdMessage = messageCreatedSubscriptionData?.messageCreated;
    if (!createdMessage || createdMessage.id === lastHandledCreatedMessageId) {
      return;
    }

    setLastHandledCreatedMessageId(createdMessage.id);

    if (
      !shouldIncludeMessage({
        senderId: createdMessage.sender.id,
        threadId: createdMessage.threadId,
        event: "create",
      })
    ) {
      return;
    }

    unreadMessagesCountVar(unreadMessagesCount + 1);
  }, [
    messageCreatedSubscriptionData,
    lastHandledCreatedMessageId,
    unreadMessagesCount,
    shouldIncludeMessage,
  ]);

  /**
   * Hook for reacting to updated messages coming over the messageUpdated subscription.
   * Responsible for incrementing the overall unread messages count.
   * If the currently authenticated user is the sender, or if the messages's thread screen is open, the increment will be skipped.
   */
  useEffect(() => {
    const updatedMessage = messageUpdatedSubscriptionData?.messageUpdated;
    if (!updatedMessage || updatedMessage.id === lastHandledUpdatedMessageId) {
      return;
    }

    setLastHandledUpdatedMessageId(updatedMessage.id);

    if (
      !shouldIncludeMessage({
        senderId: updatedMessage.sender.id,
        threadId: updatedMessage.threadId,
        event: "update",
      })
    ) {
      return;
    }

    unreadMessagesCountVar(unreadMessagesCount + 1);
  }, [
    messageUpdatedSubscriptionData,
    lastHandledUpdatedMessageId,
    unreadMessagesCount,
    shouldIncludeMessage,
  ]);

  /**
   * Hook responsible for reacting to the thread read event.
   * When a thread is read, the unread messages count should be refetched from the server.
   */
  useEffect(() => {
    if (
      !lastThreadReadTimestamp ||
      unreadMessagesCount === 0 ||
      lastThreadReadTimestamp === lastHandledThreadReadTimestamp
    ) {
      return;
    }

    loadUnreadMessagesCount();
    setLastHandledThreadReadTimestamp(lastThreadReadTimestamp);
  }, [
    lastThreadReadTimestamp,
    lastHandledThreadReadTimestamp,
    unreadMessagesCount,
    loadUnreadMessagesCount,
  ]);
};
