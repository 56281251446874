import { gql, useMutation } from "@apollo/client";

const SIGNUP_FOR_NEWSLETTER = gql`
  mutation SignUpForNewsletter($email: String!) {
    signupForNewsletter(email: $email)
  }
`;

const useSignupForNewsletter = () => {
  return useMutation(SIGNUP_FOR_NEWSLETTER);
};

export default useSignupForNewsletter;
