export const HOME_ROUTE = "/";

export const CREATE_AD_ROUTE = "/create-ad/wizard?page=1";
export const CREATE_AD_PAGE1 = CREATE_AD_ROUTE;
export const EDIT_AD_ROUTE = "/edit-ad";

export const ACCOUNT_ROUTE = "/account";
export const ACCOUNT_FAVORITES_ROUTE = `${ACCOUNT_ROUTE}/favorites`;
export const ACCOUNT_LOGIN_ROUTE = `${ACCOUNT_ROUTE}/login`;
export const ACCOUNT_SIGNUP_ROUTE = `${ACCOUNT_ROUTE}/signup`;
export const ACCOUNT_SEARCHES_ROUTE = `${ACCOUNT_ROUTE}/searches`;
export const ACCOUNT_ADS_ROUTE = `${ACCOUNT_ROUTE}/ads`;
// export const ACCOUNT_PAYMENT_REVIEW_ROUTE = `${ACCOUNT_ROUTE}/payment-review`;
export const ACCOUNT_SETTINGS_ROUTE = `${ACCOUNT_ROUTE}/settings`;
export const ACCOUNT_VACATION_MODE_ROUTE = `${ACCOUNT_ROUTE}/vacation`;
export const ACCOUNT_SECURITY_ROUTE = `${ACCOUNT_ROUTE}/security`;
export const ACCOUNT_REFER_FRIEND_ROUTE = `${ACCOUNT_ROUTE}/refer-friend`;
export const ACCOUNT_REVIEWS_ROUTE = `${ACCOUNT_ROUTE}/reviews`;
export const ACCOUNT_PROMOTIONS_ROUTE = `${ACCOUNT_ROUTE}/promotions`;
export const ACCOUNT_SALES_ROUTE = `${ACCOUNT_ROUTE}/sales`;
export const ACCOUNT_PURCHASES_ROUTE = `${ACCOUNT_ROUTE}/purchases`;

export const NEW_IN_ROUTE = "/new";
export const FOR_YOU_ROUTE = "/recommended";

export const HIW_ROUTE = "/how-it-works";
export const HIW_SELLING_ROUTE = `${HIW_ROUTE}/selling`;
export const HIW_BUYING_ROUTE = `${HIW_ROUTE}/buying`;

export const ART_ON_WHOPPAH_ROUTE = "/art-on-whoppah";

export const SHIPPING_ROUTE = "/shipping";

export const SHOWROOM_ROUTE = "/showroom";
export const VISIT_SHOWROOM_ROUTE = `${SHOWROOM_ROUTE}/visit-showroom`;

export const SEARCH_ROUTE = "/search";

export const USER_ADS_ROUTE = "/user-ads";
export const PRODUCTS_ROUTE = "/products";

export const CHAT_ROUTE = "/chat";

export const STYLE_ROUTE = "/style";
export const BRANDS_ROUTE = "/brands";
export const DESIGNERS_ROUTE = "/designers";
export const BRANDS_ALL_ROUTE = `${BRANDS_ROUTE}/all-brands`;
export const DESIGNERS_ALL_ROUTE = `${DESIGNERS_ROUTE}/all-designers`;
export const FEEDBACK_ROUTE = "/feedback";
export const DECORATION_ROUTE = "/decoration";
export const ART_ROUTE = "/art";
export const COFFEE_TABLE_BOOKS_ROUTE = "/decoration/coffee-table-books";
export const LIGHTING_ROUTE = "/lighting";
export const FURNITURE_ROUTE = "/furniture";

export const COLLECTIONS_ROUTE = "/collections";
export const CURATION_ROUTE = "/curation";
export const BIDDING_ROUTE = "/bidding";
export const PAYMENT_ROUTE = "/payment";
export const ABOUT_ROUTE = "/about";
export const REVIEWS_ROUTE = "/reviews";
export const CONTACT_US_ROUTE = "/contact-us";

export const PHOTO_TIPS_ROUTE = "/photo-tips";
export const BUSINESS_TO_BUSINESS_ROUTE = "/business-to-business";

export const BRENGER_ROUTE = "/brenger";
export const BRENGER_SCHEDULER_ROUTE = `${BRENGER_ROUTE}/scheduler`;
export const BRENGER_APPROVER_ROUTE = `${BRENGER_ROUTE}/approver`;

export const TERMS_ROUTE = "/terms";
export const PRIVACY_ROUTE = "/privacy-policy";
export const IMPRESSUM_ROUTE = "/impressum";
export const BLOG_ROUTE = "/blog";
export const SIMILAR_ROUTE = "/similar";
