import { HeaderAccountMenuJsonItem } from "@/common/contracts/header.contracts";
import { analyticsService } from "@/common/services/analytics/analytics.service";
import HeaderMenuActions from "@whoppah/components/Header/HeaderMenuActions";
import HeaderIcons from "@whoppah/components/Header/HeaderMenuIcons";
import Link from "@whoppah/next/link";
import { cn } from "@/common/utils/css.utils";
import { useTranslation } from "next-i18next";
import React from "react";

type HeaderAccountMenuItemProps = {
  item: HeaderAccountMenuJsonItem;
  onItemClick: () => void;
  onCloseMenu: () => void;
};

const HeaderAccountMenuItem = ({
  item,
  onItemClick,
  onCloseMenu,
}: HeaderAccountMenuItemProps) => {
  const { t } = useTranslation();

  const handleClick = () => {
    analyticsService.trackNavigationMenu(t(item.label));
    onItemClick();
  };

  return (
    <li
      className={cn(
        "flex cursor-pointer items-center space-x-2 whitespace-nowrap font-body text-sm hover:text-attention-500",
        item?.highlight
          ? "text-attention-500"
          : "text-[#282828] md:text-[#585858]"
      )}
      onClick={handleClick}
    >
      {item.action ? (
        <HeaderMenuActions
          action={item.action}
          leftIcon={<HeaderIcons icon={item.leftIcon} className="h-6 w-6" />}
          label={t(item.label)}
          rightIcon={<HeaderIcons icon={item.rightIcon} className="h-6 w-6" />}
          href={item.href}
          onClick={onCloseMenu}
        />
      ) : (
        <>
          <HeaderIcons icon={item.leftIcon} className="h-6 w-6" />
          {item.href ? (
            <Link href={item.href} prefetch={false} passHref>
              <a>{t(item.label)}</a>
            </Link>
          ) : (
            <span>{t(item.label)}</span>
          )}
          <HeaderIcons icon={item.rightIcon} className="h-6 w-6" />
        </>
      )}
    </li>
  );
};

export default HeaderAccountMenuItem;
