import { MutationHookOptions, useMutation } from "@apollo/client";
import updateMeGql from "@whoppah/apollo/mutation/updateMe.graphql";
import {
  UpdateMeMutation,
  UpdateMeMutationVariables,
} from "@whoppah/apollo/schema";

const useUpdateMe = (
  options?: MutationHookOptions<UpdateMeMutation, UpdateMeMutationVariables>
) => {
  return useMutation(updateMeGql, options);
};

export default useUpdateMe;
