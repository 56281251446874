import { useAuth } from "@/auth";
import { analyticsService } from "@/common/services/analytics/analytics.service";
import { CHAT_ROUTE } from "@/common/static/routes.static";
import useMe from "@/hooks/useMe";
import { useCallback, useEffect, useRef } from "react";
import { useRouter } from "next/router";

/**
 * Hook that tracks page views and triggers `pageview_custom` event when needed.
 */
const useTrackPageView = () => {
  const lastUrlRef = useRef("");

  const router = useRouter();

  const { isLoggedIn } = useAuth();
  const { me, loading: meLoading } = useMe();

  const trigger = useCallback(() => {
    // TODO: See with other members if we need to "normalize" this URL in some way.
    const url = window.location.href;

    const isChat =
      lastUrlRef.current.includes(CHAT_ROUTE) && url.includes(CHAT_ROUTE);

    if (lastUrlRef.current === url || meLoading || isChat) {
      return;
    }

    /** Trigger `pageview_custom` event. */
    analyticsService.trackPageView(
      window.location.href,
      window.document.title,
      router.locale,
      me ?? undefined,
      isLoggedIn ? "logged_in" : "logged_out"
    );

    lastUrlRef.current = url;
  }, [isLoggedIn, me, meLoading, router.locale]);

  useEffect(() => {
    /** Trigger `pageview_custom` event on initial page render. */
    trigger();
  }, [trigger]);

  useEffect(() => {
    const handleRouteChangeComplete = () => {
      trigger();
    };

    router.events.on("routeChangeComplete", handleRouteChangeComplete);

    // Unmounted - cleanup.
    return () => {
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
    };
  }, [router.events, trigger]);
};

export default useTrackPageView;
