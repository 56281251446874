import { createStore } from "zustand/vanilla";
import { useStoreWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";

import cookies from "js-cookie";
import { useEffect } from "react";
import { terminateSubscriptions } from "../../lib/apollo/subscriptions";
import { useApolloClient } from "@apollo/client";
import { createDeviceId } from "@/auth/utils";

type AuthStore = {
  isReady: boolean;
  setReady: () => void;
  token: string | null;
  setToken: (token: string) => void;
  deviceId: string;
  setDeviceId: (deviceId: string) => void;
  userId: string | null;
  setUserId: (userId: string) => void;
  reset: () => void;
};

const COOKIE_OPTIONS = {
  path: "/", // The cookie is available in all pages.
  expires: 365, // 365 days (1 year)
  sameSite: "lax", // lax prevents the cookie from being sent on cross-site requests
} as const;

/**
 * Generate an anonymous User ID if it doesn't exist yet.
 * This is used to identify anonymous users. It should be passed in header to the API.
 */
if (!cookies.get("device_id")) {
  const deviceId = createDeviceId();
  cookies.set("device_id", deviceId, COOKIE_OPTIONS);
}

/**
 * Remove legacy anonymous User ID from cookies.
 */
if (cookies.get("user_id")?.startsWith("anonymous")) {
  cookies.remove("user_id");
}

/**
 * Get the initial token, userId and deviceId from the cookies.
 */
const initialToken = cookies.get("token") ?? null;
const initialUserId = cookies.get("user_id") ?? null;
const initialDeviceId = cookies.get("device_id") ?? "";

/**
 * Zustand store for authentication.
 */
export const authStore = createStore<AuthStore>(set => ({
  /**
   * Whether the authentication store is ready.
   */
  isReady: false,
  /**
   * Set the authentication store to ready.
   */
  setReady: () => {
    set({ isReady: true });
  },
  /**
   * The authentication token.
   */
  token: initialToken,

  /**
   * Set the authentication token.
   * @param token - The authentication token.
   */
  setToken: (token: string) => {
    set({ token });
  },

  /**
   * Remove the authentication token and the userId.
   */
  reset: () => {
    set({ token: null, userId: null });
  },

  /**
   * The User ID of the authenticated user.
   */
  userId: initialUserId,

  /**
   * Set the User ID of the authenticated user.
   * @param userId - The User ID.
   */
  setUserId: (userId: string) => {
    set({ userId });
  },

  /**
   * The Device ID of the authenticated or anonymous user.
   */
  deviceId: initialDeviceId,

  /**
   * Set the Device ID of the authenticated or anonymous user.
   * @param deviceId - The Device ID.
   */
  setDeviceId: (deviceId: string) => {
    set({ deviceId });
  },
}));

/**
 * Hook to use the auth store. This is a wrapper around Zustand's `useStore` hook.
 * @returns The auth store.
 */
export const useAuthStore = () => {
  const client = useApolloClient();

  useEffect(() => {
    /**
     * Subscribe to the auth store.
     * @param token - The token.
     * @returns The unsubscribe function.
     */
    const unsubscribe = authStore.subscribe((state, prevState) => {
      /**
       * Update the Device ID in the cookie if it has changed.
       */
      if (state.deviceId !== prevState.deviceId) {
        // Update the Device ID in the cookie.
        if (state.deviceId) {
          cookies.set("device_id", state.deviceId, COOKIE_OPTIONS);
        } else {
          cookies.remove("device_id");
        }
      }

      /**
       * Update the User ID in the cookie if it has changed.
       */
      if (state.userId !== prevState.userId) {
        // Update the User ID in the cookie.
        if (state.userId) {
          cookies.set("user_id", state.userId, COOKIE_OPTIONS);
        } else {
          cookies.remove("user_id");
        }
      }

      /**
       * Update the token in the cookie if it has changed.
       * Reset the store if the token is removed.
       * Terminate all subscriptions when the token changes.
       */
      if (state.token !== prevState.token) {
        // Update the token in the cookie.
        if (state.token) {
          cookies.set("token", state.token, COOKIE_OPTIONS);
        } else {
          cookies.remove("token");
        }

        // Reset the store if the token is removed.
        if (!state.token) {
          client.resetStore();
        }

        // Terminate all subscriptions when the token changes.
        terminateSubscriptions();
      }
    });

    /**
     * Unsubscribe from the store when the component unmounts.
     */
    return unsubscribe;
  }, [client]);

  // Return the store with a shallow equality function.
  // This prevents unnecessary re-renders.
  // See:
  // - https://github.com/pmndrs/zustand/discussions/1937#discussioncomment-6610415
  return useStoreWithEqualityFn(authStore, state => state, shallow);
};
