import { RetryLink } from "@apollo/client/link/retry";

/**
 * Retries the request if it fails.
 *
 * If the error is a timeout error, then we want to retry the request.
 *
 * @returns {ApolloLink} The retry link.
 */
export const createRetryLink = () => {
  return new RetryLink({
    attempts: {
      max: 3, // Usually we want to retry twice before giving up, so we set this to 3.
      retryIf: (error, _operation) => {
        const errorString = error.toString();

        // If the error is a timeout or a 502 bad gateway error, then we want to retry the request.
        return (
          errorString.includes("Timeout exceeded") ||
          errorString.includes("502 Bad Gateway")
        );
      },
    },
  });
};
