import { createClient, Client } from "graphql-ws";
import { authStore } from "@/auth";

// Static
import { GRAPHQL_SUBSCRIPTIONS_ENDPOINT } from "config";

let subscriptionsClient: Client | undefined = undefined;

/**
 * Creates the subscriptions client if it doesn't exist.
 * @returns The subscriptions client.
 */
export const initializeSubscriptionsClient = (): Client => {
  if (!subscriptionsClient) {
    subscriptionsClient = createClient({
      url: GRAPHQL_SUBSCRIPTIONS_ENDPOINT,
      /**
       * The connection params callback will be triggered every time a subscription should be established.
       * @returns The connection params.
       */
      connectionParams: () => {
        const token = authStore.getState().token;
        return {
          Authorization: token ? `Bearer ${token}` : undefined,
        };
      },
      shouldRetry: () => true,
    });
  }

  return subscriptionsClient;
};

/**
 * Terminates the WebSocket abruptly and immediately.
 * Use case: Terminate the subscriptions once the user logs out.
 */
export const terminateSubscriptions = () => {
  subscriptionsClient?.terminate();
};
