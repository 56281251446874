import { cn } from "@/common/utils/css.utils";
import Image from "@/components/common/Image";
import { MegaMenuItemData } from "@/common/contracts/mega-menu.contracts";

type MegaMenuHeroImageProps = {
  singleImageItem?: MegaMenuItemData;
  multipleImageItems?: {
    items: MegaMenuItemData[];
    itemIndex: number;
  };
};

export const MegaMenuHeroImage = ({
  singleImageItem,
  multipleImageItems,
}: MegaMenuHeroImageProps) => {
  if (singleImageItem?.image) {
    return (
      <div className="hidden h-[24rem] w-[32rem] 2xl:block">
        <div className="aspect-h-3 aspect-w-4 relative">
          <Image
            src={singleImageItem.image}
            layout="fill"
            objectFit="cover"
            alt="Menu hero"
          />
        </div>
      </div>
    );
  }

  if (multipleImageItems?.items) {
    return (
      <>
        {multipleImageItems.items.map((item, index) => {
          if (!item.image) {
            return null;
          }

          return (
            <div
              key={item.id}
              className={cn(
                "h-[24rem] w-[32rem]",
                index !== multipleImageItems.itemIndex && "hidden"
              )}
            >
              <div className="aspect-h-3 aspect-w-4 relative">
                <Image
                  src={item.image}
                  layout="fill"
                  objectFit="cover"
                  alt="Menu hero"
                />
              </div>
            </div>
          );
        })}
      </>
    );
  }

  return null;
};
