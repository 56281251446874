// Contracts
import { IconProps } from "@/common/contracts/icon.contracts";

export const UmbrellaBeachOutlineIcon = (props: IconProps) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.0001 19.9726L19.5701 21.4026L13.1301 14.9626L14.5601 13.5326L21.0001 19.9726ZM13.1201 3.40259C10.5401 3.40259 7.96005 4.40259 6.00005 6.35259L5.97005 6.36259C2.00005 10.3126 2.00005 16.7226 5.97005 20.6726L20.2701 6.36259C18.3001 4.40259 15.7101 3.40259 13.1201 3.40259ZM6.14005 17.6726C5.40005 16.4326 5.00005 15.0126 5.00005 13.5226C5.00005 12.5926 5.16005 11.7026 5.46005 10.8526C5.65005 12.7626 6.35005 14.6426 7.53005 16.2926L6.14005 17.6726ZM9.00005 14.8326C7.63005 12.7826 7.12005 10.3326 7.60005 8.00259C8.18005 7.90259 8.76005 7.82259 9.35005 7.82259C11.1501 7.82259 12.9001 8.37259 14.4301 9.40259L9.00005 14.8326ZM10.4501 5.86259C11.3001 5.56259 12.1901 5.40259 13.1201 5.40259C14.6101 5.40259 16.0301 5.80259 17.2701 6.54259L15.8801 7.93259C14.2301 6.75259 12.3601 6.05259 10.4501 5.86259Z"
        fill="currentColor"
      />
    </svg>
  );
};
