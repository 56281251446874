import {
  SEARCH_FILTER_AR,
  SEARCH_FILTER_AVAILABILITY,
  SEARCH_FILTER_BIDDING,
  SEARCH_FILTER_BRAND,
  SEARCH_FILTER_CATEGORY,
  SEARCH_FILTER_COLOR,
  SEARCH_FILTER_COUNTRY,
  SEARCH_FILTER_DEPTH,
  SEARCH_FILTER_HEIGHT,
  SEARCH_FILTER_MATERIAL,
  SEARCH_FILTER_PRICE,
  SEARCH_FILTER_QUALITY,
  SEARCH_FILTER_STYLE,
  // SEARCH_FILTER_SHAPE,
  SEARCH_FILTER_WIDTH,
  SEARCH_FILTER_PREMIUM,
  SEARCH_FILTER_DISCOUNT_PERCENTAGE,
  SEARCH_FILTER_SHIPPING_METHOD,
  SEARCH_FILTER_NUMBER_OF_ITEMS,
  SEARCH_FILTER_ART_SIZE,
  SEARCH_FILTER_ART_ORIENTATION,
  SEARCH_FILTER_CONDITION,
  SEARCH_FILTER_DESIGNER,
} from "components/search/SearchConstants";

/**
 * Returns array of values if any value is non-nil, null otherwise.
 *
 * @param {string | string[]} [value] Input value.
 * @return {string[] | null}
 */
const getValues = value => {
  if (value) {
    if (Array.isArray(value)) {
      const nonNil = value.filter(v => v);
      return nonNil.length > 0 ? nonNil : null;
    }
    return [value];
  }
  return null;
};

const initFilters = obj => {
  if (!obj.filters) {
    obj.filters = [];
  }

  return obj.filters;
};

export const PAGE_SIZE = 48;

const SearchUtils = {
  getVariablesObject: query => {
    const getPageSize = () => {
      if (pageSize) {
        const size = parseInt(pageSize);
        if (!Number.isNaN(size)) {
          return size;
        }
      }

      return PAGE_SIZE;
    };

    const addMultiValueFilter = (key, values) => {
      if (values && values.length > 0) {
        const filters = initFilters(variables);
        filters.push({ key, value: values.join(",") });
      }
    };

    const addSingleValueFilter = (key, value) => {
      if (value) {
        const filters = initFilters(variables);
        filters.push({ key, value });
      }
    };

    const searchQuery = query.query ?? null;

    // Take the first one, ignore the rest.
    const sort = getValues(query["sort"])?.[0];
    const order = getValues(query["order"])?.[0];

    // Take the first one, ignore the rest.
    const minPrice = getValues(query["min-price"])?.[0];
    const maxPrice = getValues(query["max-price"])?.[0];

    // Take the first one, ignore the rest.
    const pageSize = getValues(query["page-size"])?.[0];

    const categories = getValues(query.category) ?? [];
    const quality = getValues(query.quality) ?? [];
    const brands = getValues(query.brand) ?? [];
    const styles = getValues(query.style) ?? [];
    const materials = getValues(query.material);
    const colors = getValues(query.color);
    const designers = getValues(query.designer);
    // const shapes = getValues(query.shape);
    const countries = getValues(query.country);
    const artSizes = getValues(query.size);
    const artOrientations = getValues(query.orientation);

    // Take the first one, ignore the rest.
    const minDepth = getValues(query["min-depth"])?.[0];
    const maxDepth = getValues(query["max-depth"])?.[0];
    const minHeight = getValues(query["min-height"])?.[0];
    const maxHeight = getValues(query["max-height"])?.[0];
    const minWidth = getValues(query["min-width"])?.[0];
    const maxWidth = getValues(query["max-width"])?.[0];

    const discount = getValues(query.discount)?.[0];
    const shippingMethod = getValues(query.shipping)?.[0];
    const numberOfItems = getValues(query.items)?.[0];

    // Take the first one, ignore the rest.
    const bidding = getValues(query.bidding)?.[0];
    const ar = getValues(query.ar)?.[0];
    const premium = getValues(query.premium)?.[0];
    const condition = getValues(query.condition)?.[0];

    // Take the first one, ignore the rest.
    const availability = getValues(query.availability)?.[0];

    const variables = {};

    if (searchQuery) {
      variables.query = searchQuery;
    }

    if (sort) {
      variables.sort = sort.toLocaleUpperCase();
    }

    if (order) {
      variables.order = order.toLocaleUpperCase();
    }

    variables.pageSize = getPageSize();

    if ((minPrice && parseInt(minPrice)) || (maxPrice && parseInt(maxPrice))) {
      addSingleValueFilter(
        SEARCH_FILTER_PRICE,
        `${minPrice ?? ""},${maxPrice ?? ""}`
      );
    }

    addMultiValueFilter(SEARCH_FILTER_CATEGORY, categories);
    addMultiValueFilter(SEARCH_FILTER_QUALITY, quality);
    addMultiValueFilter(SEARCH_FILTER_BRAND, brands);
    addMultiValueFilter(SEARCH_FILTER_STYLE, styles);
    addMultiValueFilter(SEARCH_FILTER_MATERIAL, materials);
    addMultiValueFilter(SEARCH_FILTER_COLOR, colors);
    addMultiValueFilter(SEARCH_FILTER_DESIGNER, designers);
    // addMultiValueFilter(SEARCH_FILTER_SHAPE, shapes);
    addMultiValueFilter(SEARCH_FILTER_COUNTRY, countries);
    addMultiValueFilter(SEARCH_FILTER_ART_SIZE, artSizes);
    addMultiValueFilter(SEARCH_FILTER_ART_ORIENTATION, artOrientations);

    if ((minDepth && parseInt(minDepth)) || (maxDepth && parseInt(maxDepth))) {
      addSingleValueFilter(
        SEARCH_FILTER_DEPTH,
        `${minDepth ?? ""},${maxDepth ?? ""}`
      );
    }

    if (
      (minHeight && parseInt(minHeight)) ||
      (maxHeight && parseInt(maxHeight))
    ) {
      addSingleValueFilter(
        SEARCH_FILTER_HEIGHT,
        `${minHeight ?? ""},${maxHeight ?? ""}`
      );
    }

    if ((minWidth && parseInt(minWidth)) || (maxWidth && parseInt(maxWidth))) {
      addSingleValueFilter(
        SEARCH_FILTER_WIDTH,
        `${minWidth ?? ""},${maxWidth ?? ""}`
      );
    }

    if (bidding) {
      addSingleValueFilter(SEARCH_FILTER_BIDDING, "true");
    }

    if (ar) {
      addSingleValueFilter(SEARCH_FILTER_AR, "true");
    }

    if (premium) {
      addSingleValueFilter(SEARCH_FILTER_PREMIUM, "true");
    }

    if (availability) {
      addSingleValueFilter(SEARCH_FILTER_AVAILABILITY, "true");
    }

    if (discount) {
      addSingleValueFilter(
        SEARCH_FILTER_DISCOUNT_PERCENTAGE,
        `${discount},100`
      );
    }

    if (shippingMethod) {
      addSingleValueFilter(SEARCH_FILTER_SHIPPING_METHOD, shippingMethod);
    }

    if (numberOfItems) {
      addSingleValueFilter(
        SEARCH_FILTER_NUMBER_OF_ITEMS,
        `${numberOfItems},${numberOfItems}`
      );
    }

    if (condition) {
      addSingleValueFilter(SEARCH_FILTER_CONDITION, condition);
    }

    // Facets
    variables.facets = [
      "CATEGORY",
      "BRAND",
      "STYLE",
      "MATERIAL",
      "COLOR",
      "DESIGNER",
      // "SHAPE",
      "PREMIUM",
      "ART_SIZE",
      "ART_ORIENTATION",
      "COUNTRY",
      "QUALITY",
    ];

    // Pagination
    if (query.page && parseInt(query.page)) {
      const page = parseInt(query.page);
      variables.page = page > 0 ? page : 1;
    }

    return variables;
  },
};

export default SearchUtils;

export const DEFAULT_FILTER_DATA = {
  sort: null,
  order: null,
  minPrice: null,
  maxPrice: null,
  categories: [],
  quality: [],
  brands: [],
  styles: [],
  materials: [],
  colors: [],
  designers: [],
  // shapes: [],
  countries: [],
  sizes: [],
  orientations: [],
  minDepth: null,
  maxDepth: null,
  minHeight: null,
  maxHeight: null,
  minWidth: null,
  maxWidth: null,
  bidding: null,
  availability: null,
  ar: null,
  premium: null,
  discount: null,
  shipping: null,
  items: null,
  condition: null,
};

export const getSearchFilterData = filters => {
  return (
    filters?.reduce(
      (filterData, filter) => {
        if (filter.key === SEARCH_FILTER_PRICE && filter.value) {
          const prices = filter.value.split(",");
          filterData.minPrice = prices[0] && parseFloat(prices[0]);
          filterData.maxPrice = prices[1] && parseFloat(prices[1]);
        }

        if (filter.key === SEARCH_FILTER_CATEGORY && filter.value) {
          filterData.categories = filter.value.split(",");
        }

        if (filter.key === SEARCH_FILTER_QUALITY && filter.value) {
          filterData.quality = filter.value
            .split(",")
            .map(v => v.toLocaleLowerCase());
        }

        if (filter.key === SEARCH_FILTER_BRAND && filter.value) {
          filterData.brands = filter.value.split(",");
        }

        if (filter.key === SEARCH_FILTER_STYLE && filter.value) {
          filterData.styles = filter.value.split(",");
        }

        if (filter.key === SEARCH_FILTER_MATERIAL && filter.value) {
          filterData.materials = filter.value.split(",");
        }

        if (filter.key === SEARCH_FILTER_COLOR && filter.value) {
          filterData.colors = filter.value.split(",");
        }

        if (filter.key === SEARCH_FILTER_DESIGNER && filter.value) {
          filterData.designers = filter.value.split(",");
        }

        // if (filter.key === SEARCH_FILTER_SHAPE && filter.value) {
        //   filterData.shapes = filter.value.split(",");
        // }

        if (filter.key === SEARCH_FILTER_COUNTRY && filter.value) {
          filterData.countries = filter.value.split(",");
        }

        if (filter.key === SEARCH_FILTER_ART_SIZE && filter.value) {
          filterData.sizes = filter.value.split(",");
        }

        if (filter.key === SEARCH_FILTER_ART_ORIENTATION && filter.value) {
          filterData.orientations = filter.value.split(",");
        }

        if (filter.key === SEARCH_FILTER_DEPTH && filter.value) {
          const depth = filter.value.split(",");
          filterData.minDepth = depth[0] && parseFloat(depth[0]);
          filterData.maxDepth = depth[1] && parseFloat(depth[1]);
        }

        if (filter.key === SEARCH_FILTER_HEIGHT && filter.value) {
          const height = filter.value.split(",");
          filterData.minHeight = height[0] && parseFloat(height[0]);
          filterData.maxHeight = height[1] && parseFloat(height[1]);
        }

        if (filter.key === SEARCH_FILTER_WIDTH) {
          const width = filter.value.split(",");
          filterData.minWidth = width[0] && parseFloat(width[0]);
          filterData.maxWidth = width[1] && parseFloat(width[1]);
        }

        if (filter.key === SEARCH_FILTER_BIDDING && filter.value === "true") {
          filterData.bidding = filter.value;
        }

        if (
          filter.key === SEARCH_FILTER_AVAILABILITY &&
          filter.value === "true"
        ) {
          filterData.availability = filter.value;
        }

        if (filter.key === SEARCH_FILTER_AR && filter.value === "true") {
          filterData.ar = filter.value;
        }

        if (filter.key === SEARCH_FILTER_PREMIUM && filter.value === "true") {
          filterData.premium = filter.value;
        }

        if (filter.key === SEARCH_FILTER_DISCOUNT_PERCENTAGE && filter.value) {
          const discount = filter.value.split(",");
          filterData.discount = discount[0];
        }

        if (filter.key === SEARCH_FILTER_SHIPPING_METHOD && filter.value) {
          filterData.shipping = filter.value;
        }

        if (filter.key === SEARCH_FILTER_NUMBER_OF_ITEMS && filter.value) {
          const items = filter.value.split(",");
          filterData.items = items[0];
        }

        if (filter.key === SEARCH_FILTER_CONDITION && filter.value) {
          filterData.condition = filter.value;
        }

        return filterData;
      },
      { ...DEFAULT_FILTER_DATA }
    ) ?? { ...DEFAULT_FILTER_DATA }
  );
};
