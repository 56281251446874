import {
  ComponentProps,
  ReactNode,
  Ref,
  forwardRef,
  isValidElement,
} from "react";
import { cva, VariantProps } from "class-variance-authority";
import { ElementProps } from "@/common/contracts/input.contracts";
import { cn } from "@/common/utils/css.utils";

export type InputElementProps = ElementProps &
  Pick<ComponentProps<"input">, "id">;

export type LabelElementProps = ElementProps &
  Pick<ComponentProps<"label">, "htmlFor">;

type InputBaseProps = {
  ref?: Ref<HTMLInputElement>;
  inputRef?: Ref<HTMLInputElement>;
  leftElement?: ReactNode | ((props: ElementProps) => ReactNode);
  placeholder?: ReactNode | ((props: LabelElementProps) => ReactNode);
  rightElement?: ReactNode | ((props: ElementProps) => ReactNode);
  valid?: boolean;
  inputClassName?: string;
} & VariantProps<typeof InputWrapperStyle> &
  Omit<ComponentProps<"input">, "ref" | "color" | "placeholder"> &
  ElementProps;

const InputWrapperStyle = cva(
  "relative flex min-w-0 space-x-2 rounded-[0.25rem] border bg-white px-4 py-2.5 font-body focus-within:ring-2 focus-within:ring-curious-200",
  {
    variants: {
      color: {
        brand: "",
        curious: "",
      },
      valid: {
        true: "",
        false: "",
      },
      disabled: {
        true: "cursor-not-allowed border-grey-400 bg-grey-50",
        false: "",
      },
    },
    compoundVariants: [
      /* Valid, not disabled */
      {
        valid: true,
        disabled: false,
        className: "border-grey-500",
      },
      /* Valid, not disabled, brand */
      {
        valid: true,
        disabled: false,
        color: "brand",
        className: "focus-within:border-brand-500 hover:border-brand-500",
      },
      /* Valid, not disabled, curious */
      {
        valid: true,
        disabled: false,
        color: "curious",
        className: "focus-within:border-curious-500 hover:border-curious-500",
      },
      /* Not valid, not disabled */
      {
        valid: false,
        disabled: false,
        className: "border-attention-500 focus-within:border-attention-500",
      },
    ],
    defaultVariants: {
      color: "curious",
      valid: true,
      disabled: false,
    },
  }
);

const InputStyle = cva(
  "peer w-full min-w-0 text-base text-black placeholder:text-grey-500 focus:outline-none disabled:cursor-not-allowed disabled:bg-grey-50 disabled:text-grey-600 disabled:placeholder:text-grey-300"
);

const InputBase = ({ className, ...props }: InputBaseProps) => {
  const {
    id,
    color,
    leftElement,
    placeholder,
    rightElement,
    valid,
    disabled,
    inputRef,
    inputClassName,
    ...rest
  } = props;

  const placeholderProps = {
    /**
     * If we are passing React component as a placeholder (in most cases {@link InputAnimatedPlaceholder})
     * then we need to make sure to set the placeholder to a single space for animated events to take place.
     */
    placeholder: typeof placeholder === "string" ? placeholder : " ",
  };

  const renderPlaceholder = () => {
    if (typeof placeholder === "function") {
      return placeholder({ valid, disabled, htmlFor: id });
    }

    if (isValidElement(placeholder)) {
      return placeholder;
    }
  };

  return (
    <div
      className={cn(InputWrapperStyle({ color, valid, disabled }), className)}
    >
      {typeof leftElement === "function"
        ? leftElement({ valid, disabled })
        : leftElement}
      <div className="relative flex w-full">
        <input
          {...rest}
          {...placeholderProps}
          id={id}
          ref={inputRef}
          className={cn(InputStyle(), inputClassName)}
          disabled={disabled}
        />
        {renderPlaceholder()}
      </div>
      {typeof rightElement === "function"
        ? rightElement({ valid, disabled })
        : rightElement}
    </div>
  );
};

InputBase.displayName = "Input";

export type InputProps = Omit<InputBaseProps, "inputRef">;

/**
 * Simple input.
 *
 * @todo Move this to `ui/Input` once the usage of previous input is fully removed.
 */
export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => (
  <InputBase {...props} inputRef={ref} />
));
