import { v4 as uuid } from "uuid";
import { isbot } from "isbot";
import { BOT_DEVICE_ID } from "@/auth/constants";

/**
 * Creates a unique device id for all (anonymous and authenticated) users.
 * This is used to identify anonymous users. It should be passed in X-Device-Id
 * header to the Gateway
 *
 * @param {string | undefined} userAgent - The user agent string. If it is a bot, the device id will be "bot".
 * @returns {string} The unique user id.
 */
export const createDeviceId = (userAgent?: string) => {
  let _userAgent = userAgent;
  if (typeof window !== "undefined" && !_userAgent) {
    _userAgent = navigator.userAgent;
  }
  return isbot(_userAgent) ? BOT_DEVICE_ID : uuid();
};
