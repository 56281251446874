import { useGenerateIterableJwt } from "@/Apollo/hooks/useGenerateIterableJwt";
import { useAuth } from "@/auth";
import { ITERABLE_AUTH_TOKEN } from "@/config";
import {
  getInAppMessages,
  HandleLinks,
  DisplayOptions,
  initializeWithConfig,
} from "@iterable/web-sdk";
import { useEffect } from "react";

export const useIterable = () => {
  const { userId } = useAuth();
  const [generateIterableJwt] = useGenerateIterableJwt();

  useEffect(() => {
    // If you use userId instead of email, import setUserId instead
    const { setUserID, logout } = initializeWithConfig({
      authToken: ITERABLE_AUTH_TOKEN || "",
      configOptions: {
        isEuIterableService: true,
      },
      generateJWT: async () => {
        const { data } = await generateIterableJwt();
        return data?.generateIterableJwt.token || "";
      },
    });

    const {
      request,
      // pauseMessageStream,
      // resumeMessageStream,
      // triggerDisplayMessages,
    } = getInAppMessages(
      {
        // Here, configure the SDK. For more information, check out the
        // web SDK's GitHub repository: https://github.com/iterable/iterable-web-sdk
        count: 20,
        displayInterval: 1000,
        onOpenScreenReaderMessage: "...",
        onOpenNodeToTakeFocus: "input",
        packageName: "whoppah",
        rightOffset: "20px",
        topOffset: "20px",
        bottomOffset: "20px",
        handleLinks: HandleLinks.ExternalNewTab,
        closeButton: {
          color: "black",
          size: "16px",
          topOffset: "20px",
        },
      },
      { display: DisplayOptions.Immediate }
    );

    if (!userId) {
      return logout();
    }

    // Set the email address, and make the request to get the in-app messages
    // that are available for that user. Display them automatically.
    setUserID(userId).then(() => {
      request().then().catch();
    });
  }, [generateIterableJwt, userId]);
};
