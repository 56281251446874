import { authStore } from "@/auth";
import {
  AnalyticsMe,
  AnalyticsProduct,
  AnalyticsProvider,
} from "@/common/contracts/analytics.contracts";
import { getSearchMeta } from "@/common/services/analytics/utils/algolia.utils";
import { ALGOLIA_API_KEY, ALGOLIA_APP_ID } from "@/config";
import AlgoliaSearchInsights, { InsightsMethodMap } from "search-insights";

let algoliaInitialized = false;

const algoliaEvent = (
  key: keyof InsightsMethodMap,
  params: Record<string, unknown>
) => {
  if (!ALGOLIA_APP_ID || !ALGOLIA_API_KEY) {
    return;
  }

  const { userId, deviceId } = authStore.getState();

  if (!algoliaInitialized) {
    AlgoliaSearchInsights("init", {
      appId: ALGOLIA_APP_ID,
      apiKey: ALGOLIA_API_KEY,
      userToken: deviceId ?? undefined,
      authenticatedUserToken: userId ?? undefined,
    });

    algoliaInitialized = true;
  }

  const options = {
    ...getSearchMeta(),
    ...params,
    userToken: deviceId,
    authenticatedUserToken: userId,
  };

  AlgoliaSearchInsights(key, options);
};

/**
 * Algolia's implementation of event tracking provider.
 */
export const algoliaAnalyticsProvider: AnalyticsProvider = {
  trackAddToCart: (product: AnalyticsProduct) => {
    algoliaEvent("convertedObjectIDs", {
      eventName: "Product Added to Cart",
      objectIDs: [product.id],
    });
  },
  trackAddToCartChat: () => {
    algoliaEvent("convertedObjectIDs", {
      eventName: "Product Added to Cart from Chat",
    });
  },
  trackLogin: (me: AnalyticsMe) => {
    AlgoliaSearchInsights("setUserToken", me.id);
  },
  trackSelectItem: (
    position: number,
    listId: string,
    listName: string,
    product: AnalyticsProduct
  ) => {
    algoliaEvent("clickedObjectIDs", {
      eventName: "Product Clicked",
      objectIDs: [product.id],
      positions: [position],
    });
  },
  trackViewItem: (product: AnalyticsProduct) => {
    algoliaEvent("viewedObjectIDs", {
      eventName: "Product Viewed",
      objectIDs: [product.id],
    });
  },
  identify: (me: AnalyticsMe) => {
    algoliaAnalyticsProvider.trackLogin?.(me);
  },
};
