import ApolloLinkTimeout from "apollo-link-timeout";

const DEFAULT_TIMEOUT = 10000;

/**
 * Sets the timeout for the request.
 *
 * If the request takes longer than the timeout, then the request will be
 * cancelled and an error will be returned.
 *
 * @returns {ApolloLink} The timeout link.
 */
export const createTimeoutLink = () => {
  return new ApolloLinkTimeout(DEFAULT_TIMEOUT);
};
