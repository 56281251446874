import { authStore } from "@/auth";
import { setContext } from "@apollo/client/link/context";

/**
 * The options for the auth link.
 */
export type AuthLinkOptions = {
  token?: string;
  userId?: string;
  userIp?: string;
  deviceId: string;
};

/**
 * Intercepts the HTTP GraphQL request.
 *
 * If the token is present, it will add the Authorization header. This is used
 * for identifying the user on backend.
 *
 * If the user id is present, it will add the X-User-Id header. This is used
 * for identifying the same anonymous user.
 *
 * @param options - The options for the auth link.
 * @param options.token - The token to use. If not provided, it will use the token from the store (cookies).
 * @param options.userId - The user id to use. If not provided, it will use the user id from the store (cookies).
 * @returns {ApolloLink} The auth link.
 */
export const createAuthLink = (options?: AuthLinkOptions) => {
  return setContext((operation, context) => {
    const token = options?.token ?? authStore.getState().token;
    const userId = options?.userId ?? authStore.getState().userId;
    const userIp = options?.userIp; /** We don't keep this one in the store. */
    const deviceId = options?.deviceId ?? authStore.getState().deviceId;

    const headers: Record<string, string> = {};

    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    if (userId) {
      headers["X-User-Id"] = userId;
    }

    if (userIp) {
      headers["X-Forwarded-For"] = userIp;
    }

    if (deviceId) {
      headers["X-Device-Id"] = deviceId;
    }

    return {
      ...context,
      headers: {
        ...context.headers,
        ...headers,
      },
    };
  });
};
