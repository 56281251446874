import { CANONICAL_DOMAIN } from "@/config";
import { ParsedUrlQuery } from "querystring";

/**
 * This function cleans the search params from the URLSearchParams object.
 * It removes all the search params that NextJS adds for some reason to asPath
 * which we don't want.
 *
 * It mutates the URLSearchParams object and returns it.
 *
 * @param {URLSearchParams} searchParams - URLSearchParams object
 * @returns {URLSearchParams} - URLSearchParams object
 */
export const cleanSearchParams = (searchParams: URLSearchParams) => {
  // [...search] pages have 'search' or 'nxtPsearch' as search param in url. This is weird
  // bug/behaviour in nextjs, which won't be fixed anything soon. So we remove it here.
  searchParams.delete("search");
  searchParams.delete("nxtPsearch");

  // Sometimes pages after getting redirected to correct locale has 'nextInternalLocale'
  // as search param in url. So we remove it here.
  searchParams.delete("nextInternalLocale");

  return searchParams;
};

/**
 * This function cleans the search params from the url.
 * It removes all the search params that NextJS adds for some reason to asPath
 * which we don't want.
 *
 * @param {string} url - The url string
 * @returns {string} - The cleaned url string
 */
export const cleanSearchParamsFromUrl = (url: string) => {
  const urlObject = new URL(url, `https://${CANONICAL_DOMAIN}`);

  cleanSearchParams(urlObject.searchParams);

  return `${urlObject.pathname}${urlObject.search}`;
};

/**
 * "Extracts" url parameter and converts it using provided converted function.
 *
 * @param query Routers query object.
 * @param paramName Name of the parameter to "extract".
 * @param converter Converter function.
 */
export const getURLParameter = <T>(
  query: ParsedUrlQuery,
  paramName: string,
  converter: (value: string) => T
) => {
  const maybeValue = query[paramName];
  const value = Array.isArray(maybeValue) ? maybeValue[0] : maybeValue;

  if (!value) {
    return;
  }

  return converter(value);
};
