import { analyticsService } from "@/common/services/analytics/analytics.service";
import { cn } from "@/common/utils/css.utils";
import { Flavor } from "@/ui/typography/Flavor";
import {
  NavigationMenuItem,
  NavigationMenuLink,
} from "@radix-ui/react-navigation-menu";
import Link from "@whoppah/next/link";
import { MouseEvent } from "react";

type MegaMenuLinkItemProps = {
  href: string;
  label: string;
  selected?: boolean;
  external?: boolean;
  onSelect?: (evt: Event) => void;
  onClick?: (evt: MouseEvent<HTMLAnchorElement>) => void;
};

export const MegaMenuLinkItem = ({
  href,
  label,
  selected,
  external,
  onSelect,
  onClick,
}: MegaMenuLinkItemProps) => {
  const handleClick = (evt: MouseEvent<HTMLAnchorElement>) => {
    onClick?.(evt);

    /** Trigger `navigation_menu` event. */
    analyticsService.trackNavigationMenu(label);
  };

  return (
    <NavigationMenuItem className="flex">
      <Link href={href} passHref={true} prefetch={false}>
        <NavigationMenuLink
          onSelect={onSelect}
          onClick={handleClick}
          className="grid before:invisible before:h-0 before:overflow-hidden before:font-title before:font-bold before:content-[attr(title)] focus:rounded-[0.25rem] focus:outline-none focus-visible:ring-2 focus-visible:ring-curious-200 focus-visible:ring-offset-4"
          title={label}
          target={external ? "_blank" : undefined}
        >
          <Flavor
            className={cn(
              "whitespace-nowrap hover:text-brand-500 hover:underline",
              selected && "font-bold text-brand-500"
            )}
          >
            {label}
          </Flavor>
        </NavigationMenuLink>
      </Link>
    </NavigationMenuItem>
  );
};
