export const SEARCH_ROUTE_NEW = "new";
export const SEARCH_ROUTE_MOST_POPULAR = "most-popular";
export const SEARCH_ROUTE_SALE = "sale";
export const SEARCH_ROUTE_SHOWROOM = "showroom/products";
export const SEARCH_ROUTE = "search";

export const VALID_ROUTES = [
  SEARCH_ROUTE_NEW,
  SEARCH_ROUTE_MOST_POPULAR,
  SEARCH_ROUTE_SALE,
  SEARCH_ROUTE_SHOWROOM,
  SEARCH_ROUTE,
];

export const SEARCH_FILTER_PRICE = "PRICE";
export const SEARCH_FILTER_CATEGORY = "CATEGORY";
export const SEARCH_FILTER_QUALITY = "QUALITY";
export const SEARCH_FILTER_CONDITION = "CONDITION";
export const SEARCH_FILTER_BRAND = "BRAND";
export const SEARCH_FILTER_STYLE = "STYLE";
export const SEARCH_FILTER_MATERIAL = "MATERIAL";
export const SEARCH_FILTER_COLOR = "COLOR";
export const SEARCH_FILTER_DESIGNER = "DESIGNER";
export const SEARCH_FILTER_COUNTRY = "COUNTRY";
export const SEARCH_FILTER_ART_SIZE = "ART_SIZE";
export const SEARCH_FILTER_ART_ORIENTATION = "ART_ORIENTATION";
export const SEARCH_FILTER_DIMENSIONS = "DIMENSIONS";
export const SEARCH_FILTER_DEPTH = "DEPTH";
export const SEARCH_FILTER_HEIGHT = "HEIGHT";
export const SEARCH_FILTER_WIDTH = "WIDTH";
export const SEARCH_FILTER_LABEL = "LABEL";
export const SEARCH_FILTER_SALE = "ON_SALE";
export const SEARCH_FILTER_IN_SHOWROOM = "IN_SHOWROOM";
export const SEARCH_FILTER_SHOWMODEL = "SHOWMODEL";
export const SEARCH_FILTER_BIDDING = "ALLOW_BID";
export const SEARCH_FILTER_AVAILABILITY = "AVAILABILITY";
export const SEARCH_FILTER_AR = "HAS_AR";
export const SEARCH_FILTER_SHAPE = "SHAPE";
export const SEARCH_FILTER_PREMIUM = "PREMIUM";
export const SEARCH_FILTER_DISCOUNT_PERCENTAGE = "DISCOUNT_PERCENTAGE";
export const SEARCH_FILTER_SHOWROOM = "SHOWROOM";
export const SEARCH_FILTER_SHIPPING_METHOD = "SHIPPING_METHOD";
export const SEARCH_FILTER_NUMBER_OF_ITEMS = "NUMBER_OF_ITEMS";
export const SEARCH_SORT_PRICE = SEARCH_FILTER_PRICE;
export const SEARCH_SORT_CREATED = "CREATED";
export const SEARCH_SORT_POPULARITY = "POPULARITY";

export const SEARCH_ORDER_ASC = "ASC";
export const SEARCH_ORDER_DESC = "DESC";
