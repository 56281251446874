import { MegaMenuPanelCards } from "@/components/mega-menu/MegaMenuPanelCards";
import { MegaMenuPanelLinks } from "@/components/mega-menu/MegaMenuPanelLinks";
import { MegaMenuPanelMixed } from "@/components/mega-menu/MegaMenuPanelMixed";
import {
  MegaMenuPanelData,
  MengaMenuSubMenuType,
} from "@/common/contracts/mega-menu.contracts";
import { PropsWithChildren } from "react";
import { FocusScope } from "@radix-ui/react-focus-scope";

type MegaMenuPanelProps = {
  title: string;
  type: MengaMenuSubMenuType;
  data: MegaMenuPanelData;
};

const MegaMenuPanelRenderer = ({ title, type, data }: MegaMenuPanelProps) => {
  switch (type) {
    case "links":
      return <MegaMenuPanelLinks title={title} data={data} />;

    case "cards":
      return <MegaMenuPanelCards title={title} data={data} />;

    case "mixed":
      return <MegaMenuPanelMixed title={title} data={data} />;

    default:
      return null;
  }
};

export const MegaMenuPanel = ({
  title,
  type,
  data,
}: PropsWithChildren<MegaMenuPanelProps>) => {
  return (
    <FocusScope trapped={true} loop={true}>
      <div className="px-6 pb-20 pt-10 lg:px-12 xl:px-24">
        <MegaMenuPanelRenderer title={title} type={type} data={data} />
      </div>
    </FocusScope>
  );
};
