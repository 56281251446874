import { IconProps } from "@/common/contracts/icon.contracts";

export const Search = (props: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3645 14.608H16.1986L20.6753 19.1032C21.1082 19.5359 21.1082 20.2429 20.6753 20.6755C20.2424 21.1082 19.535 21.1082 19.1021 20.6755L14.6148 16.1909V15.3572L14.3298 15.0618C12.8516 16.328 10.835 16.9823 8.69161 16.6235C5.75639 16.1275 3.41244 13.6794 3.05345 10.7248C2.50442 6.26128 6.26319 2.50471 10.7294 3.05342C13.6857 3.41219 16.1352 5.75477 16.6315 8.68827C16.9905 10.8304 16.3359 12.8458 15.0688 14.3231L15.3645 14.608ZM5.11232 9.85955C5.11232 12.487 7.23455 14.608 9.86358 14.608C12.4926 14.608 14.6148 12.487 14.6148 9.85955C14.6148 7.23207 12.4926 5.11108 9.86358 5.11108C7.23455 5.11108 5.11232 7.23207 5.11232 9.85955Z"
        fill="currentColor"
      />
    </svg>
  );
};
