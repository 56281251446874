import { cn } from "@/common/utils/css.utils";
import { Body } from "@/ui/typography/Body";
import Link from "@whoppah/next/link";
import React, { MouseEvent, ReactElement } from "react";

type HeaderSearchItemProps = {
  icon?: ReactElement;
  title: string;
  url: string;
  highlight?: boolean;
  className?: string;
  onMouseEnter?: (evt: MouseEvent<HTMLLIElement>) => void;
  onMouseLeave?: (evt: MouseEvent<HTMLLIElement>) => void;
  onSelect?: (url: string) => void;
};

export const HeaderSearchItem = ({
  icon,
  title,
  url,
  highlight,
  className,
  onMouseEnter,
  onMouseLeave,
  onSelect,
}: HeaderSearchItemProps) => {
  const handleMouseDown = (evt: MouseEvent<HTMLLIElement>) => {
    /** This will prevent popup from closing. */
    evt.preventDefault();
  };

  const handleClick = (evt: MouseEvent<HTMLLIElement>) => {
    /**
     * This will prevent link from navigating. We want to do this ourselves:
     * - First close the popup
     * - Navigate
     *
     * NOTE: Although we are preventing default navigation, using right-click or
     * middle-click still works as expected.
     */
    evt.preventDefault();
    onSelect?.(url);
  };

  return (
    <li
      className={cn("px-3 py-1", highlight && "bg-flavour-50", className)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseDown={handleMouseDown}
      onClick={handleClick}
      onKeyDown={() => {}}
    >
      <Link href={url} passHref>
        <a className="flex items-center space-x-1">
          {icon}
          <Body className="overflow-hidden text-ellipsis text-nowrap">
            {title}
          </Body>
        </a>
      </Link>
    </li>
  );
};
