import { cloneDeep, get, set } from "lodash-es";
import { util, ZodSchema } from "zod";
import noUndefined = util.noUndefined;

/**
 * Checks whether the passed value is nil (either `null` or `undefined`)
 *
 * @param value Value to check
 */
export const isNil = <T>(value?: T | null): value is undefined | null =>
  value === null || value === undefined;

/**
 * Normalizes input GraphQL variables by validating them against validation
 * schema and null-ifies those that are marked as invalid.
 *
 * @param variables Variables to "normalize".
 * @param variablesSchemaFunc Function that returns validation schema.
 * @param defaults Default values to replace the invalid ones.
 */
export const normalizeGraphQLVariables = <T extends Record<string, unknown>>(
  variables: T,
  variablesSchema: ZodSchema<T>,
  defaults?: noUndefined<T>
): T => {
  const validationResult = variablesSchema.safeParse(variables);

  if (!validationResult.success) {
    const variablesCopy = cloneDeep(variables);

    /**
     * Replace the invalid value with the default one (if present), otherwise
     * `null`-ify the value.
     */
    for (const issue of validationResult.error.issues) {
      const def = get(defaults, issue.path);
      set(variablesCopy, issue.path, def ?? null);
    }

    return variablesCopy;
  }

  return variables;
};

/**
 * Returns a pseudorandom number between 0 and 1, just like {@link Math.random},
 * but with less of a security risk.
 */
export const getRandomNumber = () => {
  const array = new Uint8Array(1);
  const randomValue = crypto.getRandomValues(array)[0];

  if (!randomValue) {
    throw Error("Unable to generate pseudorandom seed value.");
  }

  return randomValue / Math.pow(2, 8);
};

/**
 * Returns the value of the enum key.
 */
export const getEnumValue = <T extends Record<string, unknown>>(
  enumObj: T,
  key?: keyof T
) => {
  if (!key) {
    return;
  }

  return enumObj[key];
};

/**
 * Masks phone number.
 *
 * @param phone Phone to mask.
 */
export const maskPhoneNumber = (phone?: string | null) => {
  if (!phone) {
    return "";
  }

  const firstSlice = phone.startsWith("+") ? 6 : 3;
  const firstDigits = phone.slice(0, firstSlice);
  const last2Digits = phone.slice(-2);

  return firstDigits.padEnd(phone.length - 2, "*").concat(last2Digits);
};
