import { analyticsService } from "@/common/services/analytics/analytics.service";
import {
  MegaMenuItemData,
  MengaMenuSubMenuType,
} from "@/common/contracts/mega-menu.contracts";
import { MegaMenuMobileActionDelegator } from "@/components/mega-menu/mobile/MegaMenuMobileActionDelegator";
import {
  MegaMenuMobileItem,
  MegaMenuMobileItemIcon,
} from "@/components/mega-menu/mobile/MegaMenuMobileItem";
import { MegaMenuMobileSeparatorItem } from "@/components/mega-menu/mobile/MegaMenuMobileSeparatorItem";
import { SEPARATOR_TAG } from "@/common/static/mega-menu.static";
import { Flavor } from "@/ui/typography/Flavor";
import { MegaMenuMobilePanel } from "@/components/mega-menu/mobile/MegaMenuMobilePanel";
import { ChevronRight as ChevronRightIcon } from "@/assets/icons/24/ChevronRight";
import { cn } from "@/common/utils/css.utils";

export const MegaMenuMobileItemList = ({
  items,
  type,
  onItemClick,
}: {
  items?: MegaMenuItemData[];
  type?: MengaMenuSubMenuType | null;
  onItemClick?: () => void;
}) => {
  const handleSubMenuClick = (item: MegaMenuItemData) => () => {
    /** Trigger `navigation_menu` event. */
    analyticsService.trackNavigationMenu(item.label);
  };

  return (
    <ul
      className={cn(
        "mb-4 flex flex-col",
        type?.startsWith("card") ? "space-y-2" : "space-y-4"
      )}
    >
      {items?.map(item => {
        if (item.label === SEPARATOR_TAG) {
          return <MegaMenuMobileSeparatorItem key={item.id} />;
        }

        if (item.href?.includes("@")) {
          return (
            <MegaMenuMobileActionDelegator
              key={item.id}
              item={item}
              onClick={onItemClick}
            />
          );
        }

        const hasSubItems = !!item.items?.length || !!item.submenu;

        if (hasSubItems) {
          return (
            <MegaMenuMobilePanel
              key={item.id}
              trigger={
                <button
                  className="flex items-center space-x-2 text-left"
                  onClick={handleSubMenuClick(item)}
                >
                  <MegaMenuMobileItemIcon item={item} />
                  <Flavor className="flex-1">{item.label}</Flavor>
                  <ChevronRightIcon />
                </button>
              }
              type={item.submenuType}
              title={item.label}
              items={item.submenu?.items ?? item.items}
              hasBack={true}
              onClose={onItemClick}
            />
          );
        }

        return (
          <MegaMenuMobileItem key={item.id} item={item} onClick={onItemClick} />
        );
      })}
    </ul>
  );
};
