import { IconProps } from "@/common/contracts/icon.contracts";

export const Tag = (props: IconProps) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.41 2.60502L21.41 11.605C21.77 11.965 22 12.475 22 13.025C22 13.575 21.78 14.075 21.41 14.435L14.41 21.435C14.05 21.805 13.55 22.025 13 22.025C12.45 22.025 11.95 21.805 11.59 21.445L2.59 12.445C2.22 12.075 2 11.575 2 11.025V4.02502C2 2.92502 2.9 2.02502 4 2.02502H11C11.55 2.02502 12.05 2.24502 12.41 2.60502ZM4 5.52502C4 6.35502 4.67 7.02502 5.5 7.02502C6.33 7.02502 7 6.35502 7 5.52502C7 4.69502 6.33 4.02502 5.5 4.02502C4.67 4.02502 4 4.69502 4 5.52502Z"
        fill="currentColor"
      />
    </svg>
  );
};
