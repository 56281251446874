import { MegaMenuItemCardData } from "@/common/contracts/mega-menu.contracts";
import { Flavor } from "@/ui/typography/Flavor";

export const MegaMenuCardItemText = ({
  item,
}: {
  item: MegaMenuItemCardData;
}) => {
  return <Flavor className="flex-1">{item.label}</Flavor>;
};
