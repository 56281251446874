import { useCallback, useMemo } from "react";
import StarIcon from "@whoppah/icons/24/Star";
import HalfStarIcon from "@whoppah/icons/24/HalfStar";
import EmptyStarIcon from "@whoppah/icons/24/EmptyStar";
import clsx from "clsx";

const STARS: Record<
  number,
  ({ className }: { className: string }) => JSX.Element
> = {
  [1]: StarIcon,
  [0.5]: HalfStarIcon,
  [0]: EmptyStarIcon,
};

interface RatingProps {
  value?: number;
  maxValue?: number;
  className?: string;
}

const Rating = ({ value = 0, maxValue = 5, className }: RatingProps) => {
  const getStarType = useCallback(
    (starNo: number) => {
      const fixedPart = Math.floor(value);
      const decimalPart = Math.round(value * 2) / 2 - Math.floor(value);

      if (fixedPart >= starNo) return 1;

      if (fixedPart + 1 === starNo) {
        return decimalPart;
      }

      return 0;
    },
    [value]
  );

  const stars = useMemo(() => {
    const starsArray = [];

    for (let i = 1; i <= maxValue; i++) starsArray.push(getStarType(i));

    return starsArray;
  }, [getStarType, maxValue]);

  return (
    <>
      {stars.map((star, index) => {
        const Icon = STARS[star];

        if (Icon)
          return (
            <Icon
              key={`rating_${index}`}
              className={clsx("text-[#f9d960]", className)}
            />
          );

        return null;
      })}
    </>
  );
};

export default Rating;
