import { HIW_SELLING_ROUTE } from "@/common/static/routes.static";
import { Tag as TagIcon } from "@/assets/icons/24/Tag";
import { LinkButton } from "@/ui/next/LinkButton";
import { useTranslation } from "next-i18next";

/**
 * Header create ad button. Navigates user to create ad wizard.
 */
export const HeaderCreateAd = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* Desktop */}
      <LinkButton
        size="large"
        rightIcon={<TagIcon />}
        href={HIW_SELLING_ROUTE}
        className="hidden lg:flex"
      >
        {t("header:nav-sell-now")}
      </LinkButton>
      {/* Tablet */}
      <LinkButton
        rightIcon={<TagIcon />}
        href={HIW_SELLING_ROUTE}
        className="hidden self-stretch md:flex lg:hidden"
      />
      {/* Mobile */}
      <LinkButton href={HIW_SELLING_ROUTE} size="small" className="md:hidden">
        {t("header:header-nav-sell")}
      </LinkButton>
    </>
  );
};
