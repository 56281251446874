import { shouldShowAppBanner } from "@/components/app-banner/should-show-app-banner";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export const useShowAppBanner = () => {
  const router = useRouter();

  const [showBanner, setShowBanner] = useState(shouldShowAppBanner(true));

  useEffect(() => {
    const onRouteChangeComplete = () => {
      setShowBanner(shouldShowAppBanner(false));
    };
    router.events.on("routeChangeComplete", onRouteChangeComplete);
    return () => {
      router.events.off("routeChangeComplete", onRouteChangeComplete);
    };
  }, [router.events]);

  return showBanner;
};
