import useMeHook from "../scenes/auth/hooks/useMe";
import { useCallback } from "react";
import useUpdateMe from "../scenes/auth/hooks/useUpdateMe";

const useMe = () => {
  const { data, loading } = useMeHook();

  const me = data?.me;
  const email = me?.email;
  const fullName = [me?.given_name, me?.family_name].filter(Boolean).join(" ");

  const merchant = me?.merchants[0];
  const phone = merchant?.phone;
  const favoritesCount = merchant?.number_of_favorites;

  const lastUsedPaymentMethod = me?.lastUsedPaymentMethod;

  const [updateMeMutation] = useUpdateMe();

  type UpdateMeInput = {
    firstName?: string;
    lastName?: string;
    phone?: string;
  };

  const updateMe = useCallback(
    async (input: UpdateMeInput) => {
      await updateMeMutation({
        variables: {
          given_name: input.firstName,
          family_name: input.lastName,
          phone: input.phone,
        },
      });
    },
    [updateMeMutation]
  );

  return {
    loading,
    me,
    email,
    fullName,
    merchant,
    phone,
    favoritesCount,
    lastUsedPaymentMethod,
    updateMe,
  };
};

export default useMe;
