import { useFormatMinMax } from "@/common/hooks/intl.hooks";
import { formatPrice } from "@/common/utils/intl.utils";
import {
  isBrandSavedSearchFilter,
  isCategorySavedSearchFilter,
  isColorSavedSearchFilter,
  isMaterialSavedSearchFilter,
  isStyleSavedSearchFilter,
} from "@/common/utils/type-guards.utils";
import {
  SavedSearchFilters,
  SaveSearchFilter,
} from "@/components/search/contracts/search.contracts";
import {
  SEARCH_FILTER_AR,
  SEARCH_FILTER_ART_ORIENTATION,
  SEARCH_FILTER_ART_SIZE,
  SEARCH_FILTER_AVAILABILITY,
  SEARCH_FILTER_BIDDING,
  SEARCH_FILTER_BRAND,
  SEARCH_FILTER_CATEGORY,
  SEARCH_FILTER_COLOR,
  SEARCH_FILTER_COUNTRY,
  SEARCH_FILTER_DEPTH,
  SEARCH_FILTER_DISCOUNT_PERCENTAGE,
  SEARCH_FILTER_HEIGHT,
  SEARCH_FILTER_MATERIAL,
  SEARCH_FILTER_NUMBER_OF_ITEMS,
  SEARCH_FILTER_PRICE,
  SEARCH_FILTER_QUALITY,
  SEARCH_FILTER_SHIPPING_METHOD,
  SEARCH_FILTER_STYLE,
  SEARCH_FILTER_WIDTH,
} from "@/components/search/SearchConstants";
import { useCallback, useMemo } from "react";
import { useTranslation } from "next-i18next";

export const useSearchTagLabels = (filters?: SavedSearchFilters) => {
  const { t } = useTranslation();

  const { format: formatMinMaxPrice } = useFormatMinMax({
    signGenerator: formatPrice,
  });

  const { format: formatMinMaxDimension } = useFormatMinMax({
    signGenerator: value => `${value} cm`,
  });

  const { format: formatMinMaxPercentage } = useFormatMinMax({
    signGenerator: value => `${value}%`,
  });

  const tryGetCategory = useCallback((filter?: SaveSearchFilter) => {
    if (filter?.key === SEARCH_FILTER_CATEGORY && filter.models) {
      return filter.models
        .filter(isCategorySavedSearchFilter)
        .map(model => model.title)
        .join(", ");
    }
  }, []);

  const tryGetPrice = useCallback(
    (filter?: SaveSearchFilter) => {
      if (filter?.key === SEARCH_FILTER_PRICE && filter.value) {
        const price = filter.value.split(",");
        return formatMinMaxPrice(price[0], price[1]);
      }
    },
    [formatMinMaxPrice]
  );

  const tryGetQuality = useCallback(
    (filter?: SaveSearchFilter) => {
      if (filter?.key === SEARCH_FILTER_QUALITY && filter.value) {
        const quality = filter.value
          .split(",")
          .map(key => t(`common:condition-${key}`))
          .join(", ");

        return `${t("common:filter-quality")}: ${quality}`;
      }
    },
    [t]
  );

  const tryGetBrand = useCallback(
    (filter?: SaveSearchFilter) => {
      if (filter?.key === SEARCH_FILTER_BRAND && filter.models) {
        const brands = filter.models
          .filter(isBrandSavedSearchFilter)
          .map(model => model.title)
          .join(", ");

        return `${t("common:filter-brand")}: ${brands}`;
      }
    },
    [t]
  );

  const tryGetStyle = useCallback(
    (filter?: SaveSearchFilter) => {
      if (filter?.key === SEARCH_FILTER_STYLE && filter.models) {
        const styles = filter.models
          .filter(isStyleSavedSearchFilter)
          .map(model => model.title)
          .join(", ");

        return `${t("common:filter-style")}: ${styles}`;
      }
    },
    [t]
  );

  const tryGetMaterial = useCallback(
    (filter?: SaveSearchFilter) => {
      if (filter?.key === SEARCH_FILTER_MATERIAL && filter.models) {
        const materials = filter.models
          .filter(isMaterialSavedSearchFilter)
          .map(model => model.title)
          .join(", ");

        return `${t("common:filter-material")}: ${materials}`;
      }
    },
    [t]
  );

  const tryGetColor = useCallback(
    (filter?: SaveSearchFilter) => {
      if (filter?.key === SEARCH_FILTER_COLOR && filter.models) {
        const colors = filter.models
          .filter(isColorSavedSearchFilter)
          .map(model => model.title)
          .join(", ");

        return `${t("common:filter-color")}: ${colors}`;
      }
    },
    [t]
  );

  /** Shape... */

  const tryGetCountry = useCallback(
    (filter?: SaveSearchFilter) => {
      if (filter?.key === SEARCH_FILTER_COUNTRY && filter.value) {
        const countries = filter.value.split(",").join(", ");
        return `${t("common:filter-country")}: ${countries}`;
      }
    },
    [t]
  );

  const tryGetArtSize = useCallback(
    (filter?: SaveSearchFilter) => {
      if (filter?.key === SEARCH_FILTER_ART_SIZE && filter.value) {
        const sizes = filter.value
          .split(",")
          .map(key => t(`common:filter-art-size-${key}`))
          .join(", ");

        return `${t("common:filter-art-size")}: ${sizes}`;
      }
    },
    [t]
  );

  const tryGetArtOrientation = useCallback(
    (filter?: SaveSearchFilter) => {
      if (filter?.key === SEARCH_FILTER_ART_ORIENTATION && filter.value) {
        const orientation = filter.value
          .split(",")
          .map(key =>
            t(`common:filter-art-orientation-${key.toLocaleLowerCase()}`)
          )
          .join(", ");

        return `${t("common:filter-art-orientation")}: ${orientation}`;
      }
    },
    [t]
  );

  const tryGetDepth = useCallback(
    (filter?: SaveSearchFilter) => {
      if (filter?.key === SEARCH_FILTER_DEPTH && filter.value) {
        const depth = filter.value.split(",");
        const minMaxDepth = formatMinMaxDimension(depth[0], depth[1]);

        return `${t("common:search_filters_depth")}: ${minMaxDepth}`;
      }
    },
    [formatMinMaxDimension, t]
  );

  const tryGetHeight = useCallback(
    (filter?: SaveSearchFilter) => {
      if (filter?.key === SEARCH_FILTER_HEIGHT && filter.value) {
        const height = filter.value.split(",");
        const minMaxHeight = formatMinMaxDimension(height[0], height[1]);

        return `${t("common:search_filters_height")}: ${minMaxHeight}`;
      }
    },
    [formatMinMaxDimension, t]
  );

  const tryGetWidth = useCallback(
    (filter?: SaveSearchFilter) => {
      if (filter?.key === SEARCH_FILTER_WIDTH && filter.value) {
        const width = filter.value.split(",");
        const minMaxWidth = formatMinMaxDimension(width[0], width[1]);

        return `${t("common:search_filters_width")}: ${minMaxWidth}`;
      }
    },
    [formatMinMaxDimension, t]
  );

  const tryGetBidding = useCallback(
    (filter?: SaveSearchFilter) => {
      if (filter?.key === SEARCH_FILTER_BIDDING && filter.value === "true") {
        return t("common:filter-bidding-possible");
      }
    },
    [t]
  );

  const tryGetAvailability = useCallback(
    (filter?: SaveSearchFilter) => {
      if (
        filter?.key === SEARCH_FILTER_AVAILABILITY &&
        filter.value === "true"
      ) {
        return t("common:filter-available-items");
      }
    },
    [t]
  );

  const tryGetAr = useCallback(
    (filter?: SaveSearchFilter) => {
      if (filter?.key === SEARCH_FILTER_AR && filter.value === "true") {
        return t("common:filter-has-ar");
      }
    },
    [t]
  );

  /** Premium... */

  const tryGetDiscount = useCallback(
    (filter?: SaveSearchFilter) => {
      if (filter?.key === SEARCH_FILTER_DISCOUNT_PERCENTAGE && filter.value) {
        const discount = filter.value.split(",");
        const discountMinMax = formatMinMaxPercentage(discount[0], discount[1]);

        return `${t("common:filter-sale")}: ${discountMinMax}`;
      }
    },
    [formatMinMaxPercentage, t]
  );

  const tryGetShipping = useCallback(
    (filter?: SaveSearchFilter) => {
      if (filter?.key === SEARCH_FILTER_SHIPPING_METHOD && filter.value) {
        const method = filter.value
          .split(", ")
          .map(method => t(`common:filter-shipping-method-${method}`))
          .join(", ");

        return `${t("common:filter-shipping-method")}: ${method}`;
      }
    },
    [t]
  );

  const tryGetNumberOfItems = useCallback(
    (filter?: SaveSearchFilter) => {
      if (filter?.key === SEARCH_FILTER_NUMBER_OF_ITEMS && filter.value) {
        const items = t("common:filter-items-count", {
          numberOfItems: filter.value,
        });
        return `${t("common:filter-items")}: ${items}`;
      }
    },
    [t]
  );

  const labels = useMemo(() => {
    const result: string[] = [];

    if (!filters) {
      return result;
    }

    const tryGetLabelFunctions = [
      tryGetCategory,
      tryGetPrice,
      tryGetQuality,
      tryGetBrand,
      tryGetStyle,
      tryGetMaterial,
      tryGetColor,
      tryGetCountry,
      tryGetArtSize,
      tryGetArtOrientation,
      tryGetDepth,
      tryGetHeight,
      tryGetWidth,
      tryGetBidding,
      tryGetAvailability,
      tryGetAr,
      tryGetDiscount,
      tryGetShipping,
      tryGetNumberOfItems,
    ];

    const tryGetLabel = (filter?: SaveSearchFilter) => {
      for (const func of tryGetLabelFunctions) {
        const label = func(filter);

        if (label) {
          return label;
        }
      }
    };

    for (const filter of filters) {
      const label = tryGetLabel(filter);

      if (label) {
        /** Special handling od category: we always want it to go first. */
        if (filter?.key === SEARCH_FILTER_CATEGORY) {
          result.unshift(label);
        } else {
          result.push(label);
        }

        continue;
      }

      if (filter?.value) {
        result.push(filter.value);
      }
    }

    return result;
  }, [
    filters,
    tryGetCategory,
    tryGetPrice,
    tryGetQuality,
    tryGetBrand,
    tryGetStyle,
    tryGetMaterial,
    tryGetColor,
    tryGetCountry,
    tryGetArtSize,
    tryGetArtOrientation,
    tryGetDepth,
    tryGetHeight,
    tryGetWidth,
    tryGetBidding,
    tryGetAvailability,
    tryGetAr,
    tryGetDiscount,
    tryGetShipping,
    tryGetNumberOfItems,
  ]);

  return { labels };
};
