import { Search as SearchIcon } from "@/assets/icons/24/Search";
import { SEARCH_ROUTE } from "@/common/static/routes.static";
import { useHeaderSearchItems } from "@/components/Header/search/hooks/useHeaderSearchItems";
import { useHeaderSearchQuery } from "@/components/Header/search/hooks/useHeaderSearchQuery";
import { HeaderSearchItemList } from "@/components/Header/search/HeaderSearchItemList";
import { Input } from "@/ui/cva/input/Input";
import * as Popover from "@radix-ui/react-popover";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import React, {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { debounce } from "lodash-es";

export const HeaderSearch = () => {
  const [focused, setFocused] = useState<boolean>(false);
  const [query, setQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState<number | null>(null);

  const inputRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();
  const router = useRouter();

  const { items, addHistoryItem } = useHeaderSearchItems(query);
  const urlQuery = useHeaderSearchQuery();

  /**
   * Set input's value if there is query entry in the URL.
   */
  useEffect(() => {
    if (!urlQuery) {
      return;
    }

    setQuery(urlQuery);

    if (inputRef.current) {
      inputRef.current.value = urlQuery;
    }
  }, [urlQuery]);

  const tryOpenPopup = useCallback(() => {
    setOpen(focused && !!items.count);
  }, [focused, items.count]);

  const tryClosePopup = useCallback(() => {
    setOpen(false);
    setIndex(null);
  }, []);

  /** Whenever user types something, try to open or close the popup if the input is focued. */
  useEffect(() => {
    const shouldOpen = !!query && !!items.count;

    if (shouldOpen) {
      tryOpenPopup();
    } else {
      tryClosePopup();
    }
  }, [tryClosePopup, tryOpenPopup, query, items.count]);

  const handleSearchClick = () => {
    router.push(`${SEARCH_ROUTE}?query=${query}`);
  };

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setQuery(evt.target.value);
  };

  const handleFocus = () => {
    setFocused(true);
    tryOpenPopup();
  };

  const handleBlur = () => {
    setFocused(false);
    tryClosePopup();
  };

  const moveHighlightDown = () => {
    setIndex(prev => ((prev ?? -1) + 1) % items.count);
  };

  const moveHighlightUp = () => {
    setIndex(prev => ((prev ?? items.count) - 1 + items.count) % items.count);
  };

  const tryActivateQuery = () => {
    if (query && !index) {
      const url = addHistoryItem(query);
      tryClosePopup();
      router.push(url);

      return true;
    }
  };

  const tryActivateHistoryItem = () => {
    const historyItem = items.historyItems.find(item => item.index === index);

    if (historyItem) {
      tryClosePopup();
      router.push(historyItem.payload.url);

      return true;
    }
  };

  const tryActivatedSavedSearchItem = () => {
    const savedItem = items.savedSearchItems.find(item => item.index === index);

    if (savedItem) {
      tryClosePopup();
      router.push(savedItem.payload.url);

      return true;
    }
  };

  const tryActivateSuggestionItem = () => {
    const suggestionItem = items.suggestionItems.find(
      item => item.index === index
    );

    if (suggestionItem) {
      addHistoryItem(suggestionItem.payload.title);
      tryClosePopup();
      router.push(suggestionItem.payload.url);

      return true;
    }
  };

  const tryActivateItem = () => {
    if (tryActivateQuery()) {
      return;
    }

    if (tryActivateHistoryItem()) {
      return;
    }

    if (tryActivatedSavedSearchItem()) {
      return;
    }

    if (tryActivateSuggestionItem()) {
      return;
    }
  };

  const handleKeyUp = (evt: KeyboardEvent<HTMLInputElement>) => {
    if (evt.key === "ArrowDown") {
      moveHighlightDown();
    }

    if (evt.key === "ArrowUp") {
      moveHighlightUp();
    }

    if (evt.key === "Enter") {
      tryActivateItem();
    }
  };

  const handleOpenAutoFocus = (evt: Event) => {
    /** Dont focus away from input. */
    evt.preventDefault();
  };

  const handleItemMouseEnter = (index: number) => {
    setIndex(index);
  };

  const handleItemMouseLeave = () => {
    setIndex(null);
  };

  const handleItemSelect = (url: string) => {
    tryClosePopup();
    router.push(url);
  };

  return (
    <Popover.Root open={open}>
      <Popover.Anchor className="flex-1">
        <Input
          ref={inputRef}
          color="brand"
          placeholder={t("header:nav-search-placeholder")}
          rightElement={
            <button
              type="button"
              className="flex h-6 w-6 items-center justify-center text-grey-400 hover:text-grey-800 disabled:pointer-events-none"
              disabled={!query}
              onClick={handleSearchClick}
            >
              <SearchIcon className="h-5 w-5" />
            </button>
          }
          onChange={debounce(handleChange, 250)}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyUp={handleKeyUp}
          onClick={handleFocus}
        />
      </Popover.Anchor>
      <Popover.Portal>
        <Popover.Content
          onOpenAutoFocus={handleOpenAutoFocus}
          className="z-[200] mt-1 flex w-[var(--radix-popover-trigger-width)] flex-col space-y-1 rounded-[0.25rem] border border-grey-500 bg-white p-1"
        >
          <HeaderSearchItemList
            items={items}
            highlightedIndex={index}
            onMouseEnter={handleItemMouseEnter}
            onMouseLeave={handleItemMouseLeave}
            onSelect={handleItemSelect}
          />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
