import { useMemo } from "react";
import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { isEqual } from "lodash-es";
import merge from "deepmerge";

// Services
import { createApolloClientLink } from "./links";
import { typePolicies } from "./type-policies";
import { AuthLinkOptions } from "./links/create-auth-link";

const isServer = typeof window === "undefined";
let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

export const createApolloClient = (options?: AuthLinkOptions) => {
  return new ApolloClient({
    ssrMode: isServer,
    link: createApolloClientLink(options),
    cache: new InMemoryCache({ typePolicies }),
    name: "com.whoppah.app-apollo-web",
  });
};

const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";

export const initializeApollo = (initialState = null) => {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter(d => sourceArray.every(s => !isEqual(d, s))),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") {
    return _apolloClient;
  }

  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = _apolloClient;
  }

  return _apolloClient;
};

export function apolloClientToProps(
  client: ApolloClient<NormalizedCacheObject>
) {
  return {
    props: {
      [APOLLO_STATE_PROP_NAME]: client.cache.extract(),
    },
  };
}

export function addApolloState(
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: { props?: Record<string, any>; [key: string]: any }
) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps: {
  props?: Record<string, unknown>;
  [key: string]: any;
}) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);
  return store;
}
