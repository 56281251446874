import { QueryHookOptions, useQuery } from "@apollo/client";
import { authStore, useAuth } from "@/auth";
import meGql from "@whoppah/apollo/query/me.graphql";
import { MeQuery, MeQueryVariables } from "@whoppah/apollo/schema";
import { analyticsService } from "@/common/services/analytics/analytics.service";
import { useUpdateUser } from "statsig-react";

import { createStatsigUser } from "@/experiments/utils/create-statsig-user";

// TODO: Move this hook and rework it
const useMe = (options?: QueryHookOptions<MeQuery, MeQueryVariables>) => {
  const { token, reset, setUserId } = useAuth();
  const updateUser = useUpdateUser();
  const { userId, deviceId } = authStore.getState();
  return useQuery(meGql, {
    ...options,
    skip: !token,
    onCompleted: data => {
      analyticsService.identify(data.me ?? undefined);
      setUserId(data.me?.id);
      if (userId) {
        updateUser(createStatsigUser({ userId, deviceId }));
      }
    },
    onError: () => {
      if (token) {
        reset();
      }
    },
  });
};

export default useMe;
