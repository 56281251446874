import { analyticsService } from "@/common/services/analytics/analytics.service";
import { HeaderSearchSavedSearchItem as HeaderSearchSavedSearchItemType } from "@/components/Header/search/contracts/header-search.contracts";
import { HeaderSearchItem } from "@/components/Header/search/HeaderSearchItem";
import { useSearchTagLabels } from "@/components/search/hooks/useSearchTagLabels";
import { MouseEvent, useMemo } from "react";
import { Bookmark as BookmarkIcon } from "@/assets/icons/24/Bookmark";

type HeaderSearchSavedSearchItemProps = {
  item: HeaderSearchSavedSearchItemType;
  highlightedIndex?: number | null;
  onMouseEnter?: (evt: MouseEvent<HTMLLIElement>) => void;
  onMouseLeave?: (evt: MouseEvent<HTMLLIElement>) => void;
  onSelect?: (url: string) => void;
};

export const HeaderSearchSavedSearchItem = ({
  item,
  highlightedIndex,
  onMouseEnter,
  onMouseLeave,
  onSelect,
}: HeaderSearchSavedSearchItemProps) => {
  const { labels } = useSearchTagLabels(item.payload.filters);

  const title = useMemo(() => {
    const { query } = item.payload;
    const queryStr = query ? `'${query}'` : undefined;

    return [queryStr, ...labels].filter(Boolean).join(", ");
  }, [item.payload, labels]);

  const handleSelect = (url: string) => {
    /** Trigger `save_alert_search` event. */
    analyticsService.trackSaveAlertSearch();

    onSelect?.(url);
  };

  return (
    <HeaderSearchItem
      icon={<BookmarkIcon className="h-5 w-5 shrink-0 text-brand-500" />}
      title={title}
      url={item.payload.url}
      highlight={item.index === highlightedIndex}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onSelect={handleSelect}
    />
  );
};
