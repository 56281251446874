import { MegaMenuPanelData } from "@/common/contracts/mega-menu.contracts";
import { MegaMenuHeaderItem } from "@/components/mega-menu/MegaMenuHeaderItem";
import { NavigationMenuList } from "@radix-ui/react-navigation-menu";

export const MegaMenuHeaderItemList = ({ items }: MegaMenuPanelData) => {
  return (
    <NavigationMenuList
      data-mega-menu-items
      className="flex flex-wrap items-center space-x-6 space-y-2 pb-4 [&>:first-child]:mt-2"
    >
      {items?.map(item => <MegaMenuHeaderItem key={item.id} {...item} />)}
    </NavigationMenuList>
  );
};
