import { IconProps } from "@/common/contracts/icon.contracts";

const Art = ({ className }: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M10.914 8.86091C10.7089 6.724 12.2683 5.35133 13.7373 5.12102C15.4858 4.84603 17.2308 6.05714 17.5574 7.82855C17.8919 9.64349 16.6934 11.4367 14.9094 11.7426C14.5462 11.8045 14.1612 11.7426 13.7865 11.738L13.7991 11.7529V8.88268C13.6112 8.88268 13.4543 8.88383 13.2973 8.88268C12.4987 8.87237 11.6989 8.86206 10.9003 8.85175L10.9129 8.85977L10.914 8.86091Z"
        fill="currentColor"
      />
      <path
        d="M9.67234 14.8133C9.82931 14.795 9.98743 14.7618 10.1444 14.7606C11.2409 14.756 12.3386 14.7595 13.4351 14.7572C13.5772 14.7572 13.7193 14.7377 13.9003 14.7251C14.7058 15.9133 15.5308 17.1324 16.3856 18.3928H7.33032C7.41969 18.2484 7.48501 18.1361 7.55719 18.0284C8.21373 17.0602 8.87371 16.0932 9.52911 15.1227C9.59328 15.0276 9.62994 14.9153 9.67921 14.811L9.67234 14.8133Z"
        fill="currentColor"
      />
      <path
        d="M9.67903 14.8119C9.278 14.7947 8.87812 14.7752 8.47709 14.7604C8.32011 14.7546 8.16314 14.7604 7.98096 14.7604V8.86178C8.95718 8.86178 9.93569 8.86178 10.9142 8.86178L10.9016 8.85376C10.9302 8.98553 10.9577 9.11844 10.9887 9.24906C11.3015 10.5851 12.5699 11.7148 13.8005 11.7538L13.7879 11.7389C13.7879 12.636 13.7879 13.5332 13.7879 14.5564C13.1141 13.563 12.4966 12.6532 11.8561 11.7068C11.2121 12.6532 10.6014 13.5573 9.98381 14.4567C9.89559 14.5862 9.77757 14.6962 9.6733 14.8154L9.68018 14.8131L9.67903 14.8119Z"
        fill="currentColor"
      />
      <path
        d="M19.7803 3H5.16333V20.47H19.7803V3Z"
        stroke="currentColor"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default Art;
