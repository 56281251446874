import { cn } from "@/common/utils/css.utils";
import { MegaMenuItemData } from "@/common/contracts/mega-menu.contracts";
import { MegaMenuCardItem } from "@/components/mega-menu/MegaMenuCardItem";
import { isMegaMenuCardItem } from "@/common/utils/type-guards.utils";

export const MegaMenuCardList = ({
  items,
  className,
}: {
  items?: MegaMenuItemData[];
  className?: string;
}) => {
  return (
    <ul className={cn("grid h-fit flex-1 gap-x-4 gap-y-6", className)}>
      {items?.map(item => {
        if (!item.href || !isMegaMenuCardItem(item)) {
          return null;
        }

        return <MegaMenuCardItem key={item.id} item={item} />;
      })}
    </ul>
  );
};
