export const createStatsigUser = ({
  userId,
  deviceId,
}: {
  userId?: string;
  deviceId: string;
}) => {
  return {
    userID: userId,
    customIDs: {
      stableID: deviceId,
    },
  };
};
