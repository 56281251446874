import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { initializeSubscriptionsClient } from "../subscriptions";

/**
 * Creates a WebSocket link for Apollo Client. This should be used
 * for subscriptions.
 * @returns {GraphQLWsLink} The WebSocket link.
 */
export const createSubscriptionsLink = () => {
  return new GraphQLWsLink(initializeSubscriptionsClient());
};
