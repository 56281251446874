import { useMegaMenuData } from "@/components/mega-menu/hooks/useMegaMenuData";
import { MegaMenuMobilePanel } from "@/components/mega-menu/mobile/MegaMenuMobilePanel";
import { MenuHamburger as MenuHamburgerIcon } from "@whoppah/icons/24/MenuHamburger";
import { ComponentProps, forwardRef, useRef } from "react";

const MegaMenuMobileHamburger = forwardRef<
  HTMLButtonElement,
  ComponentProps<"button">
>((props, ref) => {
  return (
    <button
      ref={ref}
      {...props}
      aria-label="Show Menu"
      className="flex cursor-pointer flex-col items-center md:hidden"
    >
      <MenuHamburgerIcon />
    </button>
  );
});

export const MegaMenuMobile = () => {
  const { data } = useMegaMenuData({ mobile: true });
  const hamburgerRef = useRef<HTMLButtonElement>(null);

  const handleClose = () => {
    if (hamburgerRef.current) {
      hamburgerRef.current.click();
    }
  };

  return (
    <MegaMenuMobilePanel
      trigger={<MegaMenuMobileHamburger ref={hamburgerRef} />}
      title="Menu"
      items={data?.items}
      onClose={handleClose}
    />
  );
};
