import { useAuth } from "@/auth";
import { analyticsService } from "@/common/services/analytics/analytics.service";
import { HOME_ROUTE } from "@/common/static/routes.static";
import { useRouter } from "next/router";
import React from "react";
import Link from "@whoppah/next/link";
import { useReactiveVar } from "@apollo/client";
import { unreadMessagesCountVar } from "@/variables/unreadMessagesCountVar";
import useCartCount from "@whoppah/apollo/hooks/useCartCount";

interface HeaderMenuActionProps {
  leftIcon?: React.ReactNode;
  label: string;
  href?: string;
  rightIcon?: React.ReactNode;
  onClick?: () => void;
}

const HeaderMenuLinkAction = ({
  label,
  leftIcon,
  href,
  hasBadge,
  onClick,
}: HeaderMenuActionProps & { hasBadge: boolean }) => {
  return (
    <Link href={href ?? HOME_ROUTE} prefetch={false}>
      <a className="flex w-full items-center space-x-2" onClick={onClick}>
        <div className="relative">
          {leftIcon}
          {hasBadge && (
            <div className="absolute top-0 right-0 flex h-2 w-2 items-center justify-center rounded-full bg-attention-500" />
          )}
        </div>
        <span>{label}</span>
      </a>
    </Link>
  );
};

const HeaderMenuActionChat = ({
  label,
  leftIcon,
  href,
  onClick,
}: HeaderMenuActionProps) => {
  // Derived state
  const unreadCount = useReactiveVar(unreadMessagesCountVar);

  // JSX
  return (
    <HeaderMenuLinkAction
      label={label}
      leftIcon={leftIcon}
      href={href}
      onClick={onClick}
      hasBadge={Boolean(unreadCount)}
    />
  );
};

const HeaderMenuActionCart = ({
  label,
  leftIcon,
  href,
}: HeaderMenuActionProps) => {
  // Hooks
  const { isLoggedIn } = useAuth();
  const { data } = useCartCount({ skip: !isLoggedIn });

  // Derived state
  const cartCount = data?.cartCount ?? 0;

  // JSX
  return (
    <HeaderMenuLinkAction
      label={label}
      leftIcon={leftIcon}
      href={href}
      hasBadge={Boolean(cartCount)}
    />
  );
};

/**
 * Create ad action.
 */
const HeaderMenuActionSell = ({
  label,
  leftIcon,
  href,
  onClick,
}: HeaderMenuActionProps) => {
  return (
    <Link href={href ?? "/"} prefetch={false}>
      <a className="flex w-full items-center space-x-2" onClick={onClick}>
        {leftIcon}
        <span>{label}</span>
      </a>
    </Link>
  );
};

/**
 * Logout action.
 */
const HeaderMenuActionLogout = ({
  leftIcon,
  label,
  rightIcon,
  onClick,
}: HeaderMenuActionProps) => {
  const { logout } = useAuth();

  const handleClick = () => {
    logout();
    analyticsService.trackLogout();
    onClick?.();
  };

  return (
    <span className="flex w-full items-center space-x-2" onClick={handleClick}>
      {leftIcon}
      <span>{label}</span>
      {rightIcon}
    </span>
  );
};

/**
 * Login / Signup action.
 */
const HeaderMenuActionLoginSignup = ({
  leftIcon,
  label,
  href,
  rightIcon,
  onClick,
}: HeaderMenuActionProps) => {
  const router = useRouter();

  const handleClick = () => {
    router
      .push({
        pathname: href,
        query: { returnUrl: router.asPath },
      })
      .catch(ex => console.error((ex as Error).message));

    onClick?.();
  };

  return (
    <span className="flex w-full items-center space-x-2" onClick={handleClick}>
      {leftIcon}
      <span>{label}</span>
      {rightIcon}
    </span>
  );
};

const ACTION_MAP: Record<
  string,
  (props: HeaderMenuActionProps) => JSX.Element | null
> = {
  chat: HeaderMenuActionChat,
  cart: HeaderMenuActionCart,
  sell: HeaderMenuActionSell,
  logout: HeaderMenuActionLogout,
  login: HeaderMenuActionLoginSignup,
  signup: HeaderMenuActionLoginSignup,
};

interface HeaderMenuActionsProps {
  action: string;
  leftIcon?: React.ReactNode;
  label: string;
  rightIcon?: React.ReactNode;
  href?: string;
  onClick?: () => void;
}

/**
 * Header actions.
 */
const HeaderMenuActions = ({
  action,
  leftIcon,
  label,
  rightIcon,
  href,
  onClick,
}: HeaderMenuActionsProps) => {
  const ActionComponent = ACTION_MAP[action];

  if (ActionComponent)
    return (
      <ActionComponent
        leftIcon={leftIcon}
        label={label}
        rightIcon={rightIcon}
        href={href}
        onClick={onClick}
      />
    );

  return null;
};

export default HeaderMenuActions;
