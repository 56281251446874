import NextLink, { LinkProps as NextLinkProps } from "next/link";
import { useRouter, Locale } from "next/router";
import { PropsWithChildren, forwardRef } from "react";
import { UrlObject } from "url";
import localizeHref from "../lib/multilingual/localizeHref";

type Url = string | UrlObject | Record<Locale, string | UrlObject>;

type LinkProps = Omit<NextLinkProps, "href" | "as"> & {
  href: Url;
  as?: Url;
  locale?: Locale;
  sourceLocale?: Locale;
};

const getLocalizedHref = (href: Url, source: Locale, destination: Locale) => {
  if (typeof href === "undefined") return href;

  const s = source;
  const d = destination;
  if (typeof href === "string") return localizeHref(href, s, d);

  if ("en" in href) {
    const dHref = href[destination];
    if (typeof dHref === "string") return localizeHref(dHref, s, d);
    const result = { ...dHref, pathname: dHref.pathname };
    return result as UrlObject;
  }
  const result = { ...href, pathname: href.pathname };
  return result as UrlObject;
};

const Link = forwardRef<HTMLAnchorElement, PropsWithChildren<LinkProps>>(
  ({ sourceLocale, locale, href, as, ...props }, ref) => {
    const router = useRouter();
    const source = sourceLocale ?? router.locale;
    const destination = locale ?? router.locale;

    const localizedHref = getLocalizedHref(href, source, destination);
    const localizedAs = as ? getLocalizedHref(as, source, destination) : as;

    return (
      <NextLink
        ref={ref}
        legacyBehavior
        prefetch={false}
        href={localizedHref}
        as={localizedAs}
        locale={destination}
        {...props}
      />
    );
  }
);

export default Link;
