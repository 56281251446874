import cookies from "js-cookie";
import { getPageType } from "@/common/utils/analytics.utils";

// The banner should not open when the user starts from these pages,
// but it should remain open when the user navigates to these pages.
const DONT_OPEN_FOR_PAGE_TYPES = new Set([
  "pdp", //
]);

// The banner should be closed when the user starts or navigates to one
// of these pages.
const CLOSE_FOR_PAGE_TYPES = new Set([
  "create_ad_flow",
  "cart_signup",
  "cart_overview",
  "cart",
  "checkout",
  "chat",
]);

export const shouldShowAppBanner = (initial: boolean) => {
  const isServer = typeof window === "undefined";

  if (isServer) {
    return false;
  }

  const isMobileDevice = /Android|iPhone|iPad|iPod/.test(navigator.userAgent);

  if (!isMobileDevice) {
    return false;
  }

  const appBannerDismissed = cookies.get("app_banner_dismissed");

  if (appBannerDismissed) {
    return false;
  }

  const pageType = getPageType();

  if (pageType) {
    // If the page type if in close list, we should close the banner.
    if (CLOSE_FOR_PAGE_TYPES.has(pageType)) {
      return false;
    }

    // If the page type is in dont open list, we should not show the banner
    // but we should keep it open when the user navigates to one of these pages.
    if (DONT_OPEN_FOR_PAGE_TYPES.has(pageType)) {
      return !initial;
    }
  }

  return true;
};

export const dismissAppBanner = () => {
  const now = new Date();
  const tomorrow = new Date(now.getTime() + 24 * 60 * 60 * 1000);

  cookies.set("app_banner_dismissed", "true", {
    path: "/",
    expires: tomorrow,
    sameSite: "lax",
  });
};
