import { useAuth } from "@/auth";
import { MegaMenuItemData } from "@/common/contracts/mega-menu.contracts";
import {
  GUEST_TAG,
  OVERRIDE_TAG,
  USER_TAG,
} from "@/common/static/mega-menu.static";
import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";
import { cloneDeep } from "lodash-es";
import MegaMenuPreval from "@/components/mega-menu/preval/MegaMenu.preval";

const ACCESS_ROLE_REGEX = new RegExp(`(${USER_TAG})|(${GUEST_TAG})`);
const OVERRIDE_HREF_REGEX = new RegExp(`${OVERRIDE_TAG}=(.*)$`);

type useMegaMenuDataOptions = {
  mobile: boolean;
};

export const useMegaMenuData = (options?: useMegaMenuDataOptions) => {
  const { isLoggedIn } = useAuth();
  const { locale } = useRouter();

  const overrideMobileItemHref = useCallback((item: MegaMenuItemData) => {
    if (item.items) {
      item.items = item.items.map(overrideMobileItemHref);
    }

    if (item.href?.startsWith("/http")) {
      return { ...item, href: item.href?.substring(1) };
    }

    return { ...item, href: item.href?.replace(ACCESS_ROLE_REGEX, "") };
  }, []);

  const filterMobileItemByAccess = useCallback(
    (item: MegaMenuItemData) => {
      if (item.items) {
        item.items = item.items.filter(filterMobileItemByAccess);
      }

      if (item.href?.includes(OVERRIDE_TAG)) {
        return isLoggedIn
          ? item.href?.endsWith(USER_TAG)
          : item.href?.endsWith(GUEST_TAG);
      }

      return true;
    },
    [isLoggedIn]
  );

  /**
   * Gets mobile menu from preval, filters some items whether
   * the user is logged-in or not.
   */
  const loadMobileMenu = useCallback(
    (menu: { items: MegaMenuItemData[] }) => {
      return cloneDeep(menu.items)
        .filter(filterMobileItemByAccess)
        .map(overrideMobileItemHref);
    },
    [filterMobileItemByAccess, overrideMobileItemHref]
  );

  const overrideItemHref = useCallback((item: MegaMenuItemData) => {
    if (item.submenu?.items) {
      item.submenu.items = item.submenu.items.map(overrideItemHref);
    }

    if (item.items) {
      item.items = item.items.map(overrideItemHref);
    }

    if (item.href?.includes(OVERRIDE_TAG)) {
      const overrideHref = OVERRIDE_HREF_REGEX.exec(item.href);

      if (overrideHref && overrideHref.length === 2) {
        return { ...item, href: overrideHref[1] };
      }

      return item;
    }

    return item;
  }, []);

  /** Gets main menu and mobile menu from preval. */
  const load = useCallback(() => {
    try {
      const megaMenu = MegaMenuPreval.menu[locale];
      const mobileMenu = MegaMenuPreval.mobile[locale];

      const mobileItems = options?.mobile ? loadMobileMenu(mobileMenu) : [];

      return {
        ...megaMenu,
        items: [...megaMenu.items.map(overrideItemHref), ...mobileItems],
      };
    } catch (ex) {
      console.error(ex);
    }
  }, [loadMobileMenu, locale, options?.mobile, overrideItemHref]);

  const data = useMemo(() => {
    return load();
  }, [load]);

  return { data };
};
