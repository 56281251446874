import { IconProps } from "@/common/contracts/icon.contracts";

export const Gift = (props: IconProps) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.59455 4.02506C8.04818 4.01688 6.54273 5.50597 7.23 7.35506H4.63636C4.20237 7.35506 3.78616 7.52746 3.47928 7.83434C3.1724 8.14121 3 8.55743 3 8.99142V10.6278C3 10.8448 3.0862 11.0529 3.23964 11.2063C3.39308 11.3598 3.60119 11.446 3.81818 11.446H11.1818V8.99142H12.8182V11.446H20.1818C20.3988 11.446 20.6069 11.3598 20.7604 11.2063C20.9138 11.0529 21 10.8448 21 10.6278V8.99142C21 8.55743 20.8276 8.14121 20.5207 7.83434C20.2138 7.52746 19.7976 7.35506 19.3636 7.35506H16.77C17.7273 4.6796 14.1273 2.7896 12.4664 5.09688L12 5.71869L11.5336 5.08051C11.0182 4.35233 10.3064 4.03324 9.59455 4.02506ZM9.54545 5.71869C10.2736 5.71869 10.6418 6.60233 10.1264 7.11778C9.61091 7.63324 8.72727 7.26506 8.72727 6.53688C8.72727 6.31988 8.81347 6.11177 8.96691 5.95833C9.12035 5.8049 9.32846 5.71869 9.54545 5.71869ZM14.4545 5.71869C15.1827 5.71869 15.5509 6.60233 15.0355 7.11778C14.52 7.63324 13.6364 7.26506 13.6364 6.53688C13.6364 6.31988 13.7226 6.11177 13.876 5.95833C14.0294 5.8049 14.2375 5.71869 14.4545 5.71869ZM3.81818 12.2641V18.8096C3.81818 19.2436 3.99058 19.6598 4.29746 19.9667C4.60434 20.2736 5.02055 20.446 5.45455 20.446H18.5455C18.9794 20.446 19.3957 20.2736 19.7025 19.9667C20.0094 19.6598 20.1818 19.2436 20.1818 18.8096V12.2641H12.8182V18.8096H11.1818V12.2641H3.81818Z"
        fill="currentColor"
      />
    </svg>
  );
};
