import { MeQuery } from "@whoppah/apollo/schema";

type Member = MeQuery["me"];

const AccountUtils = {
  isInfoMissing: (me?: Member) => {
    if (!me) return false;

    const merchant = me?.merchants?.[0];
    const address = merchant?.addresses?.[0];

    // If any of these is missing, consider account as not complete.
    return (
      !me.given_name ||
      !me.family_name ||
      !me.merchants?.[0]?.phone ||
      !me.email ||
      !address?.country ||
      !address?.line1 ||
      !address?.postal_code ||
      !address?.city ||
      !me.dob
    );
  },
};

export default AccountUtils;
