import { StatsigOptions } from "statsig-react";
import { STATSIG_ENVIRONMENT } from "@/config";

export const getStatsigOptions = ({
  deviceId,
}: {
  deviceId: string;
}): StatsigOptions => {
  return {
    overrideStableID: deviceId,
    environment: { tier: STATSIG_ENVIRONMENT },
  };
};
