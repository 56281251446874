import React, { useCallback, useState } from "react";
import HeaderAccountJson from "@/common/static/header/account-menu.static.json";
import { Transition } from "@headlessui/react";
import HeaderAccountAvatar from "./HeaderAccountAvatar";
import HeaderAccountMenu from "./HeaderAccountMenu";

/**
 * Header account action that has a drop-dawn with shortcuts that can navigate user to his/her various account aspects.
 */
const HeaderAccount = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openMenu = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeMenu = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleMouseEnter = () => {
    openMenu();
  };

  const handleMouseLeave = () => {
    closeMenu();
  };

  const handleMenuItemClick = () => {
    closeMenu();
  };

  return (
    <button
      className="group relative hidden md:block"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <HeaderAccountAvatar />
      <Transition
        show={isOpen}
        enter="transition ease-in-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in-out duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <HeaderAccountMenu
          className="absolute left-1/2 z-[100] flex -translate-x-1/2 flex-col space-y-2 border bg-white p-10"
          items={HeaderAccountJson.items}
          onItemClick={handleMenuItemClick}
          closeMenu={closeMenu}
        />
      </Transition>
    </button>
  );
};

export default HeaderAccount;
