import { AnchorButton, AnchorButtonProps } from "@/ui/cva/Button";
import Link from "@whoppah/next/link";
import React, { forwardRef } from "react";

type LinkButtonProps = Omit<AnchorButtonProps, "href"> & {
  href: string;
  prefetch?: boolean;
};

/**
 * Next.js specific link button that wraps our general {@link AnchorButton} with Next.js {@link Link}.
 *
 * Use {@link LinkButton} to link to the internal resource within the project - page for example. This component will
 * respect users locale and show the correct language.
 *
 * Use {@link AnchorButton} to link to the external resources.
 */
export const LinkButton = forwardRef<HTMLAnchorElement, LinkButtonProps>(
  (props, ref) => {
    const { href, prefetch, ...rest } = props;

    return (
      <Link href={href} passHref={true} prefetch={prefetch ?? false}>
        <AnchorButton {...rest} ref={ref} />
      </Link>
    );
  }
);
