import { useAuth } from "@/auth";
import { useHeaderAccountMobileData } from "@/components/Header/hooks/useHeaderAccountMobileData";
import NoSSR from "@mpth/react-no-ssr";
import { ProfileOutlined as ProfileOutlinedIcon } from "@whoppah/icons/24/ProfileOutlined";
import { MegaMenuMobilePanel } from "@/components/mega-menu/mobile/MegaMenuMobilePanel";
import React, { useRef } from "react";

export const HeaderAccountMobile = () => {
  const { data } = useHeaderAccountMobileData();
  const { isLoggedIn } = useAuth();

  const menuTriggerRef = useRef<HTMLButtonElement>(null);

  const handleClose = () => {
    if (menuTriggerRef.current) {
      menuTriggerRef.current.click();
    }
  };

  if (isLoggedIn) {
    return null;
  }

  return (
    <NoSSR>
      <MegaMenuMobilePanel
        trigger={
          <button ref={menuTriggerRef} type="button">
            <ProfileOutlinedIcon />
          </button>
        }
        title={data.label}
        items={data.items}
        onClose={handleClose}
      />
    </NoSSR>
  );
};
