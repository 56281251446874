import { IconProps } from "@/common/contracts/icon.contracts";

export const ChevronRight = (props: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.31093 6.31228C8.89636 6.72685 8.89636 7.39656 9.31093 7.81114L13.4355 11.9357L9.31093 16.0602C8.89636 16.4748 8.89636 17.1445 9.31093 17.559C9.72551 17.9736 10.3952 17.9736 10.8098 17.559L15.6891 12.6798C16.1036 12.2652 16.1036 11.5955 15.6891 11.1809L10.8098 6.30165C10.4058 5.8977 9.72551 5.8977 9.31093 6.31228Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ChevronRight;
