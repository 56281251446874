import { cva, VariantProps } from "class-variance-authority";
import { ComponentProps } from "react";

const FlavorStyle = cva("font-title", {
  variants: {
    size: {
      small: "text-sm",
      medium: "text-base",
      large: "text-lg",
    },
  },
  defaultVariants: {
    size: "small",
  },
});

type FlavorProps = VariantProps<typeof FlavorStyle> & ComponentProps<"span">;

export const Flavor = ({ size, className, ...props }: FlavorProps) => {
  return <span className={FlavorStyle({ size, className })} {...props} />;
};
