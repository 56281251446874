import NoSSR from "@mpth/react-no-ssr";
import React from "react";
import Link from "@whoppah/next/link";
import ContactIcon from "@whoppah/icons/24/Contact";
import { useReactiveVar } from "@apollo/client";
import { useAuth } from "@/auth";
import { unreadMessagesCountVar } from "@/variables/unreadMessagesCountVar";
import { useTranslation } from "next-i18next";
import { CHAT_ROUTE } from "@/common/static/routes.static";

/**
 * Header chat action icon that navigates user to his/her chat.
 */
const HeaderChat = () => {
  const { t } = useTranslation();
  const { isLoggedIn } = useAuth();

  const unreadCount = useReactiveVar(unreadMessagesCountVar);

  if (!isLoggedIn) {
    return null;
  }

  return (
    <NoSSR>
      <Link href={CHAT_ROUTE} prefetch={false} passHref>
        <a className="group flex cursor-pointer flex-col items-center md:space-y-1">
          <div className="relative">
            <ContactIcon />
            {Boolean(unreadCount) && (
              <div className="absolute right-0 top-0 flex h-2 w-2 items-center justify-center rounded-full bg-attention-500" />
            )}
          </div>
          <span className="hidden font-body text-sm group-hover:text-attention-500 lg:inline">
            {t("header:nav-chats")}
          </span>
        </a>
      </Link>
    </NoSSR>
  );
};

export default HeaderChat;
