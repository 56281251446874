import { CommonPaginationResult } from "common/contracts/pagination.contracts";
import { uniqBy } from "lodash-es";

/**
 * Function that provides a default behaviour for merging multiple pagination results.
 * Appends the incoming items to the existing items and uses the pagination data from the incoming result as the newest one.
 * @param existing - The existing pagination result that is already saved in the local Apollo cache.
 * @param incoming - The incoming pagination result that should be merged with the existing data in the cache.
 * @returns Merged pagination data.
 */
const defaultPaginationMerge = (
  existing: CommonPaginationResult | undefined,
  incoming: CommonPaginationResult
): CommonPaginationResult => {
  let existingItems: unknown[] = [];
  if (existing) {
    existingItems = existing.items;
  }
  const { items: incomingItems, pagination } = incoming;
  const mergedItems = existingItems.concat(incomingItems);
  return { items: uniqBy(mergedItems, "__ref"), pagination };
};

/**
 * A field policy specifies how a particular field in our InMemoryCache is read and written.
 * We can define a field policy to merge the results of paginated queries into a single list.
 * Take a look at the official documentation for more info: https://www.apollographql.com/docs/react/pagination/core-api/#merging-paginated-results
 * @param keyArgs - Specifies which of the field's arguments cause the cache to store a separate value for each unique combination of those arguments.
 * @param merge - A merge function tells the Apollo Client cache how to combine incoming data with existing cached data for a particular field. Without this function, incoming field values overwrite existing field values by default.
 */
export const typePolicies = {
  Query: {
    fields: {
      favorites: {
        keyArgs: ["order"],
        merge: defaultPaginationMerge,
      },
      getThreads: {
        keyArgs: [],
        merge: defaultPaginationMerge,
      },
      getMessages: {
        // Whenever the `threadId` field of the `input` argument changes or the `$lang` variable changes, store it as a separate value in cache.
        keyArgs: ["input", ["threadId"], "$lang"],
        merge: defaultPaginationMerge,
      },
    },
  },
};
