const EmptyStar = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12.3989 17.1148L11.9971 16.8603L11.5956 17.1151L7.1281 19.9505L8.32655 14.5514L8.42083 14.1267L8.09891 13.834L4.06832 10.1693L9.33876 9.69982L9.7957 9.65912L9.96758 9.23379L11.9975 4.21076L14.0275 9.23379L14.1993 9.65912L14.6563 9.69982L19.9356 10.1701L15.9058 13.834L15.5839 14.1267L15.6782 14.5514L16.8768 19.9513L12.3989 17.1148ZM6.59087 20.2915C6.59102 20.2914 6.59116 20.2913 6.59131 20.2912L6.59116 20.2913L6.59087 20.2915Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default EmptyStar;
