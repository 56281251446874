import {
  GenerateIterableJwtMutation,
  GenerateIterableJwtMutationVariables,
} from "@/Apollo/schema";
import { MutationHookOptions, useMutation } from "@apollo/client";
import mutationGQL from "@/Apollo/mutation/generateIterableJwt.graphql";

export const useGenerateIterableJwt = (
  options?: MutationHookOptions<
    GenerateIterableJwtMutation,
    GenerateIterableJwtMutationVariables
  >
) => {
  return useMutation(mutationGQL, options);
};
