const Star = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11.9975 17.7483L17.0117 20.9245C17.3802 21.1586 17.8361 20.8125 17.7391 20.3748L16.4104 14.3889L20.8329 10.3678C21.153 10.0726 20.9881 9.51268 20.5517 9.47196L14.7228 8.95278L12.4437 3.31304C12.2788 2.89565 11.7163 2.89565 11.5514 3.31304L9.27222 8.95278L3.44338 9.47196C3.01664 9.51268 2.84206 10.0726 3.17182 10.3678L7.59437 14.3889L6.26566 20.3748C6.16868 20.8125 6.62451 21.1586 6.99305 20.9245L11.9975 17.7483Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Star;
