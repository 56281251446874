import { analyticsService } from "@/common/services/analytics/analytics.service";
import { MegaMenuItemData } from "@/common/contracts/mega-menu.contracts";
import { LinkButton } from "@/ui/next/LinkButton";
import {
  NavigationMenuItem,
  NavigationMenuLink,
} from "@radix-ui/react-navigation-menu";

export const MegaMenuSeeAllItem = ({ item }: { item?: MegaMenuItemData }) => {
  const handleClick = () => {
    /** Trigger `navigation_menu` event. */
    analyticsService.trackNavigationMenu(item?.label);
  };

  if (!item?.href) {
    return null;
  }

  return (
    <ul>
      <NavigationMenuItem className="flex">
        <NavigationMenuLink asChild>
          <LinkButton
            size="large"
            appearance="minimal"
            href={item.href}
            onClick={handleClick}
          >
            {item.label}
          </LinkButton>
        </NavigationMenuLink>
      </NavigationMenuItem>
    </ul>
  );
};
