import { useSavedSearches } from "@/Apollo/hooks/useSavedSearches";
import { useAuth } from "@/auth";
import { SavedSearchItem } from "@/common/contracts/search.contracts";
import { useLanguage } from "@/common/hooks/pages.hooks";
import { useMemo } from "react";

const NO_ITEMS: SavedSearchItem[] = [];

export const useHeaderSavedSearches = () => {
  const { isLoggedIn } = useAuth();
  const { language } = useLanguage();

  const { data, loading } = useSavedSearches({
    variables: {
      pageSize: 5,
      lang: language,
    },
    skip: !isLoggedIn,
  });

  const savedSearchItems = useMemo(() => {
    return data?.savedSearches.items ?? NO_ITEMS;
  }, [data?.savedSearches.items]);

  return { savedSearchItems, loading };
};
