import { SubscriptionHookOptions, useSubscription } from "@apollo/client";

// Apollo
import messageUpdatedGql from "../../../subscriptions/chat/messageUpdated.graphql";
import {
  MessageUpdatedSubscription,
  MessageUpdatedSubscriptionVariables,
} from "@whoppah/apollo/schema";

/**
 * Hook for subscribing to the `message updated` event.
 * @param options - Hook typing options.
 * @returns Subscription results.
 */
export const useMessageUpdatedSubscription = (
  options?: SubscriptionHookOptions<
    MessageUpdatedSubscription,
    MessageUpdatedSubscriptionVariables
  >
) => {
  return useSubscription(messageUpdatedGql, {
    ...options,
    skip: typeof window === "undefined",
  });
};
