import { SEARCH_ROUTE } from "@/common/static/routes.static";
import { useCallback, useEffect, useState } from "react";

const STORAGE_KEY = "search_history";

export const useHeaderSearchHistory = () => {
  const [history, setHistory] = useState<string[]>([]);

  const saveHistory = useCallback((newHistory: string[]) => {
    setHistory(newHistory);

    window.localStorage.setItem(
      STORAGE_KEY,
      JSON.stringify(newHistory.slice(0, 5))
    );
  }, []);

  const addItem = useCallback(
    (item: string) => {
      const normalizedItem = item.trim();

      const historyCopy = [...history];
      const prevFirstItem = historyCopy[0];
      const index = history.indexOf(normalizedItem);

      const url = `${SEARCH_ROUTE}?query=${encodeURIComponent(normalizedItem)}`;

      if (prevFirstItem && index !== -1) {
        /** Move this item to be the first. */
        historyCopy[index] = prevFirstItem;
        historyCopy[0] = normalizedItem;

        saveHistory(historyCopy);
        return url;
      }

      const newHistory = [normalizedItem, ...historyCopy];
      saveHistory(newHistory);

      return url;
    },
    [history, saveHistory]
  );

  /** Load history on mount. */
  useEffect(() => {
    const data = window.localStorage.getItem(STORAGE_KEY);

    if (data) {
      setHistory(JSON.parse(data));
    }
  }, []);

  return { historyItems: history, addItem };
};
