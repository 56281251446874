import dynamic from "next/dynamic";
import { useShowAppBanner } from "./useShowAppBanner";

const AppBannerInner = dynamic(
  () => import("./AppBannerInner").then(module => module.AppBannerInner),
  { ssr: false }
);

export const AppBanner = () => {
  const showBanner = useShowAppBanner();

  if (!showBanner) {
    return null;
  }

  return <AppBannerInner />;
};
