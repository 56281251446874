import { cn } from "@/common/utils/css.utils";
import Image from "@/components/common/Image";
import Link from "@whoppah/next/link";
import { MegaMenuItemData } from "@/common/contracts/mega-menu.contracts";
import { Flavor } from "@/ui/typography/Flavor";
import { KeyboardEvent } from "react";
import { analyticsService } from "@/common/services/analytics/analytics.service";

export const MegaMenuMobileItemIcon = ({
  item,
}: {
  item: MegaMenuItemData;
}) => {
  if (!item.icon) {
    return null;
  }

  return (
    <div className="relative h-4 w-4">
      <Image
        src={item.icon}
        alt={item.label}
        layout="fill"
        objectFit="contain"
        priority={true}
      />
    </div>
  );
};

const MegaMenuMobileCardItemLeftImage = ({
  item,
}: {
  item: MegaMenuItemData;
}) => {
  if (item.type !== "card_image_left") {
    return null;
  }

  if (item.image) {
    return (
      <div className="relative h-10 w-10 shrink-0">
        <Image src={item.image} alt={item.label} layout="fill" />
      </div>
    );
  }

  return <div className="h-10 w-10 shrink-0 bg-grey-200" />;
};

const MegaMenuMobileItemText = ({ item }: { item: MegaMenuItemData }) => {
  if (item.type === "card_image") {
    return null;
  }

  return <Flavor className="flex-1">{item.label}</Flavor>;
};

const MegaMenuMobileCardItemImage = ({ item }: { item: MegaMenuItemData }) => {
  if (item.type !== "card_image") {
    return null;
  }

  if (item.image) {
    return (
      <div className="relative h-10 w-full shrink-0">
        <Image
          src={item.image}
          alt={item.label}
          layout="fill"
          objectFit="contain"
        />
      </div>
    );
  }

  return <div className="h-10 w-full shrink-0 bg-grey-200" />;
};

const MegaMenuMobileCardItemRightImage = ({
  item,
}: {
  item: MegaMenuItemData;
}) => {
  if (item.type !== "card_image_right") {
    return null;
  }

  if (item.image) {
    return (
      <div className="relative h-10 w-10 shrink-0">
        <Image src={item.image} alt={item.label} layout="fill" />
      </div>
    );
  }

  return <div className="h-10 w-10 shrink-0 bg-grey-200" />;
};

type MegaMenuMobileItemProps = {
  item: MegaMenuItemData;
  onClick?: () => void;
};

export const MegaMenuMobileItem = ({
  item,
  onClick,
}: MegaMenuMobileItemProps) => {
  if (!item.href) {
    return null;
  }

  const handleClick = () => {
    onClick?.();

    /** Trigger `navigation_menu` event. */
    analyticsService.trackNavigationMenu(item.label);
  };

  const handleKeyDown = (evt: KeyboardEvent<HTMLAnchorElement>) => {
    if (evt.key !== "Enter") {
      return;
    }

    onClick?.();
  };

  return (
    <li>
      <Link href={item.href} passHref={true} prefetch={false}>
        <a
          role="link"
          tabIndex={0}
          className={cn(
            "text-title flex items-center space-x-2 text-left text-base focus:outline-none",
            item.type?.startsWith("card") &&
              "rounded-[0.25rem] border border-grey-300 p-2.5",
            (item.type === "all" || item.type === "links_title") &&
              "text-brand-500 underline"
          )}
          onClick={handleClick}
          onKeyDown={handleKeyDown}
        >
          <MegaMenuMobileItemIcon item={item} />
          <MegaMenuMobileCardItemLeftImage item={item} />
          <MegaMenuMobileItemText item={item} />
          <MegaMenuMobileCardItemImage item={item} />
          <MegaMenuMobileCardItemRightImage item={item} />
        </a>
      </Link>
    </li>
  );
};
