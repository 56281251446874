import { useState } from "react";
import Link from "next/link";
import { useTranslation } from "next-i18next";

// Components
import { Heading } from "@/ui/cva/Heading";

// Hooks
import useMe from "@/hooks/useMe";

// Static
import { ACCOUNT_VACATION_MODE_ROUTE } from "@/common/static/routes.static";

// Icons
import { Cross } from "@/assets/icons/24/Cross";
import { UmbrellaBeachOutlineIcon } from "@/assets/icons/24/UmbrellaBeachOutline";

export const VacationModeBanner = () => {
  const [visible, setVisible] = useState(true);

  const { t } = useTranslation();

  const { merchant } = useMe();
  const vacationMode = !!merchant?.vacationModeEnabledAt;

  if (!visible || !vacationMode) {
    return null;
  }

  return (
    <div className="fixed bottom-0 left-0 z-50 flex w-full items-start justify-between space-x-2 bg-brand-800 p-2 md:items-center md:justify-center">
      <div className="flex flex-col space-x-0 space-y-2 md:flex-row md:space-x-2 md:space-y-0">
        <div className="flex items-center space-x-2">
          <UmbrellaBeachOutlineIcon className="text-white" />
          <Heading as="h4" className="text-white">
            {t("common:vacation-mode-disclaimer")}
          </Heading>
        </div>
        <Link
          href={ACCOUNT_VACATION_MODE_ROUTE}
          className="m-0 text-flavour-500 underline"
        >
          {t("common:manage-your-settings-here")}
        </Link>
      </div>
      <button onClick={() => setVisible(false)}>
        <Cross className="text-white" />
      </button>
    </div>
  );
};
