import { onError } from "@apollo/client/link/error";

/**
 * Intercepts the HTTP GraphQL error response.
 *
 * If the error is a GraphQL error, it will log the error.
 *
 * If the error is a Network error, it will try to remove the Authorization
 * header and retry the request. This is useful for when the token is wrong
 * or expired.
 *
 * @returns {ApolloLink} The error link.
 */
export const createErrorLink = () => {
  return onError(
    // eslint-disable-next-line consistent-return
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) => {
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
        });

      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
        const oldHeaders = operation.getContext().headers;
        if (oldHeaders?.authorization) {
          operation.setContext({
            headers: {
              ...oldHeaders,
              authorization: undefined,
            },
          });

          return forward(operation);
        }
      }
    }
  );
};
