import React from "react";
import SparkIcon from "@whoppah/icons/24/Spark";
import HeartIcon from "@whoppah/icons/24/line/Heart";
import LoginIcon from "@whoppah/icons/24/Login";
import CreateAdIcon from "@whoppah/icons/24/CreateAd";
import ChevronRightIcon from "@whoppah/icons/24/ChevronRight";
import ComingSoonIcon from "@whoppah/icons/24/ComingSoon";
import FurnitureIcon from "@whoppah/icons/24/product/Furniture2";
import LightingIcon from "@whoppah/icons/24/Lighting";
import MaintenanceIcon from "@whoppah/icons/24/Maintenance";
import ArtIcon from "@whoppah/icons/24/Art";
import DecorationIcon from "@whoppah/icons/24/Decoration";
import BooksIcon from "@whoppah/icons/24/Books";
import { IconProps } from "@/common/contracts/icon.contracts";
import { ProfileOutlined as ProfileOutlinedIcon } from "@whoppah/icons/24/ProfileOutlined";
import ContactIcon from "@whoppah/icons/24/Contact";
import CartIcon from "@whoppah/icons/24/Cart";
import TablewareIcon from "@whoppah/icons/24/Tableware";
import { Gift as GiftIcon } from "@whoppah/icons/24/Gift";

const NewInIcon = () => {
  return <div className="h-2 w-2 rounded-full bg-flavour-500" />;
};

const ICON_MAP: Record<string, (props: IconProps) => JSX.Element> = {
  eye: ComingSoonIcon,
  furniture: FurnitureIcon,
  lighting: LightingIcon,
  art: ArtIcon,
  decoration: DecorationIcon,
  books: BooksIcon,
  spark: SparkIcon,
  newIn: NewInIcon,
  heart: HeartIcon,
  plus: CreateAdIcon,
  login: LoginIcon,
  chevronRight: ChevronRightIcon,
  profile: ProfileOutlinedIcon,
  chat: ContactIcon,
  cart: CartIcon,
  tableware: TablewareIcon,
  maintenance: MaintenanceIcon,
  gift: GiftIcon,
};

type HeaderMenuIconsProps = {
  icon?: string;
  className?: string;
};

const HeaderMenuIcon = ({ icon, className }: HeaderMenuIconsProps) => {
  if (icon) {
    const IconComponent = ICON_MAP[icon];

    if (IconComponent) {
      return <IconComponent className={className} />;
    }
  }

  return null;
};

export default HeaderMenuIcon;
