import { createContext } from "react";
import { UrlObject } from "url";

export type AuthContextType = {
  isReady: boolean;
  isLoggedIn: boolean;
  token: string | null;
  setToken: (token: string) => void;
  reset: () => void;
  userId: string | null;
  setUserId: (userId: string) => void;
  deviceId: string;
  setDeviceId: (deviceId: string) => void;
  logout: (options?: {
    noRedirect?: boolean;
    pathname?: string;
    query?: UrlObject["query"];
  }) => void;
};

export const AuthContext = createContext<AuthContextType>({
  isReady: false,
  isLoggedIn: false,
  token: null,
  setToken: (_token: string) => {},
  reset: () => {},
  userId: null,
  setUserId: (_userId: string) => {},
  deviceId: "",
  setDeviceId: (_deviceId: string) => {},
  logout: (_options = {}) => {},
});
