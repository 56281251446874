import { authStore } from "@/auth";
import { AnalyticsProduct } from "@/common/contracts/analytics.contracts";
import { PATHNAME_PAGETYPE_MAP_ARRAY } from "@/common/static/analytics.static";
import { isNil } from "@/common/utils/value.utils";
import { getPriceInfo } from "@/common/utils/product.utils";
import { initializeApollo } from "../../../lib/apollo";
import meGQL from "@/Apollo/query/me.graphql";
import { MeQuery, MeQueryVariables } from "@/Apollo/schema";

/**
 * Extracts event item data from  {@link AnalyticsProduct}.
 *
 * @param product {@link AnalyticsProduct} to convert.
 */
export const productToAnalyticsItem = (product: AnalyticsProduct) => {
  const priceInfo = getPriceInfo(product);
  const images = product.images ?? [];

  /** We actually need reverse order of categories for analytics. */
  const categories = product.categories?.filter(Boolean).reverse();

  return {
    item_id: product.id,
    item_name: product.title,
    discount: priceInfo.discountAmount,
    item_brand: product.brand?.slug,
    item_category: categories?.[0]?.slug,
    item_category2: categories?.[1]?.slug,
    item_category3: categories?.[2]?.slug,
    item_views: product.view_count,
    item_favorites: product.favorite_count,
    // item_variant:
    price: product.auction?.buy_now_price?.amount,
    quantity: product.number_of_items ?? 1,
    item_country_of_seller: product.merchant?.addresses?.[0]?.country,
    // item_fast_lane_listing:
    item_showroom: product.is_in_showroom,
    item_number_of_images: images.length,
    item_status: product.status,
    item_bid_option: product.auction?.allow_bid,
    item_seller_review: !isNil(product.merchant?.rating),
    item_publish_date: product.auction?.start_date
      ? new Date(product.auction.start_date)
      : undefined,
  };
};

/**
 * Returns textual page identifier (page type) used by analytics.
 */
export const getPageType = () => {
  if (typeof window === "undefined") {
    return;
  }

  /** Replace occurrence of language identifier (`de` or `nl`) with `/`. */
  const pathName = window.location.pathname.replace(/\/nl|\/de/, "/");

  /** We try to find the entry that matches the `pathName`.
   * If we don't, we fall back * to "catch-all" entry - "Search".
   */
  const entry = PATHNAME_PAGETYPE_MAP_ARRAY.find(entry => {
    if (entry.exact) {
      return pathName === entry.pathName;
    }

    if (Array.isArray(entry.pathName)) {
      return entry.pathName.some(path => pathName.includes(path));
    }

    return pathName.includes(entry.pathName) || entry.pathName === "*";
  });

  return entry?.pageType;
};

/**
 * Returns the app type (android or ios).
 * @returns {string | undefined} The app type.
 */
export const getAppType = () => {
  if (typeof window === "undefined") {
    return;
  }
  const userAgent = window.navigator.userAgent;

  if (/Android/.test(userAgent)) {
    return "android";
  } else if (/iPhone|iPad|iPod/.test(userAgent)) {
    return "ios";
  }
};

/**
 * Tries to extract user info of the currently logged-in user and return it as `up` object.
 */
export const getUserInfo = async () => {
  const client = initializeApollo();

  const getMeData = async () => {
    const userId = authStore.getState().userId;

    if (!userId) {
      return;
    }

    try {
      /** Data will be cached on subsequent calls. */
      const { data } = await client.query<MeQuery, MeQueryVariables>({
        query: meGQL,
      });

      return data.me;
    } catch (_ex) {
      /** User is not logged-in or some other error happened: swallow it. */
    }
  };

  const getObjectOrNullIfEmpty = (object: Record<string, unknown>) => {
    if (Object.keys(object).length === 0) {
      return undefined;
    }

    return object;
  };

  const me = await getMeData();
  const up: Record<string, unknown> = {};

  // If no `me` is present, return the data gathered so far.
  if (!me) {
    return getObjectOrNullIfEmpty(up);
  }

  up.email = me.email ?? null;
  up.first_name = me.given_name;
  up.last_name = me.family_name;
  up.dob = me.dob;

  const merchant = me.merchants[0];

  // If no `merchant` is present, return the data gathered so far.
  if (!merchant) {
    return getObjectOrNullIfEmpty(up);
  }

  up.phone_number = merchant.phone ?? null;

  const address = merchant.addresses[0];

  // If no `address` is present, return the data gathered so far.
  if (!address) {
    return getObjectOrNullIfEmpty(up);
  }

  up.street = address.line1;
  up.city = address.city;
  up.postal_code = address.postal_code;
  up.country = address.country;

  return getObjectOrNullIfEmpty(up);
};

// with the introducing of brand promise blocks we want to know if the users are clicking on the block, so we can track it.
// we use it to track analytic events.
export const captureMisclick = (func: () => void) => {
  return {
    onClick: () => {
      func();
    },
  };
};
