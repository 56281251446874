import { NavigationMenuViewport } from "@radix-ui/react-navigation-menu";

export const MegaMenuViewport = () => {
  return (
    <div className="absolute left-0 right-0 top-full flex">
      <NavigationMenuViewport className="data-[state=open]:animate-menu-show data-[state=closed]:animate-menu-hide peer relative h-[var(--radix-navigation-menu-viewport-height)] w-full overflow-hidden bg-white transition-[height] duration-300" />
      {/* Backdrop */}
      <div className="absolute left-0 right-0 top-full hidden h-screen opacity-40 peer-data-[state=open]:block peer-data-[state=open]:bg-black" />
    </div>
  );
};
