import React from "react";
import CartIcon from "@whoppah/icons/24/Cart";
import Link from "@whoppah/next/link";
import { useTranslation } from "next-i18next";
import useCartHasItems from "@whoppah/components/shared/hooks/useCartHasItems";
import { useReactiveVar } from "@apollo/client";
import { CART_OVERVIEW_URL } from "@/common/static/checkout/checkout.static";

/**
 * Header cart action that navigates user to his/her cart.
 */
const HeaderCart = () => {
  const { t } = useTranslation();
  const { hasItemsVar } = useCartHasItems();
  const hasItems = useReactiveVar(hasItemsVar);

  return (
    <Link href={CART_OVERVIEW_URL} prefetch={false} passHref>
      <a className="group flex cursor-pointer flex-col items-center md:space-y-1">
        <div className="relative">
          <CartIcon />
          {hasItems && (
            <div className="absolute right-0 top-0 flex h-2 w-2 items-center justify-center rounded-full bg-attention-500" />
          )}
        </div>
        <span className="hidden font-body text-sm group-hover:text-attention-500 lg:inline">
          {t("header:nav-cart")}
        </span>
      </a>
    </Link>
  );
};

export default HeaderCart;
