import { Cross as CrossIcon } from "@/assets/icons/24/Cross";
import { ComponentProps } from "react";

export const MegaMenuMobileClose = (props: ComponentProps<"button">) => {
  return (
    <button {...props}>
      <CrossIcon className="text-black" />
    </button>
  );
};
