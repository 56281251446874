import { ElementType, useMemo } from "react";
import { useTranslation } from "next-i18next";
import MarkdownToJSX, {
  MarkdownToJSX as MarkdownNamespace,
} from "markdown-to-jsx";
import { TOptions } from "i18next";
import { Heading } from "@/ui/cva/Heading";

type MarkdownProps = {
  className?: string;
  wrapper?: ElementType;
  overrides?: MarkdownNamespace.Overrides;
  children: string;
};

const Markdown = ({
  className,
  wrapper,
  children,
  overrides,
}: MarkdownProps) => {
  return (
    <MarkdownToJSX
      className={className}
      options={{
        wrapper,
        forceWrapper: true,
        overrides: {
          h1: {
            component: Heading,
            props: {
              as: "span",
              size: "h1",
            },
          },
          h2: {
            component: Heading,
            props: {
              as: "span",
              size: "h2",
            },
          },
          h3: {
            component: Heading,
            props: {
              as: "span",
              size: "h3",
            },
          },
          h4: {
            component: Heading,
            props: {
              as: "span",
              size: "h4",
            },
          },
          h5: {
            component: Heading,
            props: {
              as: "span",
              size: "h5",
            },
          },
          h6: {
            component: Heading,
            props: {
              as: "span",
              size: "h6",
            },
          },
          ol: {
            props: {
              className: "list-decimal pl-5",
            },
          },
          ul: {
            props: {
              className: "list-disc pl-5",
            },
          },
          a: {
            props: {
              className: "text-brand-500 hover:underline",
            },
          },
          p: {
            props: {
              className: "whitespace-pre-wrap",
            },
          },
          em: {
            props: {
              className: "not-italic text-brand-500",
            },
          },
          ...overrides,
        },
      }}
    >
      {children}
    </MarkdownToJSX>
  );
};

export const MarkdownText = ({ wrapper = "div", ...props }: MarkdownProps) => {
  return <Markdown {...props} wrapper={wrapper} />;
};

export type LokaliseMarkdownTextProps = Omit<MarkdownProps, "children"> & {
  lokaliseKey: string;
  options?: TOptions;
};

export const LokaliseMarkdownText = ({
  lokaliseKey,
  options,
  wrapper = "div",
  ...props
}: LokaliseMarkdownTextProps) => {
  const { t } = useTranslation();

  const markdownContent = useMemo(() => {
    /**
     * Multiline text from Lokalise is downloaded as single entry inside a json (namespace) file.
     * This entry contains \\n string for each newline and because of that we are replacing all
     * occurrences of \\n string with \n (newline character)
     */
    return t(lokaliseKey, options).replaceAll("\\n", "\n");
  }, [lokaliseKey, options, t]);

  return (
    <Markdown {...props} wrapper={wrapper}>
      {markdownContent}
    </Markdown>
  );
};

export default LokaliseMarkdownText;
