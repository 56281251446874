import Header from "@whoppah/components/Header/Header";
import React, { PropsWithChildren } from "react";
import { Footer } from "components/Footer/Footer";
import clsx from "clsx";

type MainLayoutProps = {
  header?: React.ReactElement;
  headerClassName?: string;
  footer?: React.ReactElement;
  hideSearch?: boolean;
  hideFooter?: boolean;
  fullWidthContainer?: boolean;
};

const MainLayout = ({
  children,
  header = <Header />,
  headerClassName = undefined,
  footer = <Footer />,
  hideSearch = false,
  hideFooter = false,
  fullWidthContainer = false,
}: PropsWithChildren<MainLayoutProps>) => {
  return (
    <>
      <div className="flex min-h-screen flex-col">
        {React.cloneElement(header, {
          focused: hideSearch,
          className: clsx(header.props.className, headerClassName),
        })}
        <div
          className={clsx(
            "relative flex w-full flex-1 flex-col",
            !fullWidthContainer && "mx-auto xl:container"
          )}
        >
          {children}
        </div>
      </div>
      {!hideFooter ? footer : null}
    </>
  );
};

export default MainLayout;
