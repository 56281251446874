import { Locale } from "next/router";
import { createContext, useContext } from "react";

interface HeaderLanguageSwitcherContextValue {
  urls: Partial<Record<Locale, string>>;
}

const HeaderLanguageSwitcherContext =
  createContext<HeaderLanguageSwitcherContextValue>({ urls: {} });

export const useHeaderLanguageSwitcherContext = () => {
  return useContext(HeaderLanguageSwitcherContext);
};

export const HeaderLanguageSwitcherProvider =
  HeaderLanguageSwitcherContext.Provider;
