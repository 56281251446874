import { useRouter } from "next/router";
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { AuthContext, AuthContextType } from "@/auth/context";
import { useAuthStore } from "@/auth/store";

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const router = useRouter();
  const {
    isReady,
    token,
    setToken,
    reset,
    userId,
    setUserId,
    deviceId,
    setDeviceId,
    setReady,
  } = useAuthStore();

  /**
   * Set the ready state to true once the component mounts.
   * This is used to prevent the app from rendering before the auth state is determined.
   * @returns void
   */
  useEffect(() => {
    setReady();
  }, [setReady]);

  /**
   * Logout should remove token from authStore, clear apollo cache and redirect to home page if pathname isn't provided.
   * @param noRedirect - if true, redirect will be skipped
   * @param pathname - if provided the user will be redirected to this route after logout.
   * @param query - URL query object.
   * @returns void
   */
  const logout = useCallback<AuthContextType["logout"]>(
    ({ noRedirect, pathname, query } = {}) => {
      reset();

      if (noRedirect) {
        return;
      }

      router.push({
        pathname: pathname ?? "/",
        query,
      });
    },
    [router, reset]
  );

  /**
   * Memoize the context value to prevent unnecessary re-renders
   * @returns AuthContextType
   */
  const value = useMemo(
    () => ({
      isReady,
      isLoggedIn: !!token,
      token,
      setToken,
      reset,
      userId,
      setUserId,
      deviceId,
      setDeviceId,
      logout,
    }),
    [
      isReady,
      logout,
      reset,
      setToken,
      setUserId,
      token,
      userId,
      deviceId,
      setDeviceId,
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
