import { useRouter } from "next/router";
import { useEffect, useState } from "react";

// const scrollToTop = () => {
//   window.scrollTo({
//     top: 0,
//     behavior: "smooth",
//   });
// };

const useSmoothScrollOnNavigation = () => {
  const [loading, setLoading] = useState(false);
  const [navigating, setNavigating] = useState(false);
  const router = useRouter();

  useEffect(() => {
    if (navigating) {
      setLoading(true);
      // scrollToTop();
    } else {
      setLoading(false);
    }
  }, [navigating]);

  useEffect(() => {
    const start = () => setNavigating(true);
    const end = () => setNavigating(false);

    router.events.on("routeChangeStart", start);
    router.events.on("routeChangeComplete", end);
    router.events.on("routeChangeError", end);
    return () => {
      router.events.off("routeChangeStart", start);
      router.events.off("routeChangeComplete", end);
      router.events.off("routeChangeError", end);
    };
  }, [router.events]);

  return { showLoading: loading };
};

export default useSmoothScrollOnNavigation;
