import { cn } from "@/common/utils/css.utils";
import {
  MegaMenuItemData,
  MengaMenuSubMenuType,
} from "@/common/contracts/mega-menu.contracts";
import { MegaMenuMobileBack } from "@/components/mega-menu/mobile/MegaMenuMobileBack";
import { MegaMenuMobileItemList } from "@/components/mega-menu/mobile/MegaMenuMobileItemList";
import {
  Dialog,
  DialogTrigger,
  DialogPortal,
  DialogOverlay,
  DialogContent,
} from "@radix-ui/react-dialog";

import { MegaMenuMobileClose } from "@/components/mega-menu/mobile/MegaMenuMobileClose";
import { useMemo, useState } from "react";
import { Heading } from "@/ui/cva/Heading";

type MegaMenuMobilePanelProps = {
  trigger: JSX.Element;
  title: string;
  type?: MengaMenuSubMenuType | null;
  items?: MegaMenuItemData[];
  hasBack?: boolean;
  onClose?: () => void;
};

export const MegaMenuMobilePanel = ({
  trigger,
  type,
  title,
  items,
  hasBack,
  onClose,
}: MegaMenuMobilePanelProps) => {
  /**
   * When having nested dialogs and the closing of the top-most is initiated,
   * animation is only shown for top-most dialog; once the animation has finished
   * all child dialogs are just unmounted.
   *
   * Because of this, we hide all descendant dialogs when user clicks on "close all"
   * button so that the close animation is correctly shown.
   */
  const [isContentHidden, setIsContentHidden] = useState(false);

  const mobileItems = useMemo<MegaMenuItemData[] | undefined>(() => {
    if (type === "cards") {
      return items?.map(item =>
        item.type === "all" ? item : { ...item, type: item.type ?? "card_text" }
      );
    }

    if (type === "mixed") {
      return items?.filter(
        item => item.type === "all" || item.type?.startsWith("card")
      );
    }

    return items;
  }, [items, type]);

  const handleClose = () => {
    if (hasBack) {
      setIsContentHidden(true);
    }

    onClose?.();
  };

  return (
    <Dialog>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogPortal>
        <DialogOverlay />
        <DialogContent
          className={cn(
            "fixed inset-0 z-[1000] flex flex-col space-y-6 bg-white p-4 data-[state=closed]:animate-menu-mobile-hide data-[state=open]:animate-menu-mobile-show",
            isContentHidden && "invisible"
          )}
        >
          <div className="flex items-center space-x-2 border-b border-b-grey-300 pb-4">
            {hasBack && <MegaMenuMobileBack />}
            <Heading as="span" size="h4" className="flex-1">
              {title}
            </Heading>
            <MegaMenuMobileClose onClick={handleClose} />
          </div>
          <div className="flex flex-col space-y-6 overflow-y-auto overflow-x-hidden">
            <MegaMenuMobileItemList
              items={mobileItems}
              type={type}
              onItemClick={handleClose}
            />
          </div>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};
