import { IconProps } from "@/common/contracts/icon.contracts";

export const Loading = ({ className, ...rest }: IconProps) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5 12C21.5 7.02944 17.4706 3 12.5 3C7.52944 3 3.5 7.02944 3.5 12C3.5 16.9706 7.52944 21 12.5 21C17.4706 21 21.5 16.9706 21.5 12ZM19.5 12C19.5 8.13401 16.366 5 12.5 5C8.63401 5 5.5 8.13401 5.5 12C5.5 15.866 8.63401 19 12.5 19C16.366 19 19.5 15.866 19.5 12Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9596 4.84511C16.3911 3.64832 14.4729 3.00005 12.5 3.00005L12.5 5.00005C14.035 5.00005 15.5274 5.50459 16.7475 6.436C17.9676 7.36741 18.8477 8.67404 19.2524 10.1547C19.657 11.6354 19.5638 13.208 18.987 14.6305C18.4109 16.0512 17.3844 17.2434 16.0652 18.0241L17.0839 19.7452C18.7817 18.7404 20.1023 17.2055 20.8425 15.3768C21.5827 13.548 21.7015 11.5267 21.1806 9.62378C20.6597 7.7209 19.528 6.0419 17.9596 4.84511Z"
        fill="currentColor"
      />
    </svg>
  );
};
