import { Language } from "@/Apollo/schema";
import { useMakeInteractive } from "@/common/hooks/html.hooks";
import { useLanguage } from "@/common/hooks/pages.hooks";
import { analyticsService } from "@/common/services/analytics/analytics.service";
import { cn } from "@/common/utils/css.utils";
import Image from "@/components/common/Image";
import AppleDownloadDEImage from "@/common/components/assets/app-download-block-apple.de.png";
import AppleDownloadENImage from "@/common/components/assets/app-download-block-apple.en.png";
import AppleDownloadFRImage from "@/common/components/assets/app-download-block-apple.fr.png";
import AppleDownloadNLImage from "@/common/components/assets/app-download-block-apple.nl.png";
import AndroidDownloadENImage from "@/common/components/assets/app-download-block-android.en.png";
import AndroidDownloadNLImage from "@/common/components/assets/app-download-block-android.nl.png";
import AndroidDownloadDEImage from "@/common/components/assets/app-download-block-android.de.png";
import AndroidDownloadFRImage from "@/common/components/assets/app-download-block-android.fr.png";
import Link from "@whoppah/next/link";
import { StaticImageData } from "next/image";

const IOS_BUTTONS: Record<Language, StaticImageData> = {
  [Language.En]: AppleDownloadENImage,
  [Language.Nl]: AppleDownloadNLImage,
  [Language.De]: AppleDownloadDEImage,
  [Language.Fr]: AppleDownloadFRImage,
};

const ANDROID_BUTTONS: Record<Language, StaticImageData> = {
  [Language.En]: AndroidDownloadENImage,
  [Language.Nl]: AndroidDownloadNLImage,
  [Language.De]: AndroidDownloadDEImage,
  [Language.Fr]: AndroidDownloadFRImage,
};

export type Device = "ios" | "android";

type HomepageAppDownloadButtonProps = {
  href: string;
  device: Device;
  analyticsSource?: string;
  className?: string;
  onClick?: (device: Device) => void;
};

export const AppDownloadButton = ({
  href,
  device,
  analyticsSource,
  className,
  onClick,
}: HomepageAppDownloadButtonProps) => {
  const { language } = useLanguage();
  const { makeInteractive } = useMakeInteractive("a");

  const imageSrc =
    device === "ios" ? IOS_BUTTONS[language] : ANDROID_BUTTONS[language];

  const handleDownloadClick = (device: Device) => () => {
    analyticsService.trackClickedAppInstall(device, analyticsSource);
    onClick?.(device);
  };

  return (
    <div className={cn("relative h-12 w-36 md:h-8 md:w-24", className)}>
      <Link href={href} passHref>
        <a
          target="_blank"
          rel="noopener noreferrer nofollow"
          {...makeInteractive(handleDownloadClick(device), false)}
        >
          <Image src={imageSrc} layout="fill" alt={`${device} download`} />
        </a>
      </Link>
    </div>
  );
};
