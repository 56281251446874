import Link from "@whoppah/next/link";

type HeaderLogoProps = {
  logo: JSX.Element;
};

export const HeaderLogo = ({ logo }: HeaderLogoProps) => {
  return (
    <Link href="/" passHref>
      <a>{logo}</a>
    </Link>
  );
};
