import { SavedSearchItem } from "@/common/contracts/search.contracts";
import { SEARCH_ROUTE } from "@/common/static/routes.static";
import { getSearchFilterData } from "@/components/search/utils/search.utils";
import { kebabCase } from "lodash-es";

export const getUrlQueryFromSaveSearchItem = (item: SavedSearchItem) => {
  const url: string[] = [];

  if (item.query) {
    url.push(`query=${item.query}`);
  }

  if (item.filters?.length) {
    const filterData = getSearchFilterData(item.filters);
    const filterDataKeys = Object.keys(filterData);

    for (const key of filterDataKeys) {
      if (!filterData[key]) {
        continue;
      }

      const value = Array.isArray(filterData[key])
        ? filterData[key].map(encodeURIComponent).join(",")
        : encodeURIComponent(filterData[key]);

      if (!value) {
        continue;
      }

      /** Normalize key by converting it to kebabCase + map it to corrent name. */
      /** TODO: Refactor this and `getSearchFilterData` into a single / cleaner utility. */
      const normalizeKey = (key: string) => {
        const normalizedKey = kebabCase(key);

        if (normalizedKey === "categories") {
          return "category";
        }

        if (normalizedKey === "countries") {
          return "country";
        }

        if (normalizedKey === "brands") {
          return "brand";
        }

        if (normalizedKey === "colors") {
          return "color";
        }

        if (normalizedKey === "styles") {
          return "style";
        }

        if (normalizedKey === "materials") {
          return "material";
        }

        return normalizedKey;
      };

      url.push(`${normalizeKey(key)}=${value}`);
    }
  }

  return [SEARCH_ROUTE, url.join("&")].join("?");
};
