import { IconProps } from "@/common/contracts/icon.contracts";

export const QRCode = (props: IconProps) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_6919_7127)">
        <path
          d="M3 11.0178H5V13.0178H3V11.0178ZM11 5.01782H13V9.01782H11V5.01782ZM9 11.0178H13V15.0178H11V13.0178H9V11.0178ZM15 11.0178H17V13.0178H19V11.0178H21V13.0178H19V15.0178H21V19.0178H19V21.0178H17V19.0178H13V21.0178H11V17.0178H15V15.0178H17V13.0178H15V11.0178ZM19 19.0178V15.0178H17V19.0178H19ZM15 3.01782H21V9.01782H15V3.01782ZM17 5.01782V7.01782H19V5.01782H17ZM3 3.01782H9V9.01782H3V3.01782ZM5 5.01782V7.01782H7V5.01782H5ZM3 15.0178H9V21.0178H3V15.0178ZM5 17.0178V19.0178H7V17.0178H5Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_6919_7127">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.0178223)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
