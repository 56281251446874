import HeaderLanguageSwitcher from "@/components/Header/HeaderLanguageSwitcher";
import { MegaMenuHeaderItemList } from "@/components/mega-menu/MegaMenuHeaderItemList";
import { useMegaMenuData } from "@/components/mega-menu/hooks/useMegaMenuData";
import { MegaMenuViewport } from "@/components/mega-menu/MegaMenuViewport";
import { NavigationMenu } from "@radix-ui/react-navigation-menu";

/**
 * Mega menu "main" entries.
 */
export const MegaMenu = () => {
  const { data } = useMegaMenuData();

  return (
    <NavigationMenu className="flex items-end justify-between">
      <MegaMenuHeaderItemList items={data?.items} />
      <HeaderLanguageSwitcher />
      <MegaMenuViewport />
    </NavigationMenu>
  );
};
