import { useQuerySuggestions } from "@/Apollo/hooks/useQuerySuggestions";

const NO_ITEMS: string[] = [];

export const useHeaderSearchSuggestions = (query?: string) => {
  const { data, loading } = useQuerySuggestions({
    variables: {
      query,
    },
    skip: !query,
  });

  const suggestionItems = data?.querySuggestions ?? NO_ITEMS;

  return { suggestionItems, loading };
};
