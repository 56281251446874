import { useRouter } from "next/router";
import { useEffect } from "react";

import { analyticsService } from "@/common/services/analytics/analytics.service";
import { STATSIG_STATE_PROP_NAME } from "@/experiments/experiment.static";
import {
  useExperiment as useStatsigExperiment,
  useGate as useStatsigGate,
} from "statsig-react";
import { useRunOnce } from "@/common/hooks/general.hooks";

// This is a helper hook to extract the experiment values from the page props
// and should be used in the _app.tsx to pass the experiment values to the
// ExperimentsProvider.
export const useExperimentValues = (pageProps: {
  props?: Record<string, unknown>;
  [key: string]: unknown;
}) => {
  return pageProps[STATSIG_STATE_PROP_NAME];
};

const previousTrigger = {
  url: "",
  experiment: "",
};

// This is a wrapper around the statsig hook to type the experiment name.
export const useExperiment = (experimentName: string) => {
  const { isLoading, config } = useStatsigExperiment(experimentName);
  const { when } = useRunOnce();
  const router = useRouter();

  const isExperimentActive = !!config.getIsExperimentActive();

  //we "reset" the previousPageTriggerUrl when the route changes
  useEffect(() => {
    const handleRouteChangeStart = () => {
      previousTrigger.url = "";
      previousTrigger.experiment = "";
    };

    router.events.on("routeChangeStart", handleRouteChangeStart);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
    };
  }, [router]);

  // This is the hack to get the correct group name. This is a temporary solution, the group name is not
  // present when the values are loaded from the server. Workaround is to use fallback group name.
  // TODO: remove this hack once the Statsig library is updated
  when(() => !isLoading && isExperimentActive).runOnce(() => {
    const fallbackGroupName = config.get<string>("group_name", "Control");
    const groupName = config.getGroupName() ?? fallbackGroupName;
    const experimentName = config.getName();

    // This part of code guarantees that the exposure event is only triggered once per page,
    // even if we use the same experiment in multiple components on the same page.
    if (
      previousTrigger.url !== router.pathname ||
      previousTrigger.experiment !== experimentName
    ) {
      previousTrigger.url = router.pathname;
      previousTrigger.experiment = experimentName;

      analyticsService.trackExperimentExposure(experimentName, groupName);
    }
  });

  return { isLoading, config };
};

// This is a wrapper around the statsig hook to type the gate name.
export const useGate = (gateName: string) => {
  return useStatsigGate(gateName);
};

// Experiment hook for the test_experiment.
// This serves as an example of how to write a custom hook for an
// experiment.
export const useTestExperiment = () => {
  const { isLoading, config } = useExperiment("test_experiment");

  return {
    isLoading,
    params: {
      version: config.get<string>("version", "default"),
    },
  };
};
