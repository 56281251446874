import HeaderAccountMenuItem from "@whoppah/components/Header/HeaderAccountMenuItem";
import { HeaderAccountMenuJsonItem } from "@/common/contracts/header.contracts";
import React, { useMemo } from "react";
import { useAuth } from "@/auth";

type HeaderAccountMenuProps = {
  items: HeaderAccountMenuJsonItem[];
  className?: string;
  onItemClick: () => void;
  closeMenu: () => void;
};

/**
 * Header account drop-down menu.
 */
const HeaderAccountMenu = ({
  className = undefined,
  items,
  onItemClick,
  closeMenu,
}: HeaderAccountMenuProps) => {
  const { isLoggedIn } = useAuth();

  const filteredItems = useMemo(() => {
    return items.filter(item => {
      if (item.auth) {
        if (isLoggedIn) {
          return item.auth === "user";
        }
        if (!isLoggedIn) {
          return item.auth === "guest";
        }
      }

      return true;
    });
  }, [isLoggedIn, items]);

  return (
    <ul className={className}>
      {filteredItems.map(item => (
        <HeaderAccountMenuItem
          key={item.label}
          item={item}
          onItemClick={onItemClick}
          onCloseMenu={closeMenu}
        />
      ))}
    </ul>
  );
};

export default HeaderAccountMenu;
