import { IconProps } from "@/common/contracts/icon.contracts";

export const ArrowRight = (props: IconProps) => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Icon/Navigation/Arrow right">
        <path
          id="icon/navigation/arrow_forward_24px"
          d="M4.27009 9.79025H12.8721L9.11399 13.6467C8.81365 13.9549 8.81365 14.4607 9.11399 14.7689C9.41433 15.0771 9.89949 15.0771 10.1998 14.7689L15.2747 9.56107C15.5751 9.25288 15.5751 8.75502 15.2747 8.44682L10.2075 3.23114C10.0636 3.08316 9.86831 3 9.66461 3C9.4609 3 9.26557 3.08316 9.12169 3.23114C8.82135 3.53934 8.82135 4.0372 9.12169 4.3454L12.8721 8.20974H4.27009C3.84654 8.20974 3.5 8.56535 3.5 8.99999C3.5 9.43463 3.84654 9.79025 4.27009 9.79025Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
