import { ImageProps as NextImageProps } from "next/dist/client/legacy/image";
import { StaticImport } from "next/dist/shared/lib/get-img-props";
import { StaticImageData } from "next/image";
import NextImage from "next/legacy/image";
import placeholder from "assets/placeholder.jpeg";

const STRAPI_IMAGE_ROUTE = "https://storage.googleapis.com/whoppah-strapi";

const GOOGLE_IMAGE_ROUTES = [
  "https://storage.googleapis.com",
  "https://images.whoppah.com",
  "https://testing-images.whoppah.com",
];

const CDN_IMAGE_ROUTES_MAP = {
  source: "storage.googleapis.com/whoppah-production",
  target: "images.whoppah.com",
};

type ImageSrc = string | StaticImport | null;

const isStaticImageData = (src?: ImageSrc): src is StaticImageData => {
  type PossibleStaticImageData = StaticImageData | undefined;
  return typeof (src as PossibleStaticImageData)?.src === "string";
};

const isSVG = (src?: ImageSrc) => {
  if (typeof src === "string") {
    return src.endsWith(".svg");
  }

  if (isStaticImageData(src)) {
    return src.src.endsWith(".svg");
  }

  return false;
};

const isStrapiImage = (src?: ImageSrc) => {
  if (typeof src !== "string") {
    return false;
  }

  return src.startsWith(STRAPI_IMAGE_ROUTE);
};

const isGoogleStorageImage = (src?: ImageSrc) => {
  if (typeof src !== "string") {
    return false;
  }

  return GOOGLE_IMAGE_ROUTES.some(route => src.startsWith(route));
};

const isProductImage = (src?: ImageSrc) => {
  return isGoogleStorageImage(src) && !isStrapiImage(src);
};

const getCdnUrl = (src?: ImageSrc) => {
  if (typeof src !== "string") {
    return src;
  }

  return src.replace(CDN_IMAGE_ROUTES_MAP.source, CDN_IMAGE_ROUTES_MAP.target);
};

type ImageProps = Omit<NextImageProps, "src"> & {
  src?: ImageSrc;
  forceOptimize?: boolean;
};

const Image = ({ src, forceOptimize, ...props }: ImageProps) => {
  return (
    <NextImage
      unoptimized={!forceOptimize && (isSVG(src) || isProductImage(src))}
      src={getCdnUrl(src) ?? placeholder}
      placeholder="empty"
      {...props}
    />
  );
};

export default Image;
