import { IconProps } from "@/common/contracts/icon.contracts";

const Heart = ({ className }: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0003 5.13249C13.7596 3.03127 17.0183 2.17446 19.6573 4.01048C21.0568 4.98968 21.9365 6.6421 21.9964 8.37611C22.1264 12.3337 18.6977 15.506 13.4497 20.3612L13.3398 20.4632C12.5801 21.1772 11.4105 21.1772 10.6508 20.4734L10.5508 20.3816L10.4905 20.3256C5.27649 15.4918 1.86471 12.3287 2.00412 8.38631C2.06409 6.6421 2.94376 4.98968 4.34322 4.01048C6.9822 2.16426 10.241 3.03127 12.0003 5.13249ZM12.001 18.9638L12.101 18.8618C16.8592 14.4656 19.998 11.5585 19.998 8.61072C19.998 6.5707 18.4985 5.04069 16.4993 5.04069C14.9599 5.04069 13.4605 6.05049 12.9407 7.44791H11.0714C10.5416 6.05049 9.04218 5.04069 7.50277 5.04069C5.50354 5.04069 4.00412 6.5707 4.00412 8.61072C4.00412 11.5585 7.14291 14.4656 11.9011 18.8618L12.001 18.9638Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Heart;
