import dynamic from "next/dynamic";
import useMe from "@/hooks/useMe";

const InAppNotifications = dynamic(
  () =>
    import("@/components/notifications/InAppNotifications").then(
      mod => mod.InAppNotifications
    ),
  { ssr: false }
);

export const HeaderNotifications = () => {
  const { merchant } = useMe();

  if (!merchant) {
    return null;
  }

  return <InAppNotifications />;
};
