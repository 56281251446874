import { useAuth } from "@/auth";
import { ACCOUNT_FAVORITES_ROUTE } from "@/common/static/routes.static";
import NoSSR from "@mpth/react-no-ssr";
import HeartIcon from "@whoppah/icons/24/line/Heart";
import Link from "@whoppah/next/link";
import { useTranslation } from "next-i18next";

/**
 * Header chat action that navigates user to his/her favorites.
 */
export const HeaderFavorites = () => {
  const { t } = useTranslation();
  const { isLoggedIn } = useAuth();

  if (!isLoggedIn) {
    return null;
  }

  return (
    <NoSSR>
      <Link href={ACCOUNT_FAVORITES_ROUTE} prefetch={false}>
        <a className="group flex cursor-pointer flex-col items-center md:space-y-1">
          <div className="relative">
            <HeartIcon />
          </div>
          <span className="hidden font-body text-sm group-hover:text-attention-500 lg:inline">
            {t("header:nav-favourites")}
          </span>
        </a>
      </Link>
    </NoSSR>
  );
};
