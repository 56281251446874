// TODO: Move to form static file
export const CHECKOUT_FIRST_NAME = "FIRST_NAME";
export const CHECKOUT_LAST_NAME = "LAST_NAME";
export const CHECKOUT_PHONE = "PHONE";
export const CHECKOUT_EMAIL = "EMAIL";
export const CHECKOUT_COUPON_CODE = "COUPON_CODE";
export const CHECKOUT_CARD = "CARD";

export const CART_URL = "/cart2";
export const CART_OVERVIEW_URL = `${CART_URL}/overview`;
export const CART_SIGNUP_URL = `${CART_URL}/signup`;
export const CART_SHIPPING_URL = `${CART_URL}/shipping`;
export const CHECKOUT_URL = "/checkout2";
export const CHECKOUT_SERVICES_URL = "/checkout2/services";
export const CHECKOUT_COMPLETE_URL = `${CHECKOUT_URL}/complete`;
export const NEW_URL = "/new";

export const CHECKOUT_INVALID_VALUE = "INVALID";

export const CHECKOUT_SHIPPING_ADDRESS = "SHIPPING_ADDRESS";

export const CHECKOUT_DELIVERY_METHOD_PICKUP = "PICKUP";

export const CHECKOUT_PAYMENT_TYPE_SERVICES = "services";
export const CHECKOUT_PAYMENT_STATUS_FAILED = "failed";
export const CHECKOUT_PAYMENT_STATUS_SUCCEEDED = "succeeded";

export const CHECKOUT_ORDER_FALLBACK = "fallback";

export const GUEST_CHECKOUT_KEY = "GUEST_CART_IDS";

export const DUMMY_PRICE = { amount: 0, currency: "EUR" };

export const CHECKOUT_PAYMENT_IDEAL = "IDEAL";

export const DEFAULT_CALLING_CODE = "+31";

export const CHECKOUT_VIEW_LOGIN = "login";
export const CHECKOUT_VIEW_REGISTER = "register";
export const CHECKOUT_VIEW_RESET = "reset";
export const CHECKOUT_VIEW_MAGIC_LINK = "magic_link";

export const CHECKOUT_VIEW = {
  login: CHECKOUT_VIEW_LOGIN,
  register: CHECKOUT_VIEW_REGISTER,
  reset: CHECKOUT_VIEW_RESET,
  magicLogin: CHECKOUT_VIEW_MAGIC_LINK,
} as const;
