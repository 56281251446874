import { QueryHookOptions, useQuery } from "@apollo/client";
import cartCountGql from "../query/cartCount.graphql";
import {
  CartCountQuery,
  CartCountQueryVariables,
} from "@whoppah/apollo/schema";

const useCartCount = (
  options?: QueryHookOptions<CartCountQuery, CartCountQueryVariables>
) => {
  return useQuery(cartCountGql, options);
};

export default useCartCount;
