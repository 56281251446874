import {
  SavedSearchesQuery,
  SavedSearchesQueryVariables,
} from "@/Apollo/schema";
import { QueryHookOptions, useQuery } from "@apollo/client";
import queryGQL from "../query/savedSearches.graphql";

export const useSavedSearches = (
  options?: QueryHookOptions<SavedSearchesQuery, SavedSearchesQueryVariables>
) => {
  return useQuery(queryGQL, options);
};
