import { useTranslation } from "next-i18next";
import { useEffect } from "react";
import { log } from "next-axiom";
import { kebabCase } from "lodash-es";

const IGNORE_KEYS_REGEX = /(category|style|brand)-(.*)-(description|seo-title|seo-description)/;

const useLogMissingKeys = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    if (i18n && typeof i18n.on === "function") {
      i18n.on("missingKey", (lngs, namespace, key) => {
        if (!key) return;
        if (key.match(IGNORE_KEYS_REGEX)) return;
        if (key !== kebabCase(key)) return;
        log.warn(
          `Missing translation for key ${key} in ${namespace} namespace`
        );
      });
    }
  }, [i18n]);
};

export default useLogMissingKeys;
