import { cn } from "@/common/utils/css.utils";
import Image from "@/components/common/Image";
import { MegaMenuItemCardData } from "@/common/contracts/mega-menu.contracts";

export const MegaMenuCardItemImage = ({
  item,
}: {
  item: MegaMenuItemCardData;
}) => {
  if (!item.image) {
    return (
      <div
        className={cn(
          "h-10 w-full bg-grey-200",
          item.type === "card_image" ? "w-full" : "w-10"
        )}
      />
    );
  }

  return (
    <div
      className={cn(
        "relative h-10",
        item.type === "card_image" ? "w-full" : "w-10"
      )}
    >
      <Image
        src={item.image}
        alt={item.label}
        layout="fill"
        objectFit="contain"
      />
    </div>
  );
};
